export type Channel = "SMS" | "Whatsapp" | "Notification";
export const ChannelColors: {
  [key: string]: { color: string; background: string };
} = {
  SMS: {
    color: "#FFA351",
    background: "#FFF4EA",
  },
  Notification: {
    color: "#503E9D",
    background: "#EAE8F3",
  },
  Whatsapp: {
    color: "#38C172",
    background: "#E7F8EE",
  },
};

export interface Template {
  title: string;
  id: string;
  segment_use_time: number;
  channel_tags: Channel[];
}

export type CampaignStatusesType =
  | "finished"
  | "upcoming"
  | "running"
  | "recurring"
  | "failed";
export const CampaignStatus: {
  [key: string]: { background: string };
} = {
  finished: {
    background: "#38C172",
  },
  upcoming: {
    background: "#FFA351",
  },
  running: {
    background: "#FFC845",
  },
  failed: {
    background: "#EE5253",
  },
  recurring: {
    background: "#EE5223",
  },
};
export type CampaignSentActionsKeys =
  | "succeeded_assigned_users"
  | "succeeded_sent_sms"
  | "succeeded_sent_push";

export interface CampaignSentActions {
  [key: CampaignSentActionsKeys | string]: number | null;
}
export interface Campaign {
  id: number;
  title: string;
  next_time_to_run: null;
  end_time: string;
  state: CampaignStatusesType;
  enabled: boolean;
  sent_actions: CampaignSentActions | null;
  created_at: string;
  updated_at: string;
}

export interface Filters {
  page: number;
  size: number;
  title?: string;
  states?: string[];
  time_trigger?: string[];
}

export interface TemplateInfo {
  id: string;
  ar_message: string;
  en_message: string;
  ar_title: string;
  en_title: string;
}
