import { createSlice, PayloadAction } from "@reduxjs/toolkit/";
import { notify } from "../../helpers";
import { getPromoHighestRank } from "../thunks";
export type PromoModeType = "create" | "modify";

export interface IHighestRanks {
  sa: number;
  eg: number;
}
interface IState {
  ranks: IHighestRanks;
  status: "idle" | "succeeded" | "failed" | "loading";
}

const initialState: IState = {
  ranks: {
    sa: 0,
    eg: 0,
  },
  status: "idle",
};

export const highestRankSlice = createSlice({
  initialState,
  name: "highest-ranks",
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getPromoHighestRank.pending, (state) => ({
      ...state,
      status: "loading",
    })),
      builder.addCase(
        getPromoHighestRank.fulfilled,
        (
          state,
          { payload: { data } }: PayloadAction<{ data: Partial<IHighestRanks> }>
        ) => {
          return {
            ...state,
            ranks: data as IHighestRanks,
            status: "succeeded",
          };
        }
      ),
      builder.addCase(getPromoHighestRank.rejected, (state, action) => {
        notify("something went wrong", "error");
        return {
          ...state,
          status: "failed",
          ranks: { eg: 0, sa: 0 },
          error: action.error.message,
        };
      });
  },
});
export default highestRankSlice.reducer;
