import React, { useEffect } from "react";
import styles from "./styles.module.sass";

interface ProgressBarProps {
  done: string;
}
export const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const { done } = props;
  const [style, setStyle] = React.useState({});
  useEffect(() => {
    setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${done}%`,
      };

      setStyle(newStyle);
    }, 200);
  }, [done]);

  return (
    <div className={styles.progress}>
      <div className={styles.progressDone} style={style}>
        {done}%
      </div>
    </div>
  );
};

export default ProgressBar;
