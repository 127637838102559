import React from "react";
import { FormProvider } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import { useCommTemplate } from "../../../hooks";
import { TemplateMessage, TemplateSettings } from "../../../layouts";
import styles from "./styles.module.sass";
export const CreateCommTemplate: React.FC = () => {
  const { handleSubmit, methods, createTemplateResult } = useCommTemplate();
  return (
    <div className={styles.main}>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formContent}>
            <h1 className={styles.formTitle}>Create communication template </h1>
            <TemplateSettings />
            <TemplateMessage />
          </div>

          <div className={styles.submitBtnWrappr}>
            <button className={styles.submitBtn} type="submit">
              {createTemplateResult.isLoading ? (
                <ClipLoader color="#fff" />
              ) : (
                "Create Template"
              )}
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateCommTemplate;
