import React, { useState } from "react";
import styles from "./styles.module.sass";
import { Collapse } from "antd";
import SubMenuTitle from "../SubMenuTitle/index";
import "./styles.less";
import { Chevron } from "./chevron";
import { ISubMenuProps } from "./types";

const SubMenu: React.FC<ISubMenuProps> = ({
  children,
  title,
  icon,
  active,
  clicked,
  key,
}) => {
  const [activeKey, setActiveKey] = useState();
  const collapseCallBack = (current_key: any) => {
    setActiveKey(current_key);
  };
  return (
    <div className={styles.anchorTag}>
      <Collapse
        className={"side-bar-collapse"}
        activeKey={activeKey}
        onChange={collapseCallBack}
        bordered={false}
        expandIcon={({ isActive }) => (
          <div className={styles.expandIconWrapper}>
            <Chevron
              pathStyle={{
                stroke: active ? "#ffa351" : "#8E8E93",
              }}
              style={{
                width: "16px",
                height: "16px",
                transform: isActive ? "rotate(90deg)" : "",
              }}
            />
          </div>
        )}
      >
        <Collapse.Panel
          className={
            clicked
              ? "active-ant-collapse-header"
              : "sidebar-ant-collapse-header"
          }
          header={<SubMenuTitle clicked={clicked} title={title} icon={icon} />}
          key={key}
        >
          <React.Fragment>{children}</React.Fragment>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default SubMenu;
