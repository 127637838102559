import { poster } from "./../../services/poster";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addBrandsCSV as url } from "../../services/requests";

export const addBrandsCSV = createAsyncThunk<any, { body?: any }>(
  "add-csv/post",
  async ({ body }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await poster(url, body, {
        baseURL: process.env.REACT_APP_ORDERING_ENDPOINT,
      });
      return fulfillWithValue({
        data: !data.length ? [] : data,
      });
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
