import React from "react";
interface ICrossIconProps {
  stroke?: string;
  width?: string;
  height?: string;
  strokeWidth?: string;
}
const CrossIcon: React.FC<ICrossIconProps> = ({
  height,
  stroke,
  width,
  strokeWidth,
}) => {
  return (
    <svg
      width={width ?? "24"}
      height={height ?? "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke={stroke ?? "#1C1C1C"}
        strokeWidth={strokeWidth ?? "2"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke={stroke ?? "#1C1C1C"}
        strokeWidth={strokeWidth ?? "2"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CrossIcon;
