import React, { useMemo } from "react";
import { useSelect } from "../hooks";
import { Navigate, Outlet } from "react-router-dom";
import Header from "../layouts/Header";
import SideMenu from "../layouts/SideMenu";
import styles from "./styles.module.sass";
const ProtectedRoute: React.FC = () => {
  const {
    authReducer: { isLoggedIn },
  } = useSelect((state) => state);
  const path = useMemo(() => {
    return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
  }, [isLoggedIn]);

  return (
    <div className={styles.wrapper}>
      <div>
        <SideMenu />
      </div>
      <div className={styles.content}>
        <Header />
        {path}
      </div>
    </div>
  );
};

export default ProtectedRoute;
