import React from "react";
import ErrorMessage, { IErrorMessage } from "../../shared/ErrorMessage";
import styles from "./styles.module.sass";
import { IGenericInputFieldProps } from "./types";
const GenericInputField: React.FC<IGenericInputFieldProps> = ({
  register,
  type,
  error,
  placeholder,
  label,
  content,
  badge,
  onBlur,
  onChange,
  disabled,
  className,
}) => {
  return (
    <div className={className ?? styles.formControl}>
      {label && (
        <React.Fragment>
          {React.isValidElement(label) ? (
            label
          ) : (
            <label className={styles.label} htmlFor="password">
              {label}
            </label>
          )}
        </React.Fragment>
      )}
      <div className={styles.withBadge}>
        <input
          className={`${styles.input} ${
            (error as IErrorMessage)?.message ? styles.invalid : ""
          }`}
          {...register}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={onChange ?? register.onChange}
        />
        {badge && (
          <div
            className={`${styles.badge} ${
              (error as IErrorMessage)?.message && styles.invalid
            }`}
          >
            {badge}
          </div>
        )}
      </div>
      {content ?? content}
      <ErrorMessage
        message={((error as IErrorMessage)?.message as string) || ""}
        type={((error as IErrorMessage)?.type as string) || ""}
      />
    </div>
  );
};

export default React.memo(GenericInputField);
