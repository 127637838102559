import { createAsyncThunk } from "@reduxjs/toolkit";
import { poster } from "../../services";
import { login } from "../../services/requests";
import {} from "../slices/auth-slice";
export const loginRequest = createAsyncThunk<
  any,
  { email: string; password: string }
>("auth/login", async (payload, { rejectWithValue, fulfillWithValue }) => {
  try {
    const { data } = await poster(login, payload, {
      baseURL: process.env.REACT_APP_CORE_ENDPOINT,
    });

    return fulfillWithValue(data);
  } catch (error: any) {
    return rejectWithValue(error.response.status);
  }
});
