import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { CampaignFormValues, Filter, SegmentFormValues } from "../../types";

type State = {
  campaing_values: CampaignFormValues;
};
const initialState: State = {
  campaing_values: {
    end_time: Number(moment().format("x")),
    segment: [],
    time_triggers: [],
    title: "",
  },
};
type ValueOf<T> = T[keyof T];

export const campaignSlice = createSlice({
  initialState,
  name: "campaignSlice",
  reducers: {
    updateSegmentData(
      state: State,
      {
        payload,
      }: PayloadAction<{
        key: keyof SegmentFormValues;
        value: ValueOf<SegmentFormValues>;
        segmentIndex: number;
      }>
    ) {
      const newSegmentArray: SegmentFormValues[] = [
        ...state.campaing_values.segment,
      ];
      const currentIndex = newSegmentArray.findIndex(
        (_, index) => index === payload.segmentIndex
      );
      if (payload.key === "filter") {
        newSegmentArray[currentIndex] = {
          ...newSegmentArray[currentIndex],
          [payload.key]: {
            ...newSegmentArray[currentIndex][payload.key],
            conditions: payload.value as unknown as Filter[],
          },
        };
      }
      if (currentIndex > -1) {
        newSegmentArray[currentIndex] = {
          ...newSegmentArray[currentIndex],
          [payload.key]: payload.value,
        };
      }

      return {
        ...state,
        campaing_values: {
          ...state.campaing_values,
          segment: newSegmentArray,
        },
      };
    },
  },
});

export default campaignSlice.reducer;
