import { Country, CountryCode } from "../pages/Promocode/types";
import { PromocodeOptions } from "./DropdownOptions";

export const checkButtonAppear = (value: string) => {
  if (value === CountryCode.EGY || value === CountryCode.KSA) {
    return true;
  }
  return false;
};

export const verifyCountries = (countries: Country[]) => {
  return countries.some(
    (item) =>
      item.countryCode === CountryCode.EGY ||
      item.countryCode === CountryCode.KSA
  );
};

export const countrySelected = (selectCountry: string) => {
  let egyIsSelected = false,
    ksaIsSelected = false;

  if (selectCountry === CountryCode.EGY) {
    egyIsSelected = true;
  }
  if (selectCountry === CountryCode.KSA) {
    ksaIsSelected = true;
  }
  return {
    egyIsSelected,
    ksaIsSelected,
  };
};
export const sortCountries = (countries: Country[]) => {
  const countriesList = countries.sort((a, b) => {
    if (a.countryCode < b.countryCode) {
      return -1;
    }
    if (a.countryCode > b.countryCode) {
      return 1;
    }
    return 0;
  });
  if (countriesList) return true;
};

export const testPromocodeVisibility = (
  promocodeVisibleArr: PromocodeOptions[]
) => {
  return promocodeVisibleArr.length > 0;
};
export const testcashbackVisibility = (
  cashbackVisibileyArr: PromocodeOptions[]
) => {
  return cashbackVisibileyArr.length > 0;
};
export const testDeliveryAndPickup = (
  deliveryAndPickupArr: PromocodeOptions[]
) => {
  return deliveryAndPickupArr.length > 0;
};
