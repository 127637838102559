import React, { useMemo, useState } from "react";
import images from "../../../assets/images/images";
import { Button } from "../../../components/Button";
import { TargetBrandItem } from "../../../components/TargetBrandItem";
import { useSelect } from "../../../hooks";
import { IBrandsOption } from "../../../store/slices/country-brands-slice";
import styles from "./styles.module.sass";

const COLLAPSE_NUMBER = 20;
export const TargetBrands: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [collapseValue, setCollapseValue] = useState(COLLAPSE_NUMBER);

  const {
    brandsReducer: { selectedBrands },
  } = useSelect((state) => {
    return state;
  });

  const sortBrands = (brands: IBrandsOption[]) => {
    const sortedBrands = [...brands].sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return sortedBrands;
  };

  const sliceBrands = (brands: IBrandsOption[]) => {
    return brands.slice(0, collapseValue);
  };

  const updateTargetBrands = useMemo(() => {
    const sortedBrandsArr = sortBrands(selectedBrands);
    const slicedBrandArr = sliceBrands(sortedBrandsArr);
    return slicedBrandArr;
  }, [selectedBrands, collapseValue]);

  const toggleCollapse = () => {
    isCollapsed
      ? setCollapseValue(COLLAPSE_NUMBER)
      : setCollapseValue(selectedBrands.length);
    setIsCollapsed(!isCollapsed);
  };

  if (selectedBrands.length === 0) {
    return null;
  }

  return (
    <>
      <div className={styles.targetBrandContainer}>
        <h3>{`Target Brand (${selectedBrands.length})`}</h3>
      </div>

      <div className={styles.container}>
        {updateTargetBrands.map((item) => (
          <TargetBrandItem brand={item} key={item.id} />
        ))}
      </div>

      {selectedBrands.length > COLLAPSE_NUMBER && (
        <div className={styles.buttonWrapper}>
          <Button onClick={toggleCollapse} className={styles.collapsedButton}>
            <div className={styles.buttonContainer}>
              <div className={styles.buttonTitleContainer}>
                <h3 className={styles.buttonTitle}>
                  {`See All Brands (${
                    selectedBrands.length - COLLAPSE_NUMBER
                  })`}
                </h3>
              </div>

              <div className={styles.collapesdIcon}>
                <img
                  style={{
                    transform: isCollapsed ? "rotate(180deg)" : "rotate(0)",
                    transition: "transform 300ms ease",
                  }}
                  src={images.collapesArrow}
                />
              </div>
            </div>
          </Button>
        </div>
      )}
    </>
  );
};
