import React from "react";
interface IIconPlusProps {
  stroke?: string;
  width?: string;
  height?: string;
}
export const IconPlus: React.FC<IIconPlusProps> = ({
  stroke,
  height,
  width,
}) => {
  return (
    <svg
      width={width ?? "24"}
      height={height ?? "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5V19"
        stroke={stroke ?? "#FFA351"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 12H19"
        stroke={stroke ?? "#FFA351"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconPlus;
