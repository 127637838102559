import React from "react";
import { files } from "../../../../assets/files";
import images from "../../../../assets/images/images";
import { useSelect } from "../../../../hooks";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { addBrandsCSV } from "../../../../store/thunks/addBrandsCSV";
import { ModalHeader } from "../ModalHeader";
import styles from "./styles.module.sass";
import { UploadCSVProps } from "./types";

export const UploadCSV: React.FC<UploadCSVProps> = (props) => {
  const { onClose } = props;
  const {
    brandsReducer: { country_code },
  } = useSelect((state) => {
    return state;
  });
  const dispatch = useAppDispatch();

  const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const verifySCV = new FormData();
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    verifySCV.append("brands_csv", file);
    verifySCV.append("country_code", country_code);
    dispatch(
      addBrandsCSV({
        body: verifySCV,
      })
    );
  };

  return (
    <>
      <ModalHeader onClose={onClose} />
      <div className={styles.csvWrapprt}>
        <div className={styles.csvContainer}>
          <img src={images.csvIcon} />
        </div>
        <div className={styles.uploadTitleContainer}>
          <p>Upload CSV file that contains target brands</p>
        </div>
      </div>

      <div className={styles.csvButtonContainer}>
        <div className={styles.csvButtonWrapper}>
          <div className={styles.csvUploadContainer}>
            <div className={styles.uploadImageContainer}>
              <img src={images.uploadCsvIcon} />
            </div>

            <input
              type={"file"}
              title="upload"
              className={styles.customFileInput}
              onChange={(event) => {
                handleUploadFile(event);
              }}
            />
          </div>

          <div className={styles.uploadTitleWrapper}>
            <h4 className={styles.uploadTitle}>Upload CSV File</h4>
          </div>
        </div>

        <div className={styles.downloadButtonContainer}>
          <img src={images.downloadCsvIcon} />
          <a href={files.brandsTemplate}>
            <div className={styles.dowloadButtonContainer}>
              <h4 className={styles.dowloadTempTitle}>Download CSV Template</h4>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};
