import * as yup from "yup";
import { store } from "../../store";
import { getTemplatesApi } from "../../store/apis/templateSelection";
import { useMemo } from "react";

export const commTemplateValidatorSchema = () => {
  const { getTemplatesReducer } = store.getState();

  const { data: templates } = getTemplatesApi.endpoints.getTemplates.select()({
    getTemplatesReducer,
  });

  if (
    typeof templates === "undefined" ||
    (Array.isArray(templates) && !templates.length)
  ) {
    store.dispatch(getTemplatesApi.endpoints.getTemplates.initiate());
  }

  return useMemo(() => {
    return yup.object().shape({
      name: yup
        .string()
        .required("Template name is required")
        .test("duplication", "duplication", (value) => {
          return (templates || []).findIndex((element: any) => {
            return (
              value &&
              element.name.trim().toLowerCase() === value.trim().toLowerCase()
            );
          });
        }),
      tags: yup.array().min(1, "Please choose a channel tag"),
      ar_title: yup.string().required("Arabic title is required"),
      en_title: yup.string().required("English title is required"),
      ar_message: yup.string().required("Arabic description is required"),
      en_message: yup.string().required("English description is required"),
    });
  }, [templates]);
};
