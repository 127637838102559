export const generateFormDataObjects = (
  formDataInstace: FormData,
  values: { [key: string]: any }
) => {
  //stores_fund_percentage[0][store_id]
  const keys = Object.keys(values);
  keys.forEach((key) => {
    // in case of type of (values[key]) is "array"
    if (values[key] instanceof File) {
      formDataInstace.append(`${key}`, values[key]);
    } else if (Array.isArray(values[key])) {
      const arr = values[key] as Array<any>;
      arr.forEach((value, index) => {
        if (typeof value === "object") {
          const keysOfPropertyKey = Object.keys(value);
          keysOfPropertyKey.forEach((newKey) => {
            formDataInstace.append(
              `${key}[${index}][${newKey}]`,
              value[newKey]
            );
          });
        } else {
          formDataInstace.append(`${key}[${index}]`, value);
        }
      });
    }
    // in case of type of (values[key]) is "array"
    else if (typeof values[key] === "object") {
      const keysOfPropertyKey = Object.keys(values[key]);
      keysOfPropertyKey.forEach((newKey) => {
        formDataInstace.append(`${key}[${newKey}]`, values[key][newKey]);
      });
    }
    // in case of type of (values[key]) is "any"
    else {
      formDataInstace.append(`${key}`, values[key]);
    }
  });
  return formDataInstace;
};
