import React, { useEffect, useMemo, useState } from "react";
import images from "../../../../../assets/images/images";
import { files } from "../../../../../assets/files";
import ErrorMessage from "../../../../../components/shared/ErrorMessage";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";
import { useTragetedCustomerFileMutation } from "../../../../../store/apis/promocodeTargetedCustomerFileUpload-api";
import { setInfo } from "../../../../../store/slices/promoInfo-slice";
import styles from "./styles.module.sass";
import { UploadCSVProps } from "./types";
import { UseFormSetValue } from "react-hook-form";
import { FormValues } from "../../../../../pages/Promocode/types";
import { MAX_FILE_SIZE } from "../../../../../constant";
import { useSelect } from "../../../../../hooks";

export const UploadCSV: React.FC<
  UploadCSVProps & {
    register: any;
    watchers: any;
    errors: any;
    setValue: UseFormSetValue<FormValues>;
  }
> = (props) => {
  const [uploadErrors, setUploadErrors] = useState<string>("");
  const { register, errors, setValue, watchers } = props;
  const {
    customerFileVerificationReducer: {
      status: fileVerificationStatus,
      message,
      file_path,
    },
  } = useSelect((state) => state);
  const [uploadTargetedCustomersFile, results] =
    useTragetedCustomerFileMutation();

  useEffect(() => {
    if (fileVerificationStatus === "succeed") {
      setValue("targeted_customers_file_path", file_path);
      dispatch(
        setInfo({
          targeted_customers_file_path: file_path,
        })
      );
    }
  }, [fileVerificationStatus, file_path]);
  const dispatch = useAppDispatch();
  const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploadErrors("");
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    setValue("targeted_customers", file);
    if (file && file.size > MAX_FILE_SIZE) {
      setUploadErrors("The file size shouldn't Exceed  50 MB");
      return;
    }
    uploadTargetedCustomersFile({
      data: { target_customers: file },
    });
  };
  useEffect(() => {
    const error: string | { [key: string]: string[] } = (results?.error as any)
      ?.data?.message;
    if (error) {
      setUploadErrors(
        typeof error === "string" ? error : error?.["target_customers"][0]
      );
    }
    if (message) {
      setUploadErrors(message);
    }
  }, [results.error, message]);
  const fileInfoCard = useMemo(() => {
    const size = (watchers.targeted_customers?.size / 1024).toFixed(3);
    return (
      watchers.targeted_customers?.name && (
        <div className={styles.fileInfo}>
          <div className={styles.imageWrapper}>
            <img src={images.fileIcon.default} alt="file_image" />
          </div>
          <div className={styles.fileContent}>
            <p>{`${watchers.targeted_customers?.name}-${size}KB`}</p>
          </div>
          <div className={styles.imageWrapper}>
            {fileVerificationStatus === "loading" && (
              <img src={images.refreshSMIcon.default} alt="refresh_image" />
            )}

            {fileVerificationStatus === "succeed" && (
              <img src={images.checkIcon.default} alt="checkImage_image" />
            )}
            {fileVerificationStatus === "failed" && (
              <img src={images.faildUploadCSVIcon} alt="checkImage_image" />
            )}
          </div>
        </div>
      )
    );
  }, [watchers.targeted_customers, fileVerificationStatus]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.csvWrapprt}>
        <div className={styles.csvContainer}>
          {fileVerificationStatus === "loading" ? (
            <img src={images.refreshLGIcon.default} />
          ) : (
            <img src={images.csvIcon} />
          )}
        </div>
        <div className={styles.uploadTitleContainer}>
          {fileVerificationStatus === "loading" ? (
            <p style={{ marginBottom: "0px", textAlign: "center" }}>
              We&apos;re Processing file data...
            </p>
          ) : (
            <p style={{ marginBottom: "0px" }}>
              Upload CSV file that contains target users
            </p>
          )}
          {errors?.targeted_customers && (
            <ErrorMessage
              style={{ textAlign: "center", justifyContent: "center" }}
              message={errors?.targeted_customers.message}
              type={errors?.targeted_customers.type}
            />
          )}
          {fileVerificationStatus === "loading" ? (
            <p className={styles.files_count}>
              Don&apos;t close this window until processing finishes
            </p>
          ) : (
            <p className={styles.files_count}>Max. 1 file</p>
          )}
        </div>
        {fileInfoCard}
        {uploadErrors && (
          <ErrorMessage
            style={{ textAlign: "center", justifyContent: "center" }}
            message={uploadErrors}
            type={"file-type-error"}
          />
        )}
        {errors?.targeted_customers_file_path && (
          <ErrorMessage
            style={{ textAlign: "center", justifyContent: "center" }}
            message={errors?.targeted_customers_file_path.message}
            type={errors?.targeted_customers_file_path.type}
          />
        )}
      </div>

      {fileVerificationStatus !== "loading" && (
        <div className={styles.csvButtonContainer}>
          <div className={styles.csvButtonWrapper}>
            <div className={styles.csvUploadContainer}>
              <div className={styles.uploadImageContainer}>
                <img src={images.uploadCsvIcon} />
              </div>

              <input
                {...register}
                type={"file"}
                title="upload"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                className={styles.customFileInput}
                onChange={(event) => {
                  handleUploadFile(event);
                }}
              />
            </div>
            <div className={styles.uploadTitleWrapper}>
              <h4 className={styles.uploadTitle}>Upload CSV File</h4>
            </div>
          </div>

          <div className={styles.downloadButtonContainer}>
            <img src={images.downloadCsvIcon} />
            {/* <CSVLink data={csvData}> */}
            <div className={styles.dowloadButtonContainer}>
              <h4 className={styles.dowloadTempTitle}>
                <a href={files.customers}>Download CSV Template</a>
              </h4>
            </div>
            {/* </CSVLink> */}
          </div>
        </div>
      )}
    </div>
  );
};
