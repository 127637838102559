import React from "react";
import styles from "./styles.module.sass";

interface ITabsProps<TValue> {
  items: { label: string; value: TValue }[];
  onClick: (value: TValue) => void;
  value: TValue;
  label?: string | JSX.Element;
  className?: string;
  tabsClassName?: string;
  tabClassName?: string;
}
const TargettedTabs = <T,>({
  items,
  onClick,
  value,
  className,
  label,
  tabsClassName,
  tabClassName,
}: ITabsProps<T>) => {
  return (
    <div className={`${styles.wrapper} ${className}`}>
      {React.isValidElement(label) ? (
        label
      ) : (
        <span className={styles.label}>{label}</span>
      )}
      <div className={`${styles.targeting_tabs_container} ${tabsClassName}`}>
        {items.map((item) => (
          <button
            key={String(item.value)}
            className={`${styles.tab_btn} ${tabClassName} ${
              value === item.value ? styles.active : ""
            }`}
            type={"button"}
            onClick={() => {
              onClick(item.value);
            }}
          >
            {item.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TargettedTabs;
