import React from "react";
import styles from "./styles.module.sass";
import "./styles.less";
import { Controller, useFormContext } from "react-hook-form";
import moment from "moment";
import { DatePicker, TimePicker } from "antd";

const StartingDate: React.FC<{
  index: number;
  handleChange?: (value: any) => void;
}> = ({ index, handleChange }) => {
  const { control, watch } = useFormContext();
  const watchers = watch();
  const disabled = (current: moment.Moment) => {
    return current.isBefore(moment().startOf("day"));
  };
  return (
    <Controller
      name={`time_triggers[${index}].repeat_start`}
      control={control}
      render={({ field: { onChange }, fieldState: { error } }) => {
        return (
          <div className={styles.wrapper}>
            <span className={styles.startsFromLabel}>Starting date:</span>
            <DatePicker
              showToday={false}
              disabledDate={disabled}
              clearIcon={false}
              placeholder="Select starting date..."
              className="time_triggers_repeat_start_date"
              picker="date"
              value={moment(Number(watchers.time_triggers[index].repeat_start))}
              onChange={(value: any) => {
                onChange(Number(moment(value._d).format("x")));
                if (handleChange) {
                  handleChange(Number(moment(value._d).format("x")));
                }
              }}
              format={"D MMMM yyyy"}
            />
            <span className={styles.startsFromLabel}>Starts from:</span>
            <TimePicker
              clearIcon={false}
              className={
                error?.message
                  ? "time_triggers_repeat_start_error"
                  : "time_triggers_repeat_start"
              }
              use12Hours
              placeholder="12:00 PM"
              format="h:mm A"
              value={moment(Number(watchers.time_triggers[index].repeat_start))}
              onChange={(value) => {
                onChange(
                  Number(
                    moment(watchers.time_triggers[index].repeat_start)
                      .set({
                        hour: moment(value).hour(),
                        minute: moment(value).minute(),
                      })
                      .format("x")
                  )
                );
                if (handleChange) {
                  handleChange(
                    Number(
                      moment(watchers.time_triggers[index].repeat_start)
                        .set({
                          hour: moment(value).hour(),
                          minute: moment(value).minute(),
                        })
                        .format("x")
                    )
                  );
                }
              }}
            />
          </div>
        );
      }}
    />
  );
};

export default StartingDate;
