import { useEffect, useMemo } from "react";
import { UseFormSetValue } from "react-hook-form";
import { allEq } from "../helpers";
import { FormValues } from "../pages/Promocode/types";
import { setInfo } from "../store/slices/promoInfo-slice";
import { useAppDispatch } from "./useAppDispatch";
import { useDeepCompareEffect } from "use-deep-compare";
import { PromotionType } from "../layouts/Promocode/types";
export const usePromocodeSplit = ({
  watchers: {
    brand_percentages,
    min_eligible_invoices,
    max_discount_values,
    koinz_percentages,
    brands,
    max_discount_values_flag,
    min_eligible_invoice_flag,
    expires_after_values,
    expires_after_values_flag,
    split_flag,
    promotion_type,
  },
  setValue,
}: {
  watchers: FormValues;
  setValue: UseFormSetValue<FormValues>;
}) => {
  const dispatch = useAppDispatch();

  // functions
  // split checker action
  const applySplitAction = (checked: boolean, field: any) => {
    const allBrandPercentageEq = allEq(
      "brand_percentage",
      brands,
      brand_percentages
    );
    if (checked && !allBrandPercentageEq) {
      setValue(
        "brands",
        brands.map((brand) => ({
          ...brand,
          brand_percentage: 0,
          koinz_percentage: 100,
        }))
      );
      setValue("brand_percentages", 0);
      setValue("koinz_percentages", 100);
    }
    field.onChange(checked);
  };
  //  split checker action
  // min invoice checker action
  const applyMinValueAction = (checked: boolean, field: any) => {
    const allMinEligibleEq = allEq(
      "min_eligible_invoice",
      brands,
      min_eligible_invoices
    );
    if (checked && !allMinEligibleEq) {
      setValue(
        "brands",
        brands.map((brand) => ({ ...brand, min_eligible_invoice: 0 }))
      );
      setValue("min_eligible_invoices", 0);
    }
    field.onChange(checked);
  };
  //  min invoice checker action

  // min invoice checker action
  const applyMaxDiscountAction = (checked: boolean, field: any) => {
    const allMaxDiscountEq = allEq(
      "max_discount_value",
      brands,
      max_discount_values
    );
    if (checked && !allMaxDiscountEq) {
      setValue(
        "brands",
        brands.map((brand) => ({ ...brand, max_discount_value: 0 }))
      );
      setValue("max_discount_values", 0);
    }
    field.onChange(checked);
  };
  const applyExpiresAfterAction = (checked: boolean, field: any) => {
    const allExpiresAfterEq = allEq(
      "expires_after_value",
      brands,
      expires_after_values
    );
    if (checked && !allExpiresAfterEq) {
      setValue(
        "brands",
        brands.map((brand) => ({ ...brand, expires_after_value: 72 }))
      );
      setValue("expires_after_values", 72);
    }
    field.onChange(checked);
  };
  const memoizedSplittedValuesFlages = useMemo(() => {
    const flags: { [key: string]: boolean } = {
      [PromotionType.PERCENTAGE]:
        split_flag && min_eligible_invoice_flag && max_discount_values_flag,
      [PromotionType.CASHBACK]:
        split_flag &&
        min_eligible_invoice_flag &&
        max_discount_values_flag &&
        expires_after_values_flag,
      other: split_flag,
    };
    return flags;
  }, [
    split_flag,
    min_eligible_invoice_flag,
    max_discount_values_flag,
    expires_after_values_flag,
  ]);
  const memoizedSplittedValues = useMemo(() => {
    const selectedType = [
      PromotionType.PERCENTAGE,
      PromotionType.CASHBACK,
    ].includes(promotion_type)
      ? promotion_type
      : "other";
    return memoizedSplittedValuesFlages[selectedType];
  }, [memoizedSplittedValuesFlages, promotion_type]);
  // effects
  useEffect(() => {
    if (brands.length === 1) {
      setValue("min_eligible_invoice_flag", false);
      setValue("max_discount_values_flag", false);
      setValue("split_flag", false);
      setValue("expires_after_values_flag", false);
    }
  }, [brands]);
  useDeepCompareEffect(() => {
    const newBrands = [
      ...(brands ?? []).map((brand) => {
        const {
          brand_percentage,
          koinz_percentage,
          max_discount_value,
          min_eligible_invoice,
          expires_after_value,
        } = { ...brand };
        return {
          ...brand,
          brand_percentage: split_flag ? brand_percentages : brand_percentage,
          min_eligible_invoice: min_eligible_invoice_flag
            ? min_eligible_invoices
            : min_eligible_invoice,
          max_discount_value: max_discount_values_flag
            ? max_discount_values
            : max_discount_value,
          koinz_percentage: split_flag ? koinz_percentages : koinz_percentage,
          expires_after_value: expires_after_values_flag
            ? expires_after_values ?? 72
            : expires_after_value ?? 72,
        };
      }),
    ];
    setValue("brands", [...newBrands]);
    dispatch(
      setInfo({
        brands: [...newBrands],
        max_discount_values_flag,
        min_eligible_invoice_flag,
        split_flag,
        brand_percentages,
        min_eligible_invoices,
        max_discount_values,
        koinz_percentages,
        expires_after_values_flag,
        expires_after_values: expires_after_values ?? 72,
      })
    );
  }, [
    min_eligible_invoices,
    max_discount_values,
    koinz_percentages,
    brand_percentages,
    max_discount_values_flag,
    min_eligible_invoice_flag,
    split_flag,
    expires_after_values_flag,
    expires_after_values,
  ]);
  return {
    applyMaxDiscountAction,
    applyMinValueAction,
    applySplitAction,
    applyExpiresAfterAction,
    memoizedSplittedValues,
  };
};
