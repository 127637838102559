import moment from "moment";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { clockIcon } from "../../../assets";
import { IconPlus } from "../../../assets/images/images";
import { CampaignFormValues } from "../../../types";
import styles from "./styles.module.sass";
import TimeTriggerCard from "./TimeTriggerCard";
export const TimeTrigger: React.FC = () => {
  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext<CampaignFormValues>();
  const { append, remove, fields } = useFieldArray({
    control,
    name: "time_triggers",
  });

  const addTimeTrigger = () => {
    append({
      month: [],
      repeat: false,
      repeat_start: Number(moment(moment.now()).format("x")),
      time: Number(moment(moment.now()).format("x")),
      week: [],
      week_day: [],
    });

    clearErrors("time_triggers");
  };

  const removeTimeTrigger = (index: any) => {
    remove(index);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.timeTriggerIconWrapper}>
          <img className={styles.timeTriggerIcon} src={clockIcon} />
        </div>
        <span className={styles.timeTriggerTitle}>Time Trigger</span>
      </div>
      <div className={styles.timeTriggerFields}>
        {fields.map((_, index) => (
          <TimeTriggerCard
            index={index}
            removeTimeTrigger={() => removeTimeTrigger(index)}
            key={index}
          />
        ))}
      </div>
      <button
        type={"button"}
        onClick={addTimeTrigger}
        className={styles.addTimeTriggerBtn}
      >
        <IconPlus />
        Add time trigger
      </button>
      {errors.time_triggers?.message && (
        <span className={styles.errorMessage}>
          {errors.time_triggers.message}
        </span>
      )}
    </div>
  );
};

export default TimeTrigger;
