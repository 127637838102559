import React from "react";
import { useFormContext } from "react-hook-form";
import { GenericInputField, TagsSelect } from "../../../components";
import { TemplateFormValues } from "../../../types";
import styles from "./styles.module.sass";
export const TemplateSettings: React.FC = () => {
  const { control, register, formState } = useFormContext<TemplateFormValues>();
  return (
    <div className={styles.main}>
      <span className={styles.title}>Template Settings</span>
      <div className={styles.fieldsWrapper}>
        <GenericInputField
          register={register("name")}
          type={"text"}
          label="Template name"
          placeholder="ex. “Brand activation”"
          className={styles.templateNameInput}
          error={formState.errors.name}
        />
        <div className={styles.separator} />
        <TagsSelect
          control={control}
          placeholder={"Select channel tags..."}
          name={"tags"}
          label={"Channel Tags"}
          allText={"All Channels"}
          options={[
            {
              value: "sms",
              label: "SMS",
              backgroundColor: "#FFF4EA",
              color: "#FFA351",
            },
            {
              value: "notification",
              label: "Notification",
              backgroundColor: "#EAE8F3",
              color: "#503E9D",
            },
            {
              value: "whatsapp",
              label: "Whatsapp",
              backgroundColor: "#E7F8EE",
              color: "#38C172",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default TemplateSettings;
