import React, { useState } from "react";
import images from "../../../assets/images/images";
import styles from "./styles.module.sass";
export type Channel = "SMS" | "Whatsapp" | "Notification";
export const ChannelColors: {
  [key: string]: { color: string; background: string };
} = {
  SMS: {
    color: "#FFA351",
    background: "#FFF4EA",
  },
  Notification: {
    color: "#503E9D",
    background: "#EAE8F3",
  },
  Whatsapp: {
    color: "#38C172",
    background: "#E7F8EE",
  },
};

export interface Template {
  title: string;
  id: string;
  segment_use_time: number;
  channel_tags: Channel[];
}
export const columns = [
  {
    title: "Title",
    sorter: false,
    render: (obj: Template) => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            fontWeight: "400",
            color: "#1c1c1e",
            paddingBottom: "10px",
            fontSize: "14px",
          }}
        >
          {obj.title}
        </span>
        <span style={{ fontWeight: "400", color: "#8E8E93", fontSize: "12px" }}>
          {" "}
          {obj.id}
        </span>
      </div>
    ),
  },
  {
    title: "Segment Used Time",

    sorter: false,
    render: (obj: Template) => <span>{obj.segment_use_time || 0}</span>,
  },
  {
    title: "Channel Tags",

    sorter: false,
    render: ({ channel_tags }: Template) => (
      <div>
        {channel_tags?.map((tag: Channel, index: number) => {
          return (
            <span
              key={`${tag}-${index}`}
              style={{
                ...ChannelColors[tag],
                borderRadius: "5px",
                fontSize: "14px",
                lineHeight: "30px",
                height: "30px",
                display: "inline-block",
                marginRight: "5px",
                padding: "0px 5px",
                fontWeight: "500",
              }}
            >
              {tag}
            </span>
          );
        })}
      </div>
    ),
  },
  {
    title: "Actions",
    sorter: false,
    render: (item: Template) => <Actions item={item} />,
  },
];
export const Actions: React.FC<{ item: Template }> = () => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div className={styles.actions_wrapper}>
      <div className={styles.image_wrapper}>
        <img
          src={images.dropdown}
          alt="dropdown"
          onClick={() => {
            setOpen(!open);
          }}
        />
      </div>
      <div className={`${styles.actions} ${open ? styles.open : ""}`}>
        <button onClick={() => setOpen(false)} className={styles.action_cta}>
          Edit Template
        </button>
        <button onClick={() => setOpen(false)} className={styles.action_cta}>
          Duplicate Template
        </button>
        <button
          onClick={() => setOpen(false)}
          className={`${styles.action_cta} ${styles.danger}`}
        >
          Delete Template
        </button>
      </div>
    </div>
  );
};
