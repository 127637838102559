import React from "react";
import styles from "./styles.module.sass";
interface INotFoundSearchTerm {
  search: string;
}
const NotFoundSearchTerm: React.FC<INotFoundSearchTerm> = ({ search }) => {
  return (
    <div className={styles.notFoundContainer}>
      <div className={styles.imageContainer}>
        <img src={require("../../assets/search.svg").default} alt="search" />
      </div>
      <div className={styles.textContainer}>
        <p>No result found for</p>
        <p>
          <strong>&quot; {`${search}`} &quot;</strong>
        </p>
      </div>
    </div>
  );
};

export default React.memo(NotFoundSearchTerm);
