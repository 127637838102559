import React from "react";
import { FormProvider } from "react-hook-form";
import { useCampaign } from "../../../hooks";
import {
  CustomerSegmentation,
  TimeTrigger,
  CampaignSettings,
} from "../../../layouts";
import styles from "./styles.module.sass";

export const TargetCampaigns: React.FC = () => {
  const { handleSubmit, methods } = useCampaign();
  return (
    <div className={styles.main}>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formContent}>
            <h1 className={styles.header}>Create new campaign</h1>
            <CampaignSettings />
            <TimeTrigger />
            <CustomerSegmentation />
          </div>

          <div className={styles.submitBtnWrappr}>
            <button className={styles.submitBtn} type="submit">
              Create campaign
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default TargetCampaigns;
