import {
  OrderingType,
  PointMultiplier,
  PromotionType,
  VisibilityType,
  TargetedUserType,
} from "../../layouts/Promocode/types";
import { IPromocodeBrandValues } from "../../store/types";

export enum CountryCode {
  EGY = "+2",
  KSA = "+966",
}
export interface Country {
  id: number;
  image: string;
  countryName: string;
  countryCode: CountryCode;
}
export interface AddBrand {
  id: string;
  brand: string;
}

export type TargetingMethodType =
  | "all_customers"
  | "new_customers"
  | "ordering_customers";
export interface FormValues {
  expiry_days?: number;
  targeting_method?: TargetingMethodType;
  visibility_type: VisibilityType;
  ordering_type: OrderingType;
  promotion_type: PromotionType;
  country_code: string;
  start_date: number;
  end_date: number;
  rank: string;
  promo_code: string;
  ar_desc: string;
  desc: string;
  title: string;
  ar_title: string;
  koinz_percentages: number;
  brand_percentages: number;
  min_eligible_invoices: number;
  max_discount_values: number;
  min_eligible_invoice_flag: boolean;
  max_discount_values_flag: boolean;
  split_flag: boolean;
  max_usage_times: number;
  discount_percentage: number;
  brands: IPromocodeBrandValues[];
  masked: boolean;
  max_discount: number;
  min_eligible: number;
  point_multiplier_value: PointMultiplier;
  preselectPromo: boolean;
  targeted_customers?: File;
  targeted_customers_type: TargetedUserType;
  targeted_customers_file_path?: string;
  expires_after_values: number;
  expires_after_values_flag: boolean;
}
