import moment from "moment";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { ISelectItem } from "../components/shared/types";
import { getWeeksInAMonth } from "../helpers";

export const DAYS: { [x: string]: number } = {
  Sunday: 1,
  Monday: 2,
  Tuesday: 3,
  Wednesday: 4,
  Thursday: 5,
  Friday: 6,
  Saturday: 7,
};
export const monthOptions = [
  {
    label: "January",
    value: 1,
  },
  {
    label: "February",
    value: 2,
  },
  {
    label: "March",
    value: 3,
  },
  {
    label: "April",
    value: 4,
  },
  {
    label: "May",
    value: 5,
  },
  {
    label: "June",
    value: 6,
  },
  {
    label: "July",
    value: 7,
  },
  {
    label: "August",
    value: 8,
  },
  {
    label: "September",
    value: 9,
  },
  {
    label: "October",
    value: 10,
  },
  {
    label: "November",
    value: 11,
  },
  {
    label: "December",
    value: 12,
  },
];
export const useTimeTrigger = (index: number) => {
  const { control, watch, ...rest } = useFormContext();
  const watchers = watch();

  const weeksOptions = useMemo(() => {
    return getWeeksInAMonth({
      months: [
        ...(!watchers.time_triggers[index].month.length
          ? Array.from({ length: 12 }, (_, i) => i + 1)
          : watchers.time_triggers[index].month),
      ],
    })
      .map((element) => ({
        label: `Week ${element}`,
        value: element as number,
      }))
      .sort((a, b) => a.value - b.value);
  }, [watchers.time_triggers[index].month]);

  const getMonthsTitle = useMemo(() => {
    return monthOptions
      .filter((month) =>
        watchers.time_triggers[index].month.includes(month.value)
      )
      .map((element) => element.label);
  }, [watchers.time_triggers[index].month]);

  const weekdaysOptions = useMemo(() => {
    let dates: string[] = [];
    for (const w of watchers.time_triggers[index].week) {
      dates = [
        ...dates,
        ...Array.from(
          {
            length: 7,
          },
          (_, i) =>
            moment(`${moment(moment.now()).year()}-W${w < 10 ? `0${w}` : w}`)
              .startOf("isoWeek")
              .add(i, "days")
              .format("dddd DD MMMM YYYY")
        ),
      ];
    }

    return dates
      .filter((date) => getMonthsTitle.includes(date.split(" ")[2]))
      .map((element) => {
        const dayLabel = element.split(" ")[0];
        return {
          label: dayLabel,
          value: DAYS[dayLabel],
        };
      })
      .sort((a, b) => a.value - b.value)
      .reduce((acc: ISelectItem<number>[], next: ISelectItem<number>) => {
        return acc.findIndex((element) => element.label === next.label) > -1
          ? acc
          : [...acc, next];
      }, []);
  }, [watchers.time_triggers[index].week, getMonthsTitle]);

  useEffect(() => {
    const currentDaysSelected = watchers.time_triggers[index].week_day.filter(
      (element: number) => {
        return weekdaysOptions.find((element2) => element2.value === element);
      }
    );
    const notExistIndex = watchers.time_triggers[index].week_day.findIndex(
      (element: number) =>
        !weekdaysOptions.find((element2) => element2.value === element)
    );
    if (notExistIndex > -1) {
      rest.setValue(`time_triggers.${index}.week_day`, currentDaysSelected);
    }
  }, [weekdaysOptions, watchers.time_triggers[index].week_day]);
  return {
    weeksOptions,
    weekdaysOptions,
    control,
    watchers,
    ...rest,
  };
};
