import Pusher from "pusher-js";

export const pusher = (token: string) => {
  const socket = new Pusher(process.env.REACT_APP_PUSHER_API_KEY as string, {
    authEndpoint: `${process.env.REACT_APP_ORDERING_ENDPOINT}/api/pusher/admin/auth`,
    auth: {
      headers: {
        token: token,
      },
      params: {},
    },

    cluster: "eu",
  });

  return socket;
};
