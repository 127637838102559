import React, { useState } from "react";
import { CampaignFormValues } from "../../../types";
import styles from "./styles.module.sass";
import { useFormContext } from "react-hook-form";
import { IconPlus } from "../../../assets/images/images";
import { ConnectionItemCard } from "./ConnectionInfoCard";
import ConnectionSelectionModal, {
  ConnectionTypes,
} from "./ConnectionSelectionModal";

export interface IContentSelectionProps {
  index: number;
  options: any[];
  onSelect?: (option: any) => void;
  name?: string;
  defaultValue?: any;
}
const ContentSelection: React.FC<IContentSelectionProps> = ({
  index,
  options,
}) => {
  const {
    watch,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useFormContext<CampaignFormValues>();
  const watchers = watch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      {watchers.segment[index].connections.length ? (
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <span>Communication</span>
            <button
              type="button"
              onClick={() => {
                setValue(`segment.${index}.connections`, []);
              }}
            >
              &times;
            </button>
          </div>

          {/* card */}

          <div className={styles.filterWrapper}>
            <div className={styles.divider}>
              {watchers.segment[index].connections.map(
                (item, connection_index) => {
                  return (
                    <ConnectionItemCard
                      item={item}
                      onClose={() => {
                        setValue(
                          `segment.${index}.connections`,
                          watchers.segment[index].connections.filter(
                            (connection) => connection.id !== item.id
                          )
                        );
                      }}
                      options={options}
                      applyCheck={() => {
                        setValue(
                          `segment.${index}.connections.${connection_index}.attachActiveLink`,
                          !item.attachActiveLink
                        );
                      }}
                      control={control}
                      error={
                        errors.segment?.[index]?.connections?.[connection_index]
                      }
                      image={
                        ConnectionTypes.find(
                          (connection: any) => connection.id == item.id
                        )?.card_image || ""
                      }
                      name={`segment.${index}.connections.${connection_index}.template_id`}
                      onSelect={(option) => {
                        setValue(
                          `segment.${index}.connections.${connection_index}.template_id`,
                          option.value?.toString()
                        );
                        clearErrors(
                          `segment.${index}.connections.${connection_index}.template_id`
                        );
                      }}
                      key={`segment.${index}.connections.${connection_index}.template_id`}
                    />
                  );
                }
              )}
            </div>
            {watchers.segment[index].connections.length <
            ConnectionTypes.length ? (
              <button
                type={"button"}
                className={styles.addNewSegmentationBtn}
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                <div className={styles.addNewSegmentationIcon}>
                  <IconPlus />
                </div>
                Add Communication Channel
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      <ConnectionSelectionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(!isModalOpen)}
        segmentIndex={index}
      />
    </>
  );
};

export default ContentSelection;
