import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { commTemplateValidatorSchema } from "../helpers/validator";
import { useCreateTemplateMutation } from "../store/apis/templateSelection";
import { TemplateFormValues } from "../types";
import useFormPersist from "./useFormPersist";

export const useCommTemplate = () => {
  const navigator = useNavigate();
  const [createTemplateMutate, createTemplateResult] =
    useCreateTemplateMutation();

  const methods = useForm<TemplateFormValues>({
    resolver: yupResolver(commTemplateValidatorSchema()),
    reValidateMode: "onChange",
    defaultValues: {
      tags: [],
      name: "",
      ar_title: "",
      ar_message: "",
      en_message: "",
      en_title: "",
    },
  });

  const persistentTemplateFormData = useFormPersist("persist:template", {
    watch: methods.watch,
    setValue: methods.setValue,
    storage: window.localStorage,
  });
  const handleSubmit = (values: TemplateFormValues) => {
    createTemplateMutate(values);
  };

  useEffect(() => {
    if (createTemplateResult.isSuccess) {
      createTemplateResult.reset();
      persistentTemplateFormData.clear();
      navigator("/targeting-tool/communication_template");
    }
  }, [createTemplateResult.isSuccess]);

  return {
    methods,
    handleSubmit: methods.handleSubmit(handleSubmit),
    createTemplateResult,
  };
};
