import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "..";
import { pusher } from "../../pusher/pusher";
import { poster } from "../../services";
export const socketAuth = createAsyncThunk<
  any,
  {
    channel_name: string;
  }
>(
  "socketAuth/post",
  ({ channel_name }, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const { isLoggedIn, token } = (getState() as RootState).authReducer;
      const socket = pusher(token);
      if (!isLoggedIn && !token) {
        socket.unsubscribe(channel_name);
        socket.unbind_all();
      }
      socket.connection.bind("connected", async (data: any) => {
        const { data: res } = await poster(
          `${process.env.REACT_APP_ORDERING_ENDPOINT}/api/pusher/admin/auth`,
          {
            socket_id: data.socket_id,
            channel_name,
          }
        );

        fulfillWithValue(res);
      });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
