import React from "react";
import styles from "./styles.module.sass";
interface IBrandOptionItem {
  name: string;
  id: string;
  type: "label" | "name";
  selected: boolean;
  action: (id: string) => void;
}
const BrandOptionItem: React.FC<IBrandOptionItem> = ({
  name,
  id,
  type,
  selected,
  action,
}) => {
  return (
    <>
      {type == "label" ? (
        <div className={styles.labelDivision} key={`${name}`}>
          {name}
        </div>
      ) : (
        <div
          onClick={() => {
            action(id);
          }}
          key={id}
          className={styles.listItem}
        >
          <button
            className={`${styles.checkbox} ${
              selected ? styles.checkbox_active : ""
            }`}
          >
            {selected && (
              <img
                src={require("../../assets/check.svg").default}
                alt="close"
                width="12px"
                height={"12px"}
              />
            )}
          </button>
          <p className={styles.content}>
            {name.length > 10 ? `${name.slice(0, 15)}...` : name}
          </p>
        </div>
      )}
    </>
  );
};

export default React.memo(BrandOptionItem);
