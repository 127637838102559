import React, { ReactNode } from "react";
import Blanket from "../Blanket";
import Menu from "../Menu";

const Dropdown: React.FC<{
  children?: ReactNode;
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
}> = ({ children, isOpen, target, onClose }) => (
  <div style={{ position: "relative" }}>
    {target}
    {isOpen ? <Blanket onClick={onClose} /> : null}
    {isOpen ? <Menu>{children}</Menu> : null}
  </div>
);

export default Dropdown;
