import React from "react";
import { Controller, FieldPath } from "react-hook-form";
import Select from "react-select";
import CrossIcon from "../../../assets/images/CrossIcon";
import images from "../../../assets/images/images";
import ErrorMessage, { IErrorMessage } from "../ErrorMessage";
import styles from "./styles.module.sass";
import { MultipleItemSelect, MultipleSelectProps } from "./types";

export const TagsSelect = <
  T extends string | number,
  TFieldValues extends Record<string, any>,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  className,
  options,
  label,
  styles: selectStyles,
  name,
  control,
  onSelect,
  placeholder,
  allText,
}: MultipleSelectProps<T, TFieldValues, TName>) => {
  const getTags = (values: T[]) => {
    return (options || []).filter((option) =>
      (values || []).includes(option.value)
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value: tags }, fieldState: { error } }) => {
        return (
          <div className={`${styles.main} ${className}`}>
            {label && (
              <React.Fragment>
                {React.isValidElement(label) ? (
                  label
                ) : (
                  <span className={styles.label}>{label}</span>
                )}
              </React.Fragment>
            )}
            <Select
              isMulti
              styles={{
                ...selectStyles,
                valueContainer: (base, props) => {
                  return selectStyles?.valueContainer
                    ? selectStyles.valueContainer(base, props)
                    : {
                        ...base,
                        padding: 0,
                      };
                },
                multiValue: (base, props) => {
                  return selectStyles?.multiValue
                    ? selectStyles?.multiValue(base, props)
                    : {
                        ...base,
                        backgroundColor: props.data.backgroundColor,
                        color: props.data.color,
                        padding: "6.5px 8px",
                        fontSize: "17px",
                        height: "32px",
                        alignItems: "center",
                        fontWeight: 700,
                      };
                },
                clearIndicator: (base, props) => {
                  return selectStyles?.clearIndicator
                    ? selectStyles?.clearIndicator(base, props)
                    : { display: "none" };
                },
                control(base, props) {
                  return selectStyles?.control
                    ? selectStyles.control(base, props)
                    : {
                        display: "flex",
                        height: "48px",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        border: error?.message
                          ? "1px solid #EE5253"
                          : "1px solid #D1D1D6",
                        borderRadius: "8px",
                        padding: "8px 16px",
                      };
                },
                indicatorSeparator(base, props) {
                  return selectStyles?.indicatorSeparator
                    ? selectStyles.indicatorSeparator(base, props)
                    : { display: "none" };
                },
                dropdownIndicator(base, props) {
                  return selectStyles?.dropdownIndicator
                    ? selectStyles.dropdownIndicator(base, props)
                    : {
                        ...base,
                      };
                },
              }}
              value={getTags(tags)}
              isSearchable={false}
              placeholder={placeholder ?? "Select tags..."}
              components={{
                DropdownIndicator({ selectProps: { menuIsOpen } }) {
                  return (
                    <img
                      src={images.ic_chevron_down}
                      style={menuIsOpen ? { transform: "rotate(180deg)" } : {}}
                    />
                  );
                },
                MultiValueRemove(props) {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        onChange(
                          tags.filter((tag: T) => tag !== props.data.value)
                        );
                        if (onSelect) {
                          onSelect(props.data.value);
                        }
                      }}
                    >
                      <CrossIcon
                        strokeWidth="4"
                        stroke={props.data.color}
                        height="16px"
                        width="16px"
                      />
                    </div>
                  );
                },
                MenuList(MenuListProps) {
                  return (
                    <div>
                      {allText && (
                        <div
                          onClick={() => {
                            onChange(
                              tags.length > 0
                                ? []
                                : options.map((option) => option.value)
                            );
                            if (onSelect) {
                              onSelect(tags);
                            }
                          }}
                          className={styles.allChannelsWrapper}
                        >
                          <div
                            className={
                              !(tags || []).length
                                ? styles.allChannelscheckbox
                                : styles.allChannelsSelectedCheckbox
                            }
                          >
                            {!!tags.length && (
                              <img
                                src={
                                  options.length === tags.length
                                    ? images.whitecheckicon
                                    : images.minusIcon
                                }
                              />
                            )}
                          </div>
                          <span className={styles.allChannelsLabel}>
                            {allText}
                          </span>
                        </div>
                      )}
                      {(MenuListProps.options as MultipleItemSelect<T>[]).map(
                        (option, index) => (
                          <div
                            className={styles.dropdownElement}
                            key={index}
                            onClick={() => {
                              if (tags.includes(option.value)) {
                                onChange(
                                  tags.filter((tag: T) => tag !== option.value)
                                );
                              } else {
                                onChange(Array.from(tags).concat(option.value));
                              }
                              if (onSelect) {
                                onSelect(option.value);
                              }
                            }}
                          >
                            <div
                              className={
                                tags.includes(option.value)
                                  ? styles.selectedCheckbox
                                  : styles.checkbox
                              }
                            >
                              {tags.includes(option.value) && (
                                <img src={images.whitecheckicon} />
                              )}
                            </div>
                            {option.label}
                          </div>
                        )
                      )}
                    </div>
                  );
                },
              }}
              menuPlacement="auto"
              options={options}
            />
            <ErrorMessage
              message={((error as IErrorMessage)?.message as string) || ""}
              type={((error as IErrorMessage)?.type as string) || ""}
            />
          </div>
        );
      }}
    />
  );
};

export default TagsSelect;
