import React from "react";
export interface ButtonProps
  extends React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    React.AriaAttributes {}

export const Button: React.FC<ButtonProps> = (props) => {
  const { children } = props;
  return (
    <button data-testid="test-see-all-button" {...props}>
      {children}
    </button>
  );
};

export default Button;
