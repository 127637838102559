import images from "../assets/images/images";
import { AddBrand, Country, CountryCode } from "../pages/Promocode/types";

export interface PromocodeOptions {
  id: number;
  value: string;
  label: string;
}

export const PromocodeVisibility: PromocodeOptions[] = [
  {
    id: 1,
    value: "visible",
    label: "Normal",
  },
  {
    id: 2,
    value: "invisible",
    label: "Hidden",
  },
];
export const PromocodeTypes: PromocodeOptions[] = [
  {
    id: 1,
    label: "Percentage",
    value: "discount-percentage",
  },
  {
    id: 2,
    label: "Discount Value",
    value: "discount-value",
  },
  {
    id: 3,
    label: "Point Muliplier",
    value: "points-multiplier",
  },
];

export const PromocodeTypesWithCashback: PromocodeOptions[] = [
  ...PromocodeTypes,
  {
    id: 4,
    label: "Cashback",
    value: "cashback-value",
  },
];

export const PromocodeTypesWithoutPointMultiplier: PromocodeOptions[] = [
  {
    id: 1,
    label: "Percentage",
    value: "discount-percentage",
  },
  {
    id: 2,
    label: "Discount Value",
    value: "discount-value",
  },

  {
    id: 3,
    label: "Cashback",
    value: "cashback-value",
  },
];
export const DeliveryAndPickup: PromocodeOptions[] = [
  {
    id: 1,
    label: "Delivery + Pickup",
    value: "ordering",
  },
  {
    id: 3,
    value: "delivery",
    label: "Delivery Only",
  },
  {
    id: 3,
    value: "pickup",
    label: "Pickup Only",
  },
];
export const COUNTRIES: Country[] = [
  {
    id: 1,
    image: images.egyFlag,
    countryName: "Egypt",
    countryCode: CountryCode.EGY,
  },
  {
    id: 2,
    image: images.ksaFlage,
    countryName: "Saudi Arabia",
    countryCode: CountryCode.KSA,
  },
];
export const ADD_BRAND: AddBrand[] = [
  {
    id: "1",
    brand: "Add Manually",
  },
  {
    id: "2",
    brand: "Add using CSV Template",
  },
];

export const PointMultiplierValue: PromocodeOptions[] = [
  {
    id: 1,
    label: "2X",
    value: "2X",
  },
  {
    id: 2,
    label: "3X",
    value: "3X",
  },
  {
    id: 3,
    label: "4X",
    value: "4X",
  },
  {
    id: 4,
    label: "5X",
    value: "5X",
  },
];
