import { PromotionType, VisibilityType } from "../layouts/Promocode/types";

export const login = "/api/shopxadmin/login";
export const getBrands = "/api/country-active-stores";
export const getPromoByRank = "/api/admin/promo-code/get-promo-by-rank";
export const getPromoHighestRank = "/api/v1/admin/promotions/highest-rank";
export const checkPromoCodeValidity =
  "/api/admin/promo-code/check-availability";
export const promotionsURL = "/api/v1/admin/promotions";
export const promoCodeCreation = (
  promotion_type: PromotionType,
  customer_type: string,
  visibility: VisibilityType
) =>
  `/api/v1/admin/promotions/${promotion_type}/${customer_type}/${visibility}`;
export const promocodeTragetedCustomerFileUpload =
  "/api/v1/admin/promotions/customers-file-upload";
export const addBrandsCSV = "/api/admin/promo-code/add-brands/csv";
export const getPromocodes = "/api/admin/promo-code/get-promotions";
export const getTemplates = "/api/v1/templates";
export const getCampaigns = "/api/v1/campaigns/metrics";
export const getCampaignsCount = "/api/v1/campaigns/metrics/total-count";
