import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery, getPromocodes } from "../../services";

// Define a service using a base URL and expected endpoints
export const getPromocodesApi = createApi({
  reducerPath: "getPromocodesReducer",
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_ORDERING_ENDPOINT}`,
  }),
  endpoints: (builder) => ({
    getPromocodes: builder.mutation<any, void>({
      query: () => {
        return {
          method: "get",
          url: getPromocodes + "?page=0&per_page=15 ",
        };
      },
    }),
  }),
});
export const { useGetPromocodesMutation } = getPromocodesApi;
