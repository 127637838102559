import React from "react";
import styles from "./styles.module.sass";
import images from "../../../../assets/images/images";
export interface IPromocodeInfoCard {
  promo_info: {
    promocode: string;
    description: string;
    id: string;
  };
}
export interface IPromocodeInfoCardProps {
  selectedPromoCodeInfo: {
    value: string;
    label: string;
    description?: string;
  };
}
const PromocodeInfoCard: React.FC<IPromocodeInfoCardProps> = ({
  selectedPromoCodeInfo: { label, value, description },
}: IPromocodeInfoCardProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.image}>
        <img src={images.discount} alt="" />
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span>{label}</span>
          </div>
          <div className={styles.link}>
            <img src={images.link} alt={value} />
            <span>See Details</span>
          </div>
        </div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};

export default PromocodeInfoCard;
