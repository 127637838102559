import React, { useMemo, useState } from "react";
import styles from "./styles.module.sass";
import { Controller, useFormContext } from "react-hook-form";
import { ChevronDown } from "../../../../assets/images/images";
import { Dropdown } from "../../../../components";
import { CampaignFormValues } from "../../../../types";

export const LogicalOperator: React.FC<{ index: number }> = ({ index }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { control, watch } = useFormContext<CampaignFormValues>();
  const watchers = watch();
  const options = [
    { value: "and", label: "AND" },
    { value: "or", label: "OR" },
  ];

  const selectedOperator = useMemo(
    () =>
      options.find((element) =>
        element.value.includes(watchers.segment[index].filter.logical_operator)
      )?.label,
    [watchers.segment[index].filter.logical_operator]
  );
  return (
    <Controller
      control={control}
      name={`segment.${index}.filter.logical_operator`}
      render={({ field: { onChange } }) => (
        <div>
          <Dropdown
            isOpen={menuOpen}
            onClose={() => {
              setMenuOpen(false);
            }}
            target={
              <div
                className={styles.dropdownBtn}
                onClick={() => setMenuOpen((prev) => !prev)}
              >
                <span className={styles.dropdownLabel}>{selectedOperator}</span>
                <ChevronDown
                  width={20}
                  height={20}
                  className={menuOpen ? styles.dropdownOpened : ""}
                  style={{ marginLeft: "4px" }}
                  stroke={"#503E9D"}
                />
              </div>
            }
          >
            <div className={styles.dropdownContainer}>
              {options.map((element) => (
                <div
                  onClick={() => {
                    onChange(element.value);
                    setMenuOpen(false);
                  }}
                  key={element.value}
                  className={
                    watchers.segment[index].filter.logical_operator ===
                    element.value
                      ? styles.selectedItemWrapper
                      : styles.dropdownWrapper
                  }
                >
                  <span className={styles.itemDropdropLabel}>
                    {element.label}
                  </span>
                </div>
              ))}
            </div>
          </Dropdown>
        </div>
      )}
    />
  );
};

export default LogicalOperator;
