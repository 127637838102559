import React from "react";
import { Navigate } from "react-router-dom";
import { useSelect } from "../../hooks";
const NotFound: React.FC = () => {
  const {
    authReducer: { isLoggedIn },
  } = useSelect((state) => state);

  return <Navigate to={isLoggedIn ? "/promocodes" : "/login"} />;
};

export default NotFound;
