import React from "react";

interface ITragetIconProps {
  clicked: boolean;
}
export const TargetIcon: React.FC<ITragetIconProps> = ({ clicked }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 10L17.5 2.5"
        stroke={clicked ? "#FFA351" : "#AEAEB2"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3048 4.69559C13.9751 3.36196 12.1933 2.5761 10.3119 2.49356C8.43047 2.41101 6.58663 3.03779 5.14531 4.24985C3.70399 5.46191 2.77022 7.1709 2.52882 9.03858C2.28742 10.9063 2.75599 12.7965 3.84178 14.3352C4.92757 15.8739 6.55145 16.9489 8.39201 17.3474C10.2326 17.7459 12.1557 17.4389 13.7807 16.4872C15.4057 15.5354 16.6142 14.0083 17.167 12.208C17.7198 10.4078 17.5766 8.4656 16.7657 6.7659"
        stroke={clicked ? "#FFA351" : "#AEAEB2"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6483 7.35114C12.0289 6.73353 11.2128 6.35237 10.3416 6.27389C9.4705 6.19541 8.59939 6.42455 7.87962 6.92153C7.15985 7.4185 6.6369 8.15189 6.40161 8.99432C6.16632 9.83675 6.23356 10.735 6.59164 11.533C6.94973 12.331 7.57603 12.9784 8.36177 13.3627C9.1475 13.7469 10.043 13.8439 10.8928 13.6366C11.7425 13.4293 12.4928 12.9308 13.0133 12.2279C13.5338 11.525 13.7916 10.6619 13.742 9.78864"
        stroke={clicked ? "#FFA351" : "#AEAEB2"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TargetIcon;
