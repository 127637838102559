import { DefaultOptionType } from "antd/lib/select";
import {
  allOperators,
  arrayOrEqualityOperators,
  booleanProperties,
  OPERATORS_OPTIONS,
} from "./../constant/customer-segmention-options";
export const operatorsOptions = (value: string) => {
  let results: DefaultOptionType[] = [];
  if (value in arrayOrEqualityOperators) {
    results = [
      {
        label: "inArray",
        value: "inArray",
      },
      {
        label: "notInArray",
        value: "notInArray",
      },
      {
        label: "=",
        value: "equal",
      },
      {
        label: "≠",
        value: "notEqual",
      },
    ];
  } else if (value in booleanProperties) {
    results = [
      {
        label: "=",
        value: "equal",
      },
      {
        label: "≠",
        value: "notEqual",
      },
    ];
  } else if (value in allOperators) {
    return OPERATORS_OPTIONS;
  } else {
    results = [
      {
        label: ">",
        value: "greater",
      },
      {
        label: "<",
        value: "less",
      },
      {
        label: "=",
        value: "equal",
      },
      {
        label: "≠",
        value: "notEqual",
      },
      {
        label: ">=",
        value: "greaterOrEqual",
      },
      {
        label: "<=",
        value: "lessOrEqual",
      },
    ];
  }
  return results;
};
