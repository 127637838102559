import React from "react";
import images from "../../../../assets/images/images";
import styles from "./styles.module.sass";

export const UploadFaildCSV: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.warningImage}>
        <img src={images.faildUploadCSVIcon} />
      </div>

      <div className={styles.warningTextContainer}>
        <h3 className={styles.warningText}>
          Please upload a valid file extension (.CSV)
        </h3>
      </div>
    </div>
  );
};
