import React, { useMemo } from "react";
import images from "../../../../assets/images/images";
import { CustomPromSelectionSelect } from "../../PromocodeSelection";
import { getHighlights } from "./utils";
import styles from "./styles.module.sass";
export interface IConnectionItemCard {
  onSelect: (option: any) => void;
  control: any;
  options: any[];
  error: any;
  image: string;
  onClose: () => void;
  name: string;
  applyCheck: () => void;
  item: {
    id: string;
    name: string;
    template_id: string;
    attachActiveLink: boolean;
  };
}
export const ConnectionItemCard: React.FC<IConnectionItemCard> = ({
  onSelect,
  control,
  options,
  error,
  image,
  onClose,
  name,
  applyCheck,
  item,
}: IConnectionItemCard) => {
  const previewData = useMemo(() => {
    return options.find((option) => option.value == item.template_id);
  }, [item.template_id]);
  const description = useMemo(() => {
    let template: string = previewData?.description || "";
    const values = getHighlights(template);
    values.forEach((segment: string) => {
      template = template.replace(segment, `</template>`);
    });
    const segments: string[] = template.split("</template>");

    return segments.reduce((acc: any[], item, index) => {
      return index < values.length
        ? [
            ...acc,
            { item, isSegment: false },
            { item: values[index], isSegment: true },
          ]
        : [...acc, { item, isSegment: false }];
    }, []);
  }, [previewData]);

  return (
    <div className={styles.connection_card}>
      <div className={styles.card_header}>
        <div className={styles.title}>
          <img className={styles.icon} src={image} alt="" />
          <span className={styles.content}>
            {item.name
              .split(" ")
              .map((segment) =>
                segment
                  .split("")
                  .map((letter, index) =>
                    index == 0 ? letter.toUpperCase() : letter.toLowerCase()
                  )
                  .join("")
              )
              .join(" ")}
          </span>
        </div>
        <button type="button" onClick={onClose}>
          &times;
        </button>
      </div>
      <div className={styles.communication_body}>
        <div className={styles.content_selection}>
          <div className={styles.wrapper}>
            <CustomPromSelectionSelect
              onSelect={onSelect}
              overlayClassName={"overlayWrapper"}
              dropdownClassName={styles.dropdownWrapper}
              dropdownListClassName={styles.dropdown}
              label={"Template Name"}
              control={control as any}
              placeholder={"Select template..."}
              options={options}
              name={name}
              defaultValue={item.template_id}
            />
            {item.template_id ? (
              <p style={{ color: "#65656C", marginTop: "10px" }}>
                ID: {item.template_id}
              </p>
            ) : (
              <></>
            )}
            {error && (
              <span className={styles.userTypeError}>{error.message}</span>
            )}
          </div>
        </div>
        {item.template_id && (
          <div className={styles.card}>
            <div className={styles.floating_header}>
              <img src={images.preview} alt="" className={styles.icon} />
              <span className={styles.text}>Preview</span>
            </div>

            <div className={styles.content}>
              <h2 style={{ fontWeight: "600" }}>{previewData?.label}</h2>
              <div>
                {description.map((item, index) => {
                  return <Segment value={item} key={`index-${index}`} />;
                })}
              </div>
            </div>
          </div>
        )}

        <div className={styles.checkbox} onClick={applyCheck}>
          <div
            className={`${styles.box} ${
              item.attachActiveLink ? styles.active : ""
            }`}
          >
            {item.attachActiveLink && <img src={images.check} alt="" />}
          </div>
          <span className={styles.label}>Attach action link</span>
        </div>
      </div>
    </div>
  );
};

export const Segment: React.FC<{
  value: { item: string; isSegment: boolean };
}> = ({ value }) => {
  return (
    <span className={value.isSegment ? styles.segment : ""}>{value.item}</span>
  );
};
