import { toast } from "react-hot-toast";
export const notify = (
  content: string,
  status: "success" | "error" | "pending"
) => {
  switch (status) {
    case "success":
      toast(content, {
        style: {
          backgroundColor: "#38c172",
          fontWeight: 300,
          color: "#ffff",
        },
      });
      break;
    case "error":
      toast(content, {
        style: {
          backgroundColor: "#db3030",
          fontWeight: 300,
          color: "#ffff",
        },
      });
      break;
    default:
      toast(content, {
        style: {
          backgroundColor: "#f7cc49",
          fontWeight: 300,
          color: "#1a1a1a",
        },
      });
  }
};
