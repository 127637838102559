import React, { Fragment, useMemo } from "react";
import styles from "./styles.module.sass";
import images from "../../assets/images/images";
import { Link, useLocation, useParams } from "react-router-dom";
import { getOrigins, segmentFormatter } from "../../routes/utils";
import { useSelect } from "../../hooks";
// interface IHeaderProps {}

const Header: React.FC = () => {
  const { pathname } = useLocation();
  const params = useParams();
  const {
    routingReducer: { to },
  } = useSelect((state) => state);
  const pageName = useMemo(() => {
    const origins: any[] = getOrigins(
      to.path,
      params as { [key: string]: string }
    );
    return origins.map(({ label, path }, index) => {
      return index < origins.length - 1 ? (
        <Fragment key={index}>
          <Link to={`${path}`}>{segmentFormatter(label)}</Link>
          <span> / </span>
        </Fragment>
      ) : (
        <span key={index}> {segmentFormatter(label)}</span>
      );
    });
  }, [pathname, to]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.pageName}>{pageName}</div>
      <div className={styles.user}>
        <div className={styles.user_image}></div>
        <div>
          <img src={images.chevron_down} alt="chevron-down" />
        </div>
      </div>
    </div>
  );
};

export default Header;
