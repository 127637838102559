import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.sass";
import {
  GenericInputField,
  GenericConfimationModal,
} from "../../../components";
import images from "../../../assets/images/images";
import { useSelect } from "../../../hooks";
import { setInfo } from "../../../store/slices/promoInfo-slice";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useGetPromoByRankQuery } from "../../../store/apis/getpromoByRank-api";
import { useCheckPromocodeValidityQuery } from "../../../store/apis/checkPromocodeValidity-api";
import { IFormErrors } from "../../../components/Forms/StartEndDate/types";
import { promocodeFormInputs } from "../../../types";
import { FormValues } from "../../../pages/Promocode/types";
import { usePromocodeCreationMutation } from "../../../store/apis/createPromocode-api";
import { ClipLoader } from "react-spinners";
import { getPromoHighestRank } from "../../../store/thunks";

interface IPromoInfo {
  watchers: FormValues;
  register: any;
  clearErrors: (field?: promocodeFormInputs) => void;
  setError: (
    field: promocodeFormInputs,
    error: { type: string; message: string },
    options?: { shouldFocus: boolean }
  ) => void;
  errors: IFormErrors;
  shiftPromoRank: boolean;
  setShiftPromoRank: React.Dispatch<React.SetStateAction<boolean>>;
}
export const PromocodeInfo: React.FC<IPromoInfo> = ({
  watchers,
  clearErrors,
  register,
  setError,
  errors,
  setShiftPromoRank,
  shiftPromoRank,
}) => {
  const { rank, ar_desc, ar_title, desc, promo_code, title, country_code } =
    watchers;
  const dispatch = useAppDispatch();
  const {
    brandsReducer: { selectedBrands },
    ranksReducer: {
      ranks: { eg, sa },
    },
  } = useSelect((state) => state);
  const [
    createPromocode,
    { isLoading: creationIsLoading, isSuccess: creationIsSuccess },
  ] = usePromocodeCreationMutation();
  const [isRank, setIsRank] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  const {
    data: promoByRankData,
    isLoading,
    isSuccess,
  } = useGetPromoByRankQuery({
    country_code: country_code === "+2" ? "eg" : "sa",
    rank,
  });
  const { data: validtyData, error } = useCheckPromocodeValidityQuery({
    stores_ids: selectedBrands.map((item) => item.id),
    promo_code,
  });
  const messageContent = useMemo(() => {
    return isLoading ? (
      <p className={styles.infoMessage}>
        <img src={images.infoIcon} alt="info" />
        Loading...
      </p>
    ) : isSuccess ? (
      isRank && rank ? (
        <p className={styles.infoMessage}>
          <img src={images.infoIcon} alt="info" />
          The
          {country_code === "+2" ? ` EGY-${rank} ` : ` SA-${rank} `}
          rank is already taken
        </p>
      ) : undefined
    ) : undefined;
  }, [isLoading, isSuccess, country_code, isRank, rank, images.infoIcon]);
  useEffect(() => {
    dispatch(setInfo({ rank, ar_desc, ar_title, desc, promo_code, title }));
  }, [rank, ar_desc, ar_title, desc, promo_code, title]);

  useEffect(() => {
    const hasValues = Object.values(
      promoByRankData ? promoByRankData : {}
    ).length;
    setIsRank(hasValues > 0 ? true : false);
  }, [promoByRankData]);
  useEffect(() => {
    const { status }: { status: number; data: any } = error
      ? (error as { status: number; data: any })
      : validtyData
      ? { status: 200, data: undefined }
      : { status: 400, data: undefined };
    setIsValid(status === 200 ? false : true);
  }, [error, validtyData]);
  useEffect(() => {
    if (promo_code)
      isValid
        ? setError(
            "promo_code",
            {
              message:
                " This promo code maybe in one or more of this stores already.",
              type: "taken-promocode",
            },
            { shouldFocus: true }
          )
        : clearErrors("promo_code");
  }, [isValid, promo_code]);

  useEffect(() => {
    if (creationIsSuccess) {
      setShiftPromoRank(false);
    }
  }, [creationIsSuccess]);
  return (
    <>
      <div className={styles.divider}>
        <GenericInputField
          register={register("title")}
          type={"text"}
          placeholder={"50% Cashback! Code KN50"}
          label={"Promocode title (English)"}
          error={errors?.title}
        />
        <GenericInputField
          register={register("ar_title")}
          type={"text"}
          placeholder={"%50 كاش باك! كود KN50"}
          label={"Promocode title (بالعربية)"}
          error={errors?.ar_title}
        />
      </div>
      <div className={styles.divider}>
        <GenericInputField
          register={register("desc")}
          type={"text"}
          placeholder={"Enter promocode description..."}
          label={"Promocode description (English)"}
          error={errors?.desc}
        />
        <GenericInputField
          register={register("ar_desc")}
          type={"text"}
          placeholder={"Enter promocode description..."}
          label={"Promocode description (بالعربية)"}
          error={errors?.ar_desc}
        />
      </div>
      <div className={styles.divider}>
        <div className={styles.divider}>
          <GenericInputField
            register={register("promo_code")}
            type={"text"}
            placeholder={"Ex: Koinz-20"}
            label={"Promocode name"}
            error={errors?.promo_code}
          />
          <GenericInputField
            register={register("rank")}
            type={"number"}
            onBlur={() => {
              dispatch(getPromoHighestRank({}));
            }}
            placeholder={"0,1,2,3..."}
            label={
              country_code
                ? `Promocode Rank (Top Rank: ${
                    country_code === "+2" ? "EGY-" + eg : "SA-" + sa
                  })`
                : `Promocode Rank`
            }
            error={errors?.rank}
            content={messageContent}
          />
        </div>
      </div>
      {shiftPromoRank && (
        <GenericConfimationModal
          isOpen={shiftPromoRank}
          onClose={() => {
            setShiftPromoRank(false);
          }}
          applyAction={() => {
            createPromocode({ data: watchers });
          }}
          alertType="warn"
          buttonContent={
            creationIsLoading ? (
              <div style={{ minWidth: "114px" }}>
                <ClipLoader color="#fff" size={22} />
              </div>
            ) : (
              <div style={{ minWidth: "114px" }}>
                <span style={{ fontSize: "18px", fontWeight: 700 }}>
                  Shift all ranks
                </span>
              </div>
            )
          }
          description={`Do you wish to assign this rank to this promocode “${promo_code}” and shift all other ranks`}
          title={`The
          ${country_code === "+2" ? ` EGY-${rank} ` : ` SA-${rank} `}
          rank is already taken`}
        />
      )}
    </>
  );
};

export default PromocodeInfo;
