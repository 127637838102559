import { ISelectItem } from "../components/shared/types";

export const OPERATORS_OPTIONS: ISelectItem<string>[] = [
  {
    label: ">",
    value: "greater",
  },
  {
    label: "<",
    value: "less",
  },
  {
    label: "=",
    value: "equal",
  },
  {
    label: "≠",
    value: "notEqual",
  },
  {
    label: ">=",
    value: "greaterOrEqual",
  },
  {
    label: "<=",
    value: "lessOrEqual",
  },
  {
    label: "inArray",
    value: "inArray",
  },
  {
    label: "notInArray",
    value: "notInArray",
  },
];

export enum OperatorAsArray {
  inArray = "inArray",
  notInArray = "notInArray",
}
const { inArray, notInArray } = OperatorAsArray;
export const operatorAsArray = {
  inArray,
  notInArray,
};

export const sharedUserOptions: ISelectItem<string>[] = [
  {
    label: "customer_id",
    value: "customer_id",
  },
  {
    label: "days_since_last_visit",
    value: "days_since_last_visit",
  },
  {
    label: "most_visited_store_id",
    value: "most_visited_store_id",
  },
  {
    label: "second_most_visited_store_id",
    value: "second_most_visited_store_id",
  },
  {
    label: "most_visited_store_category",
    value: "most_visited_store_category",
  },
  {
    label: "eligible_gift_in_most_visited_store",
    value: "eligible_gift_in_most_visited_store",
  },
  {
    label: "last_visited_store_id",
    value: "last_visited_store_id",
  },
  {
    label: "phone_number",
    value: "phone_number",
  },
  {
    label: "country_code",
    value: "country_code",
  },
];
export const onlineAppUserOptions: ISelectItem<string>[] = [
  {
    label: "online_visited_stores_count",
    value: "online_visited_stores_count",
  },
  {
    label: "online_orders_count",
    value: "online_orders_count",
  },
  {
    label: "online_orders_with_promo_count",
    value: "online_orders_with_promo_count",
  },
  {
    label: "online_orders_with_promo_current_month_count",
    value: "online_orders_with_promo_current_month_count",
  },
  {
    label: "online_redemptions_count",
    value: "online_redemptions_count",
  },
  {
    label: "last_order_time",
    value: "last_order_time",
  },
  // {
  //   label: "most_visited_zone_rank", //
  //   value: "most_visited_zone_rank", //
  // },
  // {
  //   label: "customer_name",
  //   value: "customer_name",
  // },
  // {
  //   label: "most_visited_zone",
  //   value: "most_visited_zone",
  // },
];

export const offlineAndFloatingUserOptions: ISelectItem<string>[] = [
  {
    label: "offline_redemptions_count",
    value: "offline_redemptions_count",
  },
];

export const lifetimeOfflineOptions: ISelectItem<string>[] = [
  {
    label: "lifetime_offline_visits",
    value: "lifetime_offline_visits",
  },
  {
    label: "lifetime_offline_ats",
    value: "lifetime_offline_ats",
  },
];
export const notTempUserOption: ISelectItem<string>[] = [
  {
    label: "signup_type",
    value: "signup_type",
  },
  {
    label: "signup_date",
    value: "signup_date",
  },
  {
    label: "days_since_signup",
    value: "days_since_signup",
  },
  {
    label: "uninstall_status",
    value: "uninstall_status",
  },
];

export const userPropertyAsANumber = {
  days_since_last_visit: "days_since_last_visit",
  days_since_signup: "days_since_signup",
  lifetime_offline_ats: "lifetime_offline_ats",
  offline_redemptions_count: "offline_redemptions_count",
  online_orders_count: "online_orders_count",
  online_orders_with_promo_count: "online_orders_with_promo_count",
  online_orders_with_promo_current_month_count:
    "online_orders_with_promo_current_month_count",
  online_redemptions_count: "online_redemptions_count",
  online_visited_stores_count: "online_visited_stores_count",
};

export enum UserPropertyAsAString {
  most_visited_store_id = "most_visited_store_id",
  second_most_visited_store_id = "second_most_visited_store_id",
  last_visited_store_id = "last_visited_store_id",
  customer_id = "customer_id",
  phone_number = "phone_number",
  country_code = "country_code",
  signup_type = "signup_type",
}

export const userPropertyAsAString = {
  country_code: "country_code",
  customer_id: "customer_id",
  last_visited_store_id: "last_visited_store_id",
  most_visited_store_id: "most_visited_store_id",
  phone_number: "phone_number",
  second_most_visited_store_id: "second_most_visited_store_id",
  signup_type: "signup_type",
};

export const booleanProperties = {
  eligible_gift_in_most_visited_store: "eligible_gift_in_most_visited_store",
  uninstall_status: "uninstall_status",
};

export type UserType = "online_app_user" | "offline_app_user" | "temp_user";

export const arrayOrEqualityOperators = {
  customer_id: "customer_id",
  last_visited_store_id: "last_visited_store_id",
  most_visited_store_id: "most_visited_store_id",
  second_most_visited_store_id: "second_most_visited_store_id",
  signup_type: "signup_type",
  country_code: "country_code",
  phone_number: "phone_number",
  most_visited_store_category: "most_visited_store_category",
};

export const allOperators = {
  days_since_last_visit: "days_since_last_visit",
  days_since_signup: "days_since_signup",
};

export const numericOperators = {
  lifetime_offline_ats: "lifetime_offline_ats",
  offline_redemptions_count: "offline_redemptions_count",
  online_orders_count: "online_orders_count",
  online_orders_with_promo_count: "online_orders_with_promo_count",
  online_orders_with_promo_current_month_count:
    "online_orders_with_promo_current_month_count",
  online_redemptions_count: "online_redemptions_count",
  online_visited_stores_count: "online_visited_stores_count",
  signup_date: "signup_date",
  last_order_time: "last_order_time",
};
