import moment from "moment";
import * as yup from "yup";
import { booleanProperties } from "../../constant/customer-segmention-options";
import { TimeTriggerFormValues } from "../../types";

declare module "yup" {
  interface ArraySchema<T> {
    unique(message: string): ArraySchema<T>;
  }
}
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

yup.addMethod(yup.array, "unique", function (message) {
  return this.test("unique", message, function (array: any) {
    const uniqueArray: any[] = [];
    array.forEach((x: any) => {
      if (
        !uniqueArray.some((y: any) => {
          return JSON.stringify(y) === JSON.stringify(x);
        })
      ) {
        uniqueArray.push(x);
      }
    });
    return uniqueArray.length === array.length;
  });
});

const timeTigger = () => {
  return yup
    .array()
    .min(1, "Add at least 1 time trigger")
    .of(
      yup.object().shape({
        month: yup.array().min(1, "Please choose a month"),
        time: yup.number().required("*Required"),
        repeat_start: yup
          .number()
          .test(
            "repeat_start",
            "Start time must be after current time",
            (value) => {
              const isToday = moment(0, "HH").diff(value, "days") == 0;
              if (isToday) {
                return moment(value).isBefore(moment.now()) ? false : true;
              }
              return true;
            }
          ),
        week_day: yup.array().min(1, "Please choose a day"),
        week: yup.array().min(1, "Please choose a week"),
      })
    )
    .test(
      "unique-time-trigger",
      "Please add a unique time trigger",
      function (array: any) {
        const uniqueArray: any[] = [];
        array.forEach((x: TimeTriggerFormValues) => {
          if (
            !uniqueArray.some((y: TimeTriggerFormValues) => {
              return (
                JSON.stringify({
                  month: y.month,
                  week: y.week,
                  repeat: y.repeat,
                  week_day: y.week_day,
                }) ===
                  JSON.stringify({
                    month: x.month,
                    week: x.week,
                    repeat: x.repeat,
                    week_day: x.week_day,
                  }) &&
                moment(x.time).format("h:mm a") ===
                  moment(y.time).format("h:mm a") &&
                moment(x.repeat_start).format("D MMMM yyyy") ===
                  moment(y.repeat_start).format("D MMMM yyyy")
              );
            })
          ) {
            uniqueArray.push(x);
          }
        });
        return uniqueArray.length === array.length;
      }
    );
};

const segment = () => {
  return yup
    .array()
    .min(1, "Add at least 1 segment")
    .of(
      yup.object().shape({
        title: yup.object().shape({
          currentValue: yup.string().required("Please add segment name"),
          prevValue: yup.string().required("Press enter to save"),
        }),
        user_type: yup.array().min(1, "Please select users type"),
        filter: yup.object().shape({
          conditions: yup
            .array()
            .min(1, "Add at least 1 condition")
            .of(
              yup.object().shape({
                operator: yup.string().required("Please select operator"),
                value: yup
                  .string()
                  .required("*Required")
                  .test(
                    "eligible_gift_in_most_visited_store" || "uninstall_status",
                    "Value must be a boolean value",
                    (value, context) => {
                      if (context.parent.field in booleanProperties) {
                        return /(true|false)$/.test(
                          String(value).trim().toLowerCase()
                        );
                      }
                      return true;
                    }
                  )
                  .test(
                    "phone_number",
                    "Phone Number is invalid",
                    (value, context) => {
                      if (context.parent.field === "phone_number") {
                        if (
                          context.parent.operator === "inArray" ||
                          context.parent.operator === "notInArray"
                        ) {
                          const index = String(value)
                            ?.split(",")
                            .findIndex(
                              (element) => !phoneRegExp.test(element.trim())
                            );
                          if (index > -1) {
                            return false;
                          }
                        } else {
                          return phoneRegExp.test(String(value).trim());
                        }
                      }
                      return true;
                    }
                  ),
                field: yup.string().required("Please select segment"),
              })
            ),
        }),
        has_promotion_id: yup.boolean(),
        connections: yup
          .array()
          .of(
            yup.object().shape({
              name: yup.string().required(),
              id: yup.string().required(),
              attachActiveLink: yup.boolean(),
              template_id: yup.string().required(),
            })
          )
          .nullable()
          .test(
            "required",
            "Your campaign has to have at least one connection",
            (value) => {
              return !value?.length;
            }
          ),
        promotion_id: yup
          .string()
          .optional()
          .test(
            "required",
            "promotion id field is required",
            (value, context) => {
              const hasPromotionId = context.parent.has_promotion_id;
              return hasPromotionId ? !!value : true;
            }
          ),
      })
    );
};

export const campaignValidatorSchema = yup.object().shape({
  title: yup.string().required("Campaign title is required"),
  end_time: yup
    .number()
    .positive()
    .required("Campaign end time is required")
    .test(
      "time-error",
      "Campaign time must be after current time.",
      (value) => {
        const isAfter = Number(value || 0) >= new Date().getTime();
        return isAfter;
      }
    ),
  time_triggers: timeTigger(),
  segment: segment(),
});
