import React, { useMemo } from "react";
import { DefaultOptionType } from "antd/lib/select";
import { Select } from "antd";
import {
  Control,
  Controller,
  FieldErrorsImpl,
  FieldPath,
  FieldValues,
  FormState,
  UseFormGetFieldState,
} from "react-hook-form";
import { ArrowDown } from "../../assets";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import "./styles.less";
import { FormValues } from "../../pages/Promocode/types";
import styles from "./styles.module.sass";

export const GenericDropdown = <
  T extends FieldValues,
  TName extends FieldPath<T> = FieldPath<T>
>({
  name,
  className,
  label,
  control,
  style,
  labelStyle,
  options,
  size,
  arrowColor,
  value,
  customOnChange,
  errors,
  placeholder,
  dropdownClassName,
  errorDropdownClassName,
  formState,
  getFieldState,
}: {
  name: TName;
  className?: string;
  label?: string;
  control: Control<T, any> | undefined;
  style?: React.CSSProperties;
  labelStyle?: string;
  options: DefaultOptionType[];
  size?: SizeType;
  arrowColor?: string;
  value?: any;
  customOnChange?: (value: any) => void;
  errors?: FieldErrorsImpl<FormValues>;
  placeholder?: string;
  dropdownClassName?: string;
  errorDropdownClassName?: string;
  formState?: FormState<FieldValues>;
  getFieldState?: UseFormGetFieldState<FieldValues>;
}) => {
  const fieldState = useMemo(() => {
    if (getFieldState && formState) {
      return getFieldState(name, formState);
    }
  }, [name, formState]);
  return (
    <div style={style} className={className}>
      {label && <span className={`${labelStyle}`}>{label}</span>}
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Select
              labelInValue={true}
              options={options}
              placeholder={placeholder}
              value={value}
              className={`${
                errors && errors[name]?.message
                  ? "dropdown-error"
                  : "generic-dropdown"
              } ${
                fieldState?.error && fieldState?.error.message
                  ? errorDropdownClassName
                  : dropdownClassName
              }`}
              suffixIcon={<ArrowDown stroke={arrowColor as string} />}
              size={size}
              onChange={(value) => {
                field.onChange(value.value);
                customOnChange ? customOnChange(value) : null;
              }}
            />
          );
        }}
      />
      {errors && errors[name]?.message && (
        <span className={styles.errorMessage}>
          {" "}
          {errors[name]?.message?.toString()}
        </span>
      )}
      {fieldState?.error && fieldState?.error.message && (
        <span className={styles.errorMessage}> {fieldState.error.message}</span>
      )}
    </div>
  );
};

export default GenericDropdown;
