const images = {
  egyFlag: require("../images/egyFlag.png"),
  ksaFlage: require("../images/ksaFlag.png"),
  collapesArrow: require("../images/collapesArrow.png"),
  closeBrandIcon: require("../images/closeBrandIcon.png"),
  infoIcon: require("../ic_info.svg").default,
  closeModalIcon: require("../images/closeModalIcon.png"),
  csvIcon: require("../images/csvIcon.png"),
  downloadCsvIcon: require("../images/downloadCsvIcon.png"),
  uploadCsvIcon: require("../images/uploadCsvIcon.png"),
  uploadCSVRefresh: require("../images/uploadCSVRefresh.png"),
  faildUploadCSVIcon: require("../images/faildUploadCSVIcon.png"),
  refreshSMIcon: require("../images/ic_refresh.svg"),
  refreshLGIcon: require("../images/file_loading.svg"),
  fileIcon: require("../images/ic-file.svg"),
  checkIcon: require("../images/ic_check.svg").default,
  chevron_down: require("../images/chevron-down.svg").default,
  percentage: require("../images/ic_percentage.svg").default,
  percentage_active: require("../images/ic_percentage_active.svg").default,
  target: require("../images/ic_target.svg").default,
  logo: require("../images/on-dark.svg").default,
  minusIcon: require("../images/minus.svg").default,
  whitecheckicon: require("../images/check.svg").default,
  ic_chevron_down: require("../images/ic_chevron_down.svg").default,
  ic_users: require("../images/ic_users.svg").default,
  ic_edit: require("../images/ic_edit.svg").default,
  ic_percentage_campaign: require("../images/ic_percentage_campaign.svg")
    .default,
  link: require("../images/ic-external-link.svg").default,
  discount: require("../images/discount.svg").default,
  search: require("../images/ic_search.svg").default,
  message: require("../images/ic_message_square.svg").default,
  notification: require("../images/push-otification.svg").default,
  sms: require("../images/sms.svg").default,
  preview: require("../images/ic_eye.svg").default,
  check: require("../images/check.svg").default,
  dropdown: require("../images/dropdown.svg").default,
  blackMessage: require("../images/ic_message.svg").default,
  whatsapp: require("../images/ic_whatsapp.svg").default,
  smartphone: require("../images/ic_smartphone.svg").default,
  messageSm: require("../images/ic_message_square_sm.svg").default,
  bell: require("../images/ic_bell.svg").default,
  percentageSm: require("../images/percentage_sm.svg").default,
};
export default images;
export { default as IconPlus } from "./IconPlus";
export { default as CrossIcon } from "./CrossIcon";
export { ReactComponent as ChevronDown } from "../images/ic_chevron_down.svg";
