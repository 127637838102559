import { Switch } from "antd";
import React from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import styles from "./styles.module.sass";

export const GenericSwitch = <
  T extends FieldValues,
  TName extends FieldPath<T> = FieldPath<T>
>({
  name,
  className,
  label,
  antdClassName,
  control,
  style,
  labelStyle,
  checked,
  handelSwitchOnChange,
}: {
  name: TName;
  className?: string;
  label?: string | JSX.Element;
  antdClassName?: string;
  control: Control<T, any> | undefined;
  style?: React.CSSProperties;
  labelStyle?: string;
  checked: boolean;
  handelSwitchOnChange: (value: any) => void;
}) => {
  return (
    <div style={style} className={`${styles.wrapper} ${className}`}>
      {React.isValidElement(label) ? (
        label
      ) : (
        <span className={`${labelStyle}`}>{label}</span>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Switch
              className={antdClassName}
              checked={checked}
              onChange={(value) => {
                field.onChange(value);
                handelSwitchOnChange(value);
              }}
            />
          );
        }}
      />
    </div>
  );
};

export default GenericSwitch;
