import React from "react";
import styles from "./styles.module.sass";
import { Modal } from "antd";
import { alert, cross } from "../../assets";
interface IGenericConfimationModal {
  isOpen: boolean;
  onClose: () => void;
  applyAction: () => void;
  title: string;
  description: string;
  buttonContent: string | JSX.Element;
  alertType: "warn" | "success";
}

enum AlertTypesEnum {
  warn = "##EE5253",
  success = "#FFA351",
}

const GenericConfimationModal: React.FC<IGenericConfimationModal> = ({
  isOpen,
  onClose,
  applyAction,
  title,
  description,
  buttonContent,
  alertType,
}) => {
  return (
    <Modal
      open={isOpen}
      destroyOnClose={true}
      closable={false}
      onCancel={onClose}
      title={""}
      footer={""}
      width={600}
      style={{ height: "auto" }}
      centered={true}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div>
            <button
              className={`${styles.close_cta} ${styles.center}`}
              onClick={onClose}
            >
              <img src={cross} alt="close" />
            </button>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.imageWrapper}>
            <img src={alert} alt="" />
          </div>
          <div className={styles.message}>
            <h3 className={styles.contentHeader}>{title}</h3>
            <p className={styles.contentMessage}>{description}</p>
          </div>
        </div>

        <div className={styles.footer}>
          <div>
            <button
              onClick={() => {
                applyAction();
              }}
              className={styles.cancel_cta}
              style={{ background: AlertTypesEnum[alertType] }}
            >
              {buttonContent}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(GenericConfimationModal);
