import { createSlice } from "@reduxjs/toolkit";

interface IState {
  code?: string;
  file_path?: string;
  status: "loading" | "succeed" | "failed" | undefined;
  message?: string;
}
const initialState: IState = {
  code: undefined,
  file_path: undefined,
  status: undefined,
  message: undefined,
};
export const customerFileVerificationSlice = createSlice({
  initialState,
  name: "customer-file-verification-slice",
  reducers: {
    customerFileVerificationEventSucceed: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    customerFileVerificationEventPending: (state) => ({
      ...state,
      status: "loading",
      code: undefined,
      file_path: undefined,
      message: undefined,
    }),
    customerFileVerificationEventReset: () => ({
      ...initialState,
    }),
  },
});

export const {
  customerFileVerificationEventPending,
  customerFileVerificationEventSucceed,
  customerFileVerificationEventReset,
} = customerFileVerificationSlice.actions;
export default customerFileVerificationSlice.reducer;
