import React from "react";
import styles from "./styles.module.sass";
import { ISubMenuTitleProps } from "./types";

const SubMenuTitle: React.FC<ISubMenuTitleProps> = ({
  title,
  icon,
  clicked,
}) => {
  return (
    <div
      className={`${
        clicked ? styles.dropdownSelected : styles.dropdownWrapper
      }`}
    >
      <div className={styles.dropdownContent}>
        <React.Fragment>
          <div className={styles.iconDropdown}>{icon}</div>
          <span className={styles.dropdownTitle}>{title}</span>
        </React.Fragment>
      </div>
    </div>
  );
};

export default SubMenuTitle;
