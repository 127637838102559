import React, { useState } from "react";
import images from "../../../../assets/images/images";
import ContentSelection from "../../ContentSelection";
import ConnectionSelectionModal from "../../ContentSelection/ConnectionSelectionModal";
import PromocodeSelection from "../../PromocodeSelection";
import SegmentationFilters from "../SegmentationFilters";
import UserType from "../UserType";
import { SegmentCardProps } from "./types";
import styles from "../styles.module.sass";

const SegmentCard: React.FC<SegmentCardProps> = ({
  index,
  promocodeOptions,
  templateOptions,
  watchers,
  setValue,
}: SegmentCardProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  return (
    <div className={styles.panelWrapper}>
      <div className={styles.segmentBody}>
        <UserType index={index} />
      </div>
      <SegmentationFilters index={index} />

      <PromocodeSelection
        promocodes={(promocodeOptions || []).filter((promocode) => {
          return promocode.is_expired !== 1;
        })}
        index={index}
      />
      <ContentSelection
        index={index}
        options={(templateOptions || [])
          .filter((option: any) => {
            return !option.is_expired;
          })
          .map((template: any) => {
            const option = {
              label: template.en_title ?? template.en_title,
              value: template.id,
              description: template.en_message ?? template.en_message,
            };
            return option;
          })}
      />
      <div className={styles.btnContentWrapper}>
        {!watchers.segment[index].has_promotion_id ? (
          <button
            onClick={() => {
              setValue(`segment.${index}.has_promotion_id`, true);
            }}
            type="button"
            className={styles.assignPromocodeWrapper}
          >
            <img
              src={images.ic_percentage_campaign}
              className={styles.percentageIcon}
            />
            <span className={styles.assignPromocodeTitle}>
              Assign Promocode
            </span>
          </button>
        ) : (
          <></>
        )}
        {!watchers.segment[index].connections.length ? (
          <button
            onClick={() => {
              setIsModalOpen(true);
            }}
            type="button"
            className={styles.assignPromocodeWrapper}
          >
            <img src={images.message} className={styles.percentageIcon} />
            <span className={styles.assignPromocodeTitle}>Add Content</span>
          </button>
        ) : (
          <></>
        )}
      </div>
      <ConnectionSelectionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(!isModalOpen)}
        segmentIndex={index}
      />
    </div>
  );
};

export default SegmentCard;
