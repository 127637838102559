import React, { useState, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import images, { IconPlus } from "../../../assets/images/images";
import { Collapse, Panel } from "../../../components";
import styles from "./styles.module.sass";
import { useGetPromocodesMutation } from "../../../store/apis/promocodeSelection";
import { CampaignFormValues } from "../../../types";
import { useGetTemplatesQuery } from "../../../store/apis/templateSelection";
import SegmentCard from "./SegmentCard";
import SegmentTitleInput from "./SegmentTitleInput";
const CustomerSegmentation: React.FC = () => {
  const [getPromocodes, { data: promocodeOptions }] =
    useGetPromocodesMutation();
  const { data: templatesOptions } = useGetTemplatesQuery();
  useEffect(() => {
    getPromocodes();
  }, []);

  const {
    control,
    formState: { errors },
    clearErrors,
    watch,
    setValue,
  } = useFormContext<CampaignFormValues>();
  const { fields, append } = useFieldArray({
    name: "segment",
    control,
  });
  const watchers = watch();
  const addNewSegmentation = () => {
    const newSegment = {
      actions: [],
      actions_dependencies: [],
      default_exclusion_filters: {
        is_in_exclusion_table: false,
      },
      filter: {
        conditions: [
          {
            field: "",
            operator: "equal",
            value: "",
          },
        ],
        logical_operator_array: [],
        logical_operator: "and",
      },
      title: {
        currentValue: "",
        prevValue: "",
        saved: false,
      },
      user_type: [],
      has_promotion_id: false,
      has_connections: false,
      connections: [],
    };
    append({
      ...newSegment,
    });

    clearErrors("segment");
  };

  const [assignedPromocode] = useState(false);
  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.headerIconWrapper}>
          <img className={styles.usersIcon} src={images.ic_users} />
        </div>
        <span className={styles.headerTitle}>
          Customer Segmentation & Actions
        </span>
      </div>
      <Collapse
        wrapperClassName={`${
          assignedPromocode
            ? styles.collapseWrapper
            : styles.pureCollapseWrapper
        }`}
        accordion={true}
        collapsible="icon"
      >
        {fields.map((field, index) => (
          <Panel
            key={`segment_${index}`}
            title={
              <div className={styles.segmentTitleWrapper}>
                <SegmentTitleInput index={index} />
                <div className={styles.selectedUserWrapper}>
                  <img
                    className={styles.selectedUserIcon}
                    src={images.ic_users}
                  />
                  <span className={styles.selectedUserTitle}>0 selected</span>
                </div>
              </div>
            }
          >
            <SegmentCard
              index={index}
              promocodeOptions={promocodeOptions}
              templateOptions={templatesOptions}
              setValue={setValue}
              watchers={watchers}
            />
          </Panel>
        ))}
      </Collapse>

      <button
        type={"button"}
        className={styles.addNewSegmentationBtn}
        onClick={addNewSegmentation}
      >
        <div className={styles.addNewSegmentationIcon}>
          <IconPlus />
        </div>
        Add new segmentation
      </button>
      <span className={styles.segmentationError}>
        {errors.segment?.message}
      </span>
    </div>
  );
};

export default CustomerSegmentation;
