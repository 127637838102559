import React, { useEffect } from "react";
import { notify } from "../../helpers";
const ConnectionDetector: React.FC = () => {
  useEffect(() => {
    window.addEventListener("online", () =>
      notify("Connection restored...", "success")
    );
    window.addEventListener("offline", () =>
      notify("Check internet connection...", "error")
    );
  }, []);

  return <></>;
};

export default ConnectionDetector;
