import React, { useEffect, useState } from "react";
import images from "../../../../assets/images/images";
import { ProgressBar } from "../../../ProgressBar";
import { ModalHeader } from "../ModalHeader";
import styles from "./styles.module.sass";

const STEPS = 10;
const DELAY = 50;
const BREAK_INTERVAL_VALUE = 70;

export const UploadSuccessCSV: React.FC = () => {
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    const progress = setInterval(
      () => setProgressValue(progressValue + STEPS),
      DELAY
    );
    if (progressValue === BREAK_INTERVAL_VALUE) {
      clearInterval(progress);
    }
    return () => clearInterval(progress);
  }, [progressValue]);

  return (
    <>
      <ModalHeader onClose={() => console.log} />
      <div className={styles.container}>
        <div className={styles.imagesContainer}>
          <img className={styles.rotateImage} src={images.uploadCSVRefresh} />
        </div>
        <div className={styles.descriptionAndTitleContainer}>
          <h3>File is uploading and processed...</h3>
          <h4 className={styles.title}>
            Dont close this window until processing finishes
          </h4>
        </div>
      </div>
      <div></div>

      <div className={styles.progressContainer}>
        <ProgressBar done={progressValue.toString()} />
      </div>
    </>
  );
};
