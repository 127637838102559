import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useDeepCompareMemo } from "use-deep-compare";
import images from "../../../../assets/images/images";
import { CampaignFormValues } from "../../../../types";
import styles from "./styles.module.sass";

const SegmentTitleInput: React.FC<{ index: number }> = ({ index }) => {
  const {
    watch,
    register,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext<CampaignFormValues>();
  const segmentWatcher = useMemo(() => watch().segment[index], [watch()]);
  const { currentValue, saved } = segmentWatcher.title;
  const editTitle = () => {
    setValue(`segment.${index}.title.saved`, false);
  };

  const titleErrors = useDeepCompareMemo(() => {
    if (errors.segment) {
      if (
        errors?.segment[index]?.title?.prevValue ||
        errors?.segment[index]?.title?.currentValue
      ) {
        return {
          prevValue: errors?.segment[index]?.title?.prevValue,
          currentValue: errors?.segment[index]?.title?.currentValue,
        };
      }
    }
  }, [errors.segment]);

  const pressEnterToSave = useMemo(() => {
    if ((saved === false && currentValue) || titleErrors?.prevValue?.message) {
      return (
        <span className={styles.pressEnterToSave}>Press enter to save</span>
      );
    } else {
      return null;
    }
  }, [currentValue, saved, titleErrors?.prevValue?.message]);
  return (
    <div className={styles.wrapper}>
      {saved === false && (
        <input
          placeholder="Add segment name..."
          className={styles.segmentTitleInput}
          {...register(`segment.${index}.title.currentValue` as const)}
          type={"text"}
          onChange={(e) => {
            clearErrors(`segment.${index}.title.currentValue`);
            setValue(`segment.${index}.title.currentValue`, e.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setValue(`segment.${index}.title.saved`, true);
              setValue(`segment.${index}.title.prevValue`, currentValue);

              clearErrors(`segment.${index}.title.prevValue`);
            }
          }}
        />
      )}
      {saved && currentValue && (
        <div className={styles.titleWrapper}>
          <span className={styles.title}>{currentValue}</span>{" "}
          <img
            className={styles.editIcon}
            onClick={editTitle}
            src={images.ic_edit}
          />
        </div>
      )}
      {errors.segment && errors.segment[index]?.title?.currentValue ? (
        <span className={styles.pressEnterToSaveError}>
          {errors.segment[index]?.title?.currentValue?.message}
        </span>
      ) : (
        pressEnterToSave
      )}
    </div>
  );
};

export default SegmentTitleInput;
