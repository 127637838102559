import {
  FieldErrors,
  UseFormSetValue,
  UseFormUnregister,
} from "react-hook-form";
import { FormValues } from "../../pages/Promocode/types";

export interface IPromocodeTargetedUsersProps {
  selected?: TargetedUserType;
  watchers: FormValues;
  register: any;
  unregister: UseFormUnregister<FormValues>;
  errors: { [key: string]: any };
  setValue: UseFormSetValue<FormValues>;
}
export type TargetedUserType = "targetted" | "universal" | "compensation";

export type VisibilityType = "visible" | "invisible" | "masked";

export interface PromocodeSettingProps {
  watchers: FormValues;
  control?: any;
  setValue: UseFormSetValue<FormValues>;
  errors: FieldErrors<FormValues>;
}
export enum PromotionType {
  INITIAL_TYPE_VALUE = "Select promocode type...",
  PERCENTAGE = "discount-percentage",
  DISCOUNT_VALUE = "discount-value",
  POINTS_MULTIPLIER = "points-multiplier",
  CASHBACK = "cashback-value",
}

export enum OrderingType {
  DELIVERY_AND_PICKUP = "ordering",
  DELIVERY = "delivery",
  PICKUP = "pickup",
}

export enum PointMultiplier {
  INITIAL = "Select multiplier value...",
  FIRST = "2X",
  SECOND = "3X",
  THIRD = "4X",
  FOURTH = "5X",
}
