import { createSlice } from "@reduxjs/toolkit/";
import { notify } from "../../helpers";
import { loginRequest } from "../thunks";
interface IState {
  token: string;
  isLoggedIn: boolean;
  isloading: boolean;
  status: "idle" | "succeeded" | "failed" | "loading";
}

const initialState: IState = {
  token: "",
  isLoggedIn: true,
  isloading: false,
  status: "idle",
};

export const authSlice = createSlice({
  initialState,
  name: "authSlice",
  reducers: {
    logout: (state) => {
      return {
        ...state,
        isLoggedIn: false,
        token: "",
        status: "idle",
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(loginRequest.pending, (state) => ({
      ...state,
      status: "loading",
    })),
      builder.addCase(loginRequest.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isLoggedIn: true,
          token: payload.token,
          status: "succeeded",
        };
      }),
      builder.addCase(loginRequest.rejected, (state, action) => {
        notify("Please check your credentials", "error");

        return {
          ...state,
          status: "failed",
          isLoggedIn: false,
          error: action.error.message,
        };
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
