import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import styles from "./styles.module.sass";
import moment, { Moment } from "moment";
import { DatePicker } from "antd";
import ErrorMessage from "../../shared/ErrorMessage";
import { dateInputStyle, IPromocodeExpiryDate } from "./types";
import { calendar } from "../../../assets";
import { setInfo } from "../../../store/slices/promoInfo-slice";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
export const StartEndDate: React.FC<IPromocodeExpiryDate> = ({
  start_date,
  end_date,
  control,
  errors,
  setValue,
  header,
}) => {
  const dispatch = useAppDispatch();
  const dimDates = (current: Moment) => {
    return current.isBefore(moment().startOf("day"));
  };

  const setDate = (cb: (value: any) => void, value: any) => {
    cb(value && Number(value.format("x")));
  };
  useEffect(() => {
    const isAfter = moment(new Date(end_date)).isAfter(start_date);
    if (!isAfter) {
      setValue("end_date", start_date + 3600 * 1000);
    }
  }, [start_date, end_date]);
  useEffect(() => {
    dispatch(setInfo({ start_date, end_date }));
  }, [start_date, end_date]);
  return (
    <>
      {header && (
        <div className={styles.formControl}>
          <h1>{header}</h1>
        </div>
      )}
      <div className={styles.divider}>
        <div className={styles.formControl}>
          <label className={styles.label} htmlFor="password">
            Start Date
          </label>
          <Controller
            name="start_date"
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <div className={styles.withIcon}>
                  <div className={styles.iconWrapper}>
                    <div style={{ width: "35px", textAlign: "center" }}>
                      <img src={calendar} alt="calendar" />
                    </div>
                  </div>
                  <DatePicker
                    className="ant-picker-input ant-picker-input-modifier"
                    allowClear={false}
                    value={moment(start_date)}
                    disabledDate={dimDates}
                    onChange={(value) => {
                      setDate(onChange, value);
                    }}
                    format={"YYYY-MM-DD hh:mm A"}
                    placeholder="Select Start Date"
                    style={{
                      ...dateInputStyle,
                      border: `solid 1px ${
                        errors["start_date"] ? "red" : "#e5e5ea"
                      }`,
                    }}
                    showTime={true}
                  />
                </div>
              );
            }}
          />
          {errors?.["start_date"] && (
            <ErrorMessage
              type={""}
              message={errors["start_date"].message as string}
            />
          )}
        </div>
        <div className={styles.formControl}>
          <label className={styles.label} htmlFor="password">
            End Date
          </label>
          <Controller
            name="end_date"
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <div className={styles.withIcon}>
                  <div className={styles.iconWrapper}>
                    <div style={{ width: "35px", textAlign: "center" }}>
                      <img src={calendar} alt="calendar" />
                    </div>
                  </div>
                  <DatePicker
                    className="ant-picker-input ant-picker-input-modifier"
                    allowClear={false}
                    value={moment(end_date)}
                    disabledDate={dimDates}
                    format={"YYYY-MM-DD hh:mm A"}
                    onChange={(value) => {
                      setDate(onChange, value);
                    }}
                    placeholder="Select Start Date"
                    style={{
                      ...dateInputStyle,
                      border: `solid 1px ${
                        errors["end_date"] ? "red" : "#e5e5ea"
                      }`,
                    }}
                    showTime={true}
                  />
                </div>
              );
            }}
          />
          {errors?.["end_date"] && (
            <ErrorMessage
              type={""}
              message={errors["end_date"].message as string}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default StartEndDate;
