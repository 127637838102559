import React from "react";
import { Link, useLocation } from "react-router-dom";
import { TargetIcon } from "../../../assets";
import { SubMenu } from "../../../components";
import styles from "./styles.module.sass";

export const CollapseTargetingTool: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <SubMenu
      active={pathname.includes("targeting-tool")}
      key={"targeting-tool"}
      title={"Targeting tool"}
      clicked={pathname.includes("targeting-tool")}
      icon={<TargetIcon clicked={pathname.includes("targeting-tool")} />}
    >
      <Link
        to={"/targeting-tool/communication_template"}
        className={
          pathname.includes("/targeting-tool/communication_template")
            ? styles.activeCollapseItem
            : styles.collapseItem
        }
      >
        Comms Templates
      </Link>
      <Link
        to={"/targeting-tool/campaigns"}
        className={
          pathname.includes("/targeting-tool/campaigns")
            ? styles.activeCollapseItem
            : styles.collapseItem
        }
      >
        Campaigns
      </Link>
    </SubMenu>
  );
};

export default CollapseTargetingTool;
