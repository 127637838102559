import { Dropdown, Menu, Space } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import React, { useMemo } from "react";
import { ArrowDown } from "../../assets";
import "./styles.less";

interface IDropdownItem {
  label: string | JSX.Element;
  key: any;
}
interface IDropdownProps {
  items: IDropdownItem[];
  dropdownClassName?: string;
  className?: string;
  selectedKey: string;
  onClick: (value: MenuInfo) => void;
  label?: string | JSX.Element;
  suffixIcon?: React.ReactNode;
  iconColor?: string;
}

const GenericMenu = ({
  items,
  onClick,
}: {
  items: IDropdownItem[];
  onClick: (value: MenuInfo) => void;
}) => {
  return <Menu onClick={onClick} items={items} />;
};

const GenericDropdown: React.FC<IDropdownProps> = ({
  items,
  onClick,
  dropdownClassName,
  selectedKey,
  label,
  iconColor,
  suffixIcon,
  className,
}) => {
  const selectedItems: { [x: string]: any } = useMemo(
    () => ({
      ...items.reduce((acc, next) => ({ ...acc, [next.key]: next }), {}),
    }),
    [items]
  );
  return (
    <Dropdown
      overlay={<GenericMenu items={items} onClick={onClick} />}
      trigger={["click"]}
    >
      <div className={`${className ?? ""} dropdown-wrapper`}>
        {typeof label === "string" ? (
          <span className={"generic-dropdown-label"}>{label}</span>
        ) : (
          label
        )}
        <span
          onClick={(e) => e.preventDefault()}
          className={`${dropdownClassName ?? ""} dropdown-button`}
        >
          <Space>
            {selectedItems[selectedKey]?.label ?? "Add Brand"}{" "}
            {suffixIcon ?? <ArrowDown stroke={iconColor ?? "#fff"} />}
          </Space>
        </span>
      </div>
    </Dropdown>
  );
};
export default React.memo(GenericDropdown);
