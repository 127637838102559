import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { GenericInputField, GenericMentionsInput } from "../../../components";
import {
  lifetimeOfflineOptions,
  notTempUserOption,
  offlineAndFloatingUserOptions,
  onlineAppUserOptions,
} from "../../../constant/customer-segmention-options";
import { TemplateFormValues } from "../../../types";
import styles from "./styles.module.sass";

const TitleLabel = (title: string, subtitle: string, optional?: boolean) => {
  return (
    <div className={styles.titleLabelWrapper}>
      <span className={styles.titleLabel}>
        {title}
        <span
          className={/[\u0600-\u06FF]/.test(subtitle) ? styles.arabicLabel : ""}
        >
          {" "}
          {subtitle}
        </span>
      </span>
      {optional && <span className={styles.optionalLabel}>(Optional)</span>}
    </div>
  );
};
export const TemplateMessage: React.FC = () => {
  const {
    register,
    watch,
    control,
    formState: { errors },
  } = useFormContext<TemplateFormValues>();
  const { en_title, ar_message, ar_title, en_message } = watch();
  const options = useMemo(
    () =>
      [
        ...notTempUserOption,
        ...onlineAppUserOptions,
        ...offlineAndFloatingUserOptions,
        ...lifetimeOfflineOptions,
      ].map((option) => ({
        id: option.value,
        display: option.value,
      })),
    [
      notTempUserOption,
      onlineAppUserOptions,
      offlineAndFloatingUserOptions,
      lifetimeOfflineOptions,
    ]
  );
  return (
    <div className={styles.main}>
      <span className={styles.title}>Template Message</span>
      <span className={styles.note}>
        Note: Title only appears in notification channel
      </span>
      <div className={styles.titlesWrapper}>
        <div className={styles.titleInputWrapper}>
          <GenericInputField
            className={styles.inputWrapper}
            register={register("en_title")}
            error={errors["en_title"]}
            placeholder={"ex: Use {Promo name} to get 50% off"}
            type={"text"}
            label={TitleLabel("Title", "(English)")}
          />
          <span className={styles.characterCount}>
            Character count: {en_title.replace(/ /g, "").length}
          </span>
        </div>
        <div className={styles.separator} />
        <div className={styles.titleInputWrapper}>
          <GenericInputField
            className={styles.inputWrapper}
            register={register("ar_title")}
            error={errors["ar_title"]}
            placeholder={"لتحصل علي ٥٠٪ خصم {Promo name} مثال: استخدم"}
            type={"text"}
            label={TitleLabel("Title", "(بالعربية)")}
          />
          <span className={styles.characterCount}>
            Character count: {ar_title.replace(/ /g, "").length}
          </span>
        </div>
      </div>
      <div className={styles.descsWrapper}>
        <div className={styles.titleInputWrapper}>
          <GenericMentionsInput
            control={control}
            name={"en_message"}
            placeholder={"Enter template description"}
            label={TitleLabel("Description", "(English)")}
            data={options}
            trigger={"{"}
          />
          <span className={styles.characterCount}>
            Character count: {en_message.replace(/ /g, "").length}
          </span>
        </div>
        <div className={styles.separator} />
        <div className={styles.titleInputWrapper}>
          <GenericMentionsInput
            control={control}
            name={"ar_message"}
            label={TitleLabel("Description", "(بالعربية)")}
            placeholder={"أدخل وصف قالب الرسالة "}
            data={options}
            trigger={"{"}
          />
          <span className={styles.characterCount}>
            Character count: {ar_message.replace(/ /g, "").length}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TemplateMessage;
