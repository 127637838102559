import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../services";
import { checkPromoCodeValidity } from "../../services/requests";

// Define a service using a base URL and expected endpoints
export const checkPromocodeValidityApi = createApi({
  reducerPath: "promocodeValidaityApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_ORDERING_ENDPOINT}${checkPromoCodeValidity}`,
  }),
  endpoints: (builder) => ({
    checkPromocodeValidity: builder.query<
      any,
      { promo_code: string; stores_ids: string[] }
    >({
      query: ({ promo_code, stores_ids }) => {
        return {
          method: "post",
          url: "",
          data: {
            promo_code,
            stores_ids,
          },
        };
      },
    }),
  }),
});
export const { useCheckPromocodeValidityQuery } = checkPromocodeValidityApi;
