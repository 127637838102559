// sort brans localy
export const sortBrandsAlphapetiacally = (arr: any[]) => {
  let label = "";
  const newArr: any[] = [];
  arr
    .sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    })
    .forEach((item, index) => {
      const initChar = item.name[0].toLowerCase();
      if (index == 0 || label !== initChar) {
        label = initChar;
        newArr.push({
          id: initChar,
          name: initChar.toUpperCase(),
          type: "label",
          selected: item.selected,
        });
        newArr.push({ ...item, type: "name" });
      } else {
        newArr.push({ ...item, type: "name" });
      }
    });
  return newArr;
};
