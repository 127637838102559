import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery, promotionsURL } from "../../services";

// Define a service using a base URL and expected endpoints
export const getpromoByRankApi = createApi({
  reducerPath: "getPromoByRankReducer",
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_PROMOCODE_ENDPOINT}`,
  }),
  endpoints: (builder) => ({
    getPromoByRank: builder.query<any, { country_code: string; rank: string }>({
      query: ({ country_code, rank }) => {
        return {
          method: "get",
          url: promotionsURL,
          params: { country_iso_code: country_code, rank: rank ? rank : 0 },
        };
      },
    }),
  }),
});
export const { useGetPromoByRankQuery } = getpromoByRankApi;
