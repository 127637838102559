import { Select, SelectProps } from "antd";
import React from "react";

const CustomDropDown: React.FC<SelectProps> = (props) => {
  const { children } = props;
  return (
    <Select data-testid="test-custom-drop-down" {...props}>
      {children}
    </Select>
  );
};

export default CustomDropDown;
