import { createSlice } from "@reduxjs/toolkit";
import { IRoute } from "../../routes/utils";
interface IRoutingState {
  from: IRoute;
  to: IRoute;
}

const initialRoutingState: IRoutingState = {
  from: {
    label: "",
    path: "",
  },
  to: {
    label: "",
    path: "",
  },
};
export const routingSlice = createSlice({
  name: "routing",
  initialState: initialRoutingState,
  reducers: {
    setRoutes: (state, { payload: { from, to } }) => {
      state.from = { ...from };
      state.to = { ...to };
    },
    resetRoutes: () => {
      return { ...initialRoutingState };
    },
  },
});

export const { setRoutes, resetRoutes } = routingSlice.actions;
export default routingSlice.reducer;
