import React, { useEffect, useMemo, useState } from "react";
import { useSelect } from "../../hooks";
import styles from "./styles.module.sass";
import { Modal } from "antd";
import { ClipLoader } from "react-spinners";
import { getCountrybrands } from "../../store/thunks";
import { setInfo } from "../../store/slices/promoInfo-slice";
import {
  selectAll,
  getSelectedBrands,
  selectBrand,
  clearSelectedBrands,
} from "../../store/slices/country-brands-slice";
import { check, cross } from "../../assets";
import NotFoundSearchTerm from "../NotFoundSearchTerm";
import BrandOptionItem from "../BrandOptionItem";
import { sortBrandsAlphapetiacally } from "./utils";
import { ADD_BRAND, COUNTRIES } from "../../constant/DropdownOptions";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { FormValues } from "../../pages/Promocode/types";
import { UseFormSetValue } from "react-hook-form";
import GenericConfimationModal from "../genericConfimationModal";

interface IBrandSelectionModal {
  isOpen: boolean;
  onClose: () => void;
  country_code: string;
  watchers: FormValues;
  setValue: UseFormSetValue<FormValues>;
  prevOption: string;
}
const BrandSelectionModal: React.FC<IBrandSelectionModal> = ({
  isOpen,
  onClose,
  country_code,
  prevOption,
  // setValue,
  // watchers
}) => {
  const dispatch = useAppDispatch();
  const {
    brandsReducer: {
      brands,
      isloading,
      allSelected,
      country_code: countryCode,
      hasChange,
      selectedBrands,
    },
  } = useSelect((state) => {
    return state;
  });
  const [search, setSearch] = useState<string>("");
  const [allBrandsSelected] = useState<boolean>(false);
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const selectedBrandsCount = useMemo(() => {
    return brands.filter((item) => item.selected).length;
  }, [brands]);

  const isAllSelected = useMemo(() => {
    return allBrandsSelected || brands.every((item) => item.selected);
  }, [allBrandsSelected, brands]);
  // selected brands
  const brandCountMessage = useMemo(() => {
    return selectedBrandsCount ? (
      <p className={styles.content}>{selectedBrandsCount} Brands selected</p>
    ) : (
      <p className={styles.content} style={{ color: "#8E8E93" }}>
        No brand is selected yet
      </p>
    );
  }, [selectedBrandsCount]);
  const toggleLocalBrand = (id: string) => {
    dispatch(selectBrand({ value: id }));
  };
  // render bransd
  const listBrands = useMemo(() => {
    const list = [
      ...sortBrandsAlphapetiacally([...brands]).filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      ),
    ];
    return list.length ? (
      <div className={styles.listWrapper}>
        {list.map(({ name, type, id, selected }) => {
          return (
            <BrandOptionItem
              key={id}
              name={name}
              type={type}
              id={id}
              selected={selected}
              action={toggleLocalBrand}
            />
          );
        })}
      </div>
    ) : (
      <NotFoundSearchTerm search={search} />
    );
  }, [brands, search]);
  const countryImage = useMemo(() => {
    return (
      <img
        src={
          country_code === COUNTRIES[0].countryCode
            ? COUNTRIES[0].image
            : COUNTRIES[1].image
        }
      />
    );
  }, [country_code]);
  // assigin loaded  brands
  const enableAddBrands = useMemo(() => {
    return !hasChange;
  }, [hasChange, selectedBrandsCount]);
  useEffect(() => {
    if (countryCode !== country_code || prevOption === ADD_BRAND[1].id) {
      dispatch(getCountrybrands({ country_code }));
    }
  }, [country_code, prevOption]);

  return (
    <Modal
      open={isOpen}
      destroyOnClose={true}
      closable={false}
      onCancel={() => {
        enableAddBrands ? onClose() : setOpenCancelModal(true);
      }}
      title={""}
      footer={""}
      width={1100}
      centered={true}
    >
      <div className={styles.container}>
        {isloading ? (
          <div className={styles.loader}>
            <ClipLoader color="#feb114" />
          </div>
        ) : (
          <>
            <div className={styles.header}>
              <h3 className={styles.headerContent}>
                {countryImage}
                <p>Select targeted brand</p>
              </h3>
              <div>
                <button
                  className={`${styles.close_cta} ${styles.round}`}
                  onClick={() => {
                    enableAddBrands ? onClose() : setOpenCancelModal(true);
                  }}
                >
                  <img src={cross} alt="close" />
                </button>
              </div>
            </div>
            <div className={styles.filterSection}>
              <div className={styles.search_input}>
                <input
                  type="text"
                  placeholder="Search by brand name ..."
                  className={styles.input}
                  onChange={(e) => {
                    e.preventDefault();
                    setSearch(e.target.value);
                  }}
                />
              </div>

              <button
                className={styles.select_cta}
                onClick={() => {
                  dispatch(selectAll({ isAllSelected: !allSelected }));
                }}
              >
                <div
                  className={`${styles.checkbox} ${
                    isAllSelected ? styles.checkbox_active : ""
                  } `}
                >
                  {isAllSelected && <img src={check} alt="checked" />}
                </div>
                <p className={styles.content}>
                  {allSelected ? "Deselect all" : "Select all"}
                </p>
              </button>
            </div>

            {listBrands}

            <div className={styles.footer}>
              {brandCountMessage}
              <div>
                <button
                  onClick={() => {
                    dispatch(getSelectedBrands());
                    onClose();
                  }}
                  className={styles.cancel_cta}
                  disabled={enableAddBrands}
                >
                  Add Brands
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      {openCancelModal && (
        <GenericConfimationModal
          isOpen={openCancelModal}
          onClose={() => {
            setOpenCancelModal(false);
          }}
          applyAction={() => {
            dispatch(selectAll({ isAllSelected: false }));
            dispatch(clearSelectedBrands());
            dispatch(setInfo({ brands: [] }));
            onClose();
          }}
          alertType="warn"
          buttonContent={"Discard"}
          description={`Are you sure you want to discard adding ${
            selectedBrandsCount || selectedBrands.length
          } brands to this promocode?`}
          title={`Discard adding ${
            selectedBrandsCount || selectedBrands.length
          } brands`}
        />
      )}
    </Modal>
  );
};

export default React.memo(BrandSelectionModal);
