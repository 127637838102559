import React from "react";
import { dateInputStyle } from "../StartEndDate/types";
import { ErrorMessage } from "../../shared";
import styles from "./styles.module.sass";
import { Controller } from "react-hook-form";
import { calendar } from "../../../assets";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";

export interface ICapmaignEndDate {
  placeholder: string;
  name: string;
  errors?: { message?: string; type?: string };
  value: number;
  control: any;
  label: string;
  format?: string;
}
const GenericDateField: React.FC<ICapmaignEndDate> = ({
  control,
  errors,
  name,
  placeholder,
  label,
  format,
  value,
}) => {
  const dimDates = (current: Moment) => {
    return current.isBefore(moment().startOf("day"));
  };

  const setDate = (cb: (value: any) => void, value: any) => {
    cb(value && Number(value.format("x")));
  };
  return (
    <div className={styles.formControl}>
      <label className={styles.label} htmlFor="password">
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <div className={styles.withIcon}>
              <div className={styles.iconWrapper}>
                <div style={{ width: "35px", textAlign: "center" }}>
                  <img src={calendar} alt="calendar" />
                </div>
              </div>
              <DatePicker
                defaultValue={moment(value ? value : new Date())}
                className="ant-picker-input ant-picker-input-modifier"
                allowClear={false}
                disabledDate={dimDates}
                onChange={(value) => {
                  setDate(onChange, value);
                }}
                format={format ?? "D MMMM yyyy"}
                placeholder={placeholder}
                style={{
                  ...dateInputStyle,
                  border: `solid 1px ${errors ? "red" : "#e5e5ea"}`,
                }}
                showTime={true}
                suffixIcon={null}
              />
            </div>
          );
        }}
      />
      {errors && <ErrorMessage type={""} message={errors?.message as string} />}
    </div>
  );
};

export default GenericDateField;
