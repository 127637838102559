import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  authSlice,
  promoInfoSlice,
  highestRankSlice,
  customerFileVerificationSlice,
  campaignSlice,
} from "./slices";
import { countryBrandsSlice } from "./slices/country-brands-slice";
import { routingSlice } from "./slices/routing-slice";
import { Reducer } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  getpromoByRankApi,
  checkPromocodeValidityApi,
  promocodeCreationApi,
  TargetedCustomersFileUploadApi,
  getPromocodesApi,
} from "./apis";
import { getTemplatesApi } from "./apis/templateSelection";
import { getCampaignsApi } from "./apis/campaignSelection";
import { CampaignListSlice } from "./slices/campaign-list-slice";

const reducers = {
  authReducer: persistReducer(
    {
      storage,
      key: "auth",
    },
    authSlice.reducer
  ),
  routingReducer: routingSlice.reducer,
  campaignsListReducer: CampaignListSlice.reducer,
  brandsReducer: persistReducer(
    { storage, key: "selectBrands" },
    countryBrandsSlice.reducer
  ),
  promoInfoReducer: persistReducer(
    { storage, key: "promoInfo" },
    promoInfoSlice.reducer
  ),
  ranksReducer: persistReducer(
    { storage, key: "ranks" },
    highestRankSlice.reducer
  ),
  campaignReducer: persistReducer(
    { storage, key: "campaign" },
    campaignSlice.reducer
  ),
  customerFileVerificationReducer: customerFileVerificationSlice.reducer,
  [getpromoByRankApi.reducerPath]: getpromoByRankApi.reducer,
  [checkPromocodeValidityApi.reducerPath]: checkPromocodeValidityApi.reducer,
  [promocodeCreationApi.reducerPath]: promocodeCreationApi.reducer,
  [TargetedCustomersFileUploadApi.reducerPath]:
    TargetedCustomersFileUploadApi.reducer,
  [getPromocodesApi.reducerPath]: getPromocodesApi.reducer,
  [getTemplatesApi.reducerPath]: getTemplatesApi.reducer,
  [getCampaignsApi.reducerPath]: getCampaignsApi.reducer,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
  return combinedReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["appReducer", "promoInfo", "selectBrands", "ranks"],
};
const persistReducers = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      getpromoByRankApi.middleware,
      checkPromocodeValidityApi.middleware,
      promocodeCreationApi.middleware,
      TargetedCustomersFileUploadApi.middleware,
      getPromocodesApi.middleware,
      getTemplatesApi.middleware,
      getCampaignsApi.middleware,
    ]),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof combinedReducer>;
