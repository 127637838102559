import React, { useEffect, useMemo, useRef, useState } from "react";
import PromocodeInfoCard from "./PromocodeInfoCard";
import styles from "./styles.module.sass";
import { Overlay } from "../../../components/shared/CustomSelect";
import { Controller, useFormContext } from "react-hook-form";
import { ISelect } from "../../../components/shared/types";
import { Dropdown } from "../../../components";
import images from "../../../assets/images/images";
import { CampaignFormValues } from "../../../types";
export interface IPromocodeSelectionProps {
  promocodes: any[];
  index: number;
}
const PromocodeSelection: React.FC<IPromocodeSelectionProps> = ({
  promocodes,
  index,
}) => {
  const {
    clearErrors,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CampaignFormValues>();
  const [options] = useState(
    promocodes.map((promo: any) => {
      const option = {
        label: promo.promo_code,
        value: promo.id,
        description: promo.desc ? promo.desc : promo.ar_desc,
      };
      return option;
    })
  );
  const watchers = watch();
  const selectedPromocodeInfo = useMemo(() => {
    return promocodes.find((promoOption) => {
      return promoOption.id == watchers.segment?.[index]?.promotion_id;
    });
  }, [watchers.segment?.[index]?.promotion_id, promocodes]);
  return (
    <>
      {watchers.segment[index].has_promotion_id && (
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <span>PROMOCODE</span>
            <button
              type="button"
              onClick={() => {
                setValue(`segment.${index}.has_promotion_id`, false);
                setValue(`segment.${index}.promotion_id`, undefined);
              }}
            >
              &times;
            </button>
          </div>

          <div className={styles.filterWrapper}>
            <div className={styles.divider}>
              <div className={styles.wrapper}>
                <CustomPromSelectionSelect
                  onSelect={(option) => {
                    setValue(
                      `segment.${index}.promotion_id`,
                      option.value?.toString()
                    );
                    clearErrors(`segment.${index}.promotion_id`);
                  }}
                  overlayClassName={"overlayWrapper"}
                  dropdownClassName={styles.dropdownWrapper}
                  dropdownListClassName={styles.dropdown}
                  label={"Promocode"}
                  control={control as any}
                  placeholder={"Select Promocode..."}
                  options={options}
                  name={`segment.${index}.promotion_id`}
                  defaultValue={watchers.segment[index].promotion_id}
                />
                {watchers.segment?.[index]?.promotion_id ? (
                  <p style={{ color: "#65656C", marginTop: "10px" }}>
                    ID: {watchers.segment[index].promotion_id}
                  </p>
                ) : (
                  <></>
                )}
                {errors?.segment?.[index] &&
                  errors?.segment?.[index]?.promotion_id && (
                    <span className={styles.userTypeError}>
                      {errors?.segment?.[index]?.promotion_id?.message}
                    </span>
                  )}
              </div>

              {selectedPromocodeInfo && (
                <PromocodeInfoCard
                  selectedPromoCodeInfo={{
                    label: selectedPromocodeInfo.title,
                    value: selectedPromocodeInfo.id,
                    description: selectedPromocodeInfo.desc
                      ? selectedPromocodeInfo.desc
                      : selectedPromocodeInfo.ar_desc,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PromocodeSelection;

export const CustomPromSelectionSelect = <T extends string | number>({
  options,
  label,
  placeholder,
  dropdownClassName,
  dropdownListClassName,
  control,
  name,
  defaultValue,
  onSelect,
}: ISelect<T>) => {
  const [selectedItem, setSelectItem] = useState<T | undefined>(defaultValue);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (defaultValue) {
      setSelectItem(defaultValue);
    }
  }, [defaultValue]);
  const selectedOptionLabel = options.find((option) => {
    if (selectedItem) {
      return selectedItem == option.value;
    }
  })?.label;
  return (
    <Controller
      control={control}
      name={name}
      render={() => (
        <div>
          <Dropdown
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
            target={
              <div
                className={`${styles.wrapper} ${dropdownClassName}`}
                onClick={() => setIsOpen((prev) => !prev)}
              >
                {label && (
                  <React.Fragment>
                    {React.isValidElement(label) ? (
                      label
                    ) : (
                      <span className={styles.label}>{label}</span>
                    )}
                  </React.Fragment>
                )}
                <div ref={dropdownRef} className={`${dropdownListClassName}`}>
                  <span>
                    {selectedOptionLabel ? selectedOptionLabel : placeholder}
                  </span>

                  <img
                    className={
                      isOpen ? styles.dropdownOpening : styles.dropdownClosed
                    }
                    src={images.ic_chevron_down}
                  />
                </div>
              </div>
            }
          >
            <Overlay
              selectedItem={selectedItem}
              options={options}
              placeholder={placeholder}
              onSelect={(item) => {
                setSelectItem((prev) => {
                  if (!prev || item.value !== prev) {
                    return item.value;
                  }
                  return prev;
                });
                if (onSelect) {
                  onSelect(item);
                }
                setIsOpen(false);
              }}
            />
          </Dropdown>
        </div>
      )}
    />
  );
};
