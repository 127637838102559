import { createApi } from "@reduxjs/toolkit/query/react";
import { notify } from "../../helpers";
import { axiosBaseQuery, getTemplates } from "../../services";
import { TemplateFormValues } from "../../types";

// Define a service using a base URL and expected endpoints
export const getTemplatesApi = createApi({
  reducerPath: "getTemplatesReducer",
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_GROWTH_TOOL_ENDPOINT}`,
  }),
  endpoints: (builder) => ({
    getTemplates: builder.query<any, void>({
      query: () => {
        return {
          method: "GET",
          url: getTemplates,
          headers: {
            "X-Api-Key": "staging",
          },
        };
      },
    }),
    createTemplate: builder.mutation<any, TemplateFormValues>({
      query(values) {
        return {
          method: "post",
          url: getTemplates,
          data: values,
          headers: {
            "X-Api-Key": "staging",
          },
        };
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            getTemplatesApi.endpoints.getTemplates.initiate(undefined, {
              forceRefetch: true,
            })
          );
          notify("Template created successfully", "success");
        } catch (error) {
          notify("Something went wrong please try again", "error");
        }
      },
    }),
  }),
});
export const { useGetTemplatesQuery, useCreateTemplateMutation } =
  getTemplatesApi;
