import moment from "moment";
import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { CrossIcon } from "../../../../assets/images/images";
import { GenericSwitch } from "../../../../components";
import { CampaignFormValues } from "../../../../types";
import StartingDate from "../StartingDate";
import TriggerSettings from "../TriggerSettings";
import styles from "./styles.module.sass";

const TimeTriggerCard: React.FC<{
  index: number;
  removeTimeTrigger: () => void;
}> = ({ index, removeTimeTrigger }) => {
  const {
    control,
    setValue,
    formState: { errors },
    watch,
    clearErrors,
  } = useFormContext<CampaignFormValues>();
  const { time_triggers } = watch();
  const handelSwitchOnChange = (value: boolean) => {
    setValue(`time_triggers.${index}.repeat`, value);
  };
  const error = useMemo(() => {
    if (errors.time_triggers) {
      return errors.time_triggers[index]?.repeat_start?.message;
    } else {
      return undefined;
    }
  }, [errors, index]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.headWrapper}>
        <div className={styles.headerContent}>
          <span className={styles.headTitle}>Trigger #{index + 1}</span>
          <div className={styles.startingDateWrapper}>
            <StartingDate
              handleChange={(v) => {
                const isAfter = moment(v).isAfter(moment.now());
                if (isAfter) {
                  clearErrors(`time_triggers.${index}.repeat_start`);
                }
              }}
              index={index}
            />
            <GenericSwitch
              label={"Repeat"}
              className={`${
                time_triggers[index].repeat
                  ? styles.activeRepeatSwitch
                  : styles.repeatSwitch
              }`}
              labelStyle={styles.repeatSwitchLabel}
              control={control}
              name={`time_triggers.${index}.repeat`}
              checked={time_triggers[index].repeat}
              handelSwitchOnChange={handelSwitchOnChange}
            />
            <div
              onClick={removeTimeTrigger}
              className={styles.removeIconWrapper}
            >
              <CrossIcon />
            </div>
          </div>
        </div>
        {error && <span className={styles.error}>{error}</span>}
      </div>
      <span className={styles.triggerSettingsTitle}> Trigger settings</span>
      <div className={styles.triggerSettingsContainer}>
        <TriggerSettings index={index} />
      </div>
    </div>
  );
};

export default React.memo(TimeTriggerCard);
