import { createApi } from "@reduxjs/toolkit/query/react";
import { notify } from "../../helpers";
import { PromotionType } from "../../layouts/Promocode/types";
import { FormValues } from "../../pages/Promocode/types";
import { axiosBaseQuery } from "../../services";
import { promoCodeCreation } from "../../services/requests";
import { resetCountryBrandsState } from "../slices/country-brands-slice";

export const VisibilityType = {
  normal: "visible",
  hidden: "invisible",
  masked: "masked",
};
export const UniversalPromoType = {
  all_customers: "all_customers",
  new_customers: "new_customers",
  ordering_customers: "ordering_customers",
};
export const mapPayloadToFormData = (data: FormValues) => {
  const {
    brands,
    promotion_type,
    ar_desc,
    ar_title,
    desc,
    title,
    end_date,
    start_date,
    max_discount,
    max_usage_times,
    ordering_type,
    rank,
    promo_code,
    targeted_customers_file_path,
    targeting_method,
    targeted_customers_type,
    country_code,
    min_eligible,
    masked,
    point_multiplier_value,
    preselectPromo,
    visibility_type,
    expiry_days,
  } = data;

  const mappedFormValues = {
    //info
    ar_desc,
    ar_title,
    rank: Number(rank),
    desc,
    title,
    promo_code,
    country_iso_code: country_code === "+2" ? "eg" : "sa",
    // discount-value (promo code type)
    min_threshold:
      promotion_type === PromotionType.DISCOUNT_VALUE
        ? `${min_eligible}`
        : undefined, // with promotion discount-value
    max_discount_value:
      promotion_type === PromotionType.DISCOUNT_VALUE
        ? `${max_discount}`
        : undefined, // with promotion discount-value
    // data
    promotion_type,
    end_time: `${end_date}`,
    start_time: `${start_date}`,
    max_usage_times: Number(max_usage_times),
    ordering_type, // ordering | delivery | pickup
    suggested:
      promotion_type === PromotionType.POINTS_MULTIPLIER
        ? preselectPromo
          ? 1
          : 0
        : undefined,
    allowed_payment_method: visibility_type === "visible" ? "all" : "online",
    reference_value:
      promotion_type === PromotionType.POINTS_MULTIPLIER
        ? point_multiplier_value
        : `${max_discount}`, // max dicount (cashback | percentage | discount)
    // targetted-customers type
    targeting_method:
      targeted_customers_type === "universal" ? targeting_method : undefined, // with promotion universal [all_customers || new_customers || ordering_customers]
    customers_file_path:
      targeted_customers_type === "targetted"
        ? targeted_customers_file_path
        : undefined,
    is_compensational: targeted_customers_type === "compensation" ? 1 : 0, // in case of [compensation]
    expiry_days:
      targeted_customers_type === "compensation" ? expiry_days : undefined,
    stores: brands.map((brand) => ({
      id: brand.id,
      store_funds: Number(brand.brand_percentage),
      // promo code type
      min_threshold: [
        PromotionType.PERCENTAGE,
        PromotionType.CASHBACK,
      ].includes(promotion_type)
        ? Number(brand.min_eligible_invoice)
        : undefined, //in case [cashback || discount-percentage]
      max_cashback_value: [PromotionType.CASHBACK].includes(promotion_type)
        ? Number(brand.max_discount_value)
        : undefined, // in case [cashback]
      max_discount_value: [PromotionType.PERCENTAGE].includes(promotion_type)
        ? Number(brand.max_discount_value)
        : undefined, // in case [percentage] ,
      // visibilty [masked]
      masked_value:
        masked && visibility_type === "invisible"
          ? Number(brand.masked_value)
          : undefined, // in case [masked]
      masked_promo_code:
        masked && visibility_type === "invisible"
          ? brand.masked_promo_code
          : undefined, // in case [masked]
      expires_after: [PromotionType.CASHBACK].includes(promotion_type)
        ? Number(brand.expires_after_value)
        : undefined,
    })),
  };
  return mappedFormValues;
};
export const promocodeCreationApi = createApi({
  reducerPath: "promocodeCreation",
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_PROMOCODE_ENDPOINT}`,
  }),
  tagTypes: ["promocode_operation"],
  endpoints: (builder) => ({
    promocodeCreation: builder.mutation<any, { data: FormValues }>({
      query: ({ data }) => {
        const {
          visibility_type,
          promotion_type,
          targeted_customers_type,
          masked,
        } = data;
        return {
          method: "POST",
          url: promoCodeCreation(
            promotion_type,
            targeted_customers_type as string,
            masked ? "masked" : visibility_type
          ),
          data: mapPayloadToFormData(data),
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          setTimeout(() => {
            notify("Promo code created successfully", "success");
            localStorage.removeItem("persist:selectBrands");
            localStorage.removeItem("persist:promoInfo");
            dispatch(resetCountryBrandsState());
          }, 1000);
        } catch (error) {
          if ((error as any)?.error?.data.code.includes("SERVER-FAILURE")) {
            notify("Something went wrong please try again", "error");
          }
          const promocodeError = (error as any)?.error.data?.message
            ?.promo_code[0];
          if (promocodeError) {
            notify(`${promocodeError}`, "error");
          }
        }
      },
    }),
  }),
});

export const { usePromocodeCreationMutation } = promocodeCreationApi;
