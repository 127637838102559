import * as React from "react";
import images from "../../assets/images/images";
import styles from "./styles.module.sass";

export interface IPanelProps {
  title: string | JSX.Element;
  children: React.ReactElement<any>;
}

export const Panel: React.FC<IPanelProps> = ({ children }) => children;
export type Panel = typeof Panel;

interface ICollapseProps {
  icon?: JSX.Element | string;
  showIcon?: boolean;
  headerClassName?: string;
  bodyClassName?: string;
  state?: string | string[];
  children:
    | Array<React.ReactElement<IPanelProps> | null>
    | React.ReactElement<IPanelProps>;
  disabled?: boolean;
  toggle?: (index?: number) => void;
  arrowIconClassName?: string;
  collapsible?: "icon" | "header";
  accordion?: boolean;
  wrapperClassName?: string;
}

const Collapse: React.FC<ICollapseProps> = ({ showIcon = true, ...props }) => {
  const [key, setKey] = React.useState<string | null>();
  const [keys, setKeys] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (typeof props.state === "string") {
      setKey(props.state);
    }
    if (Array.isArray(props.state)) {
      setKeys(props.state);
    }
  }, [props.state]);

  const toggle = (index: number) => {
    if (props.toggle) {
      props.toggle(index);
    }
    if (key?.includes(index.toString())) {
      return setKey(undefined);
    }
    if (props.accordion) {
      if (keys.includes(index.toString())) {
        setKeys(keys.filter((element) => element !== index.toString()));
      } else {
        setKeys([...keys, index.toString()]);
      }
    } else {
      setKey(index.toString());
    }
  };
  return (
    <React.Fragment>
      {React.Children.map(
        props.children as any,
        (child: React.ReactElement<IPanelProps>, index) => {
          return (
            <div
              style={
                index === 0
                  ? { borderTopRightRadius: "8px", borderTopLeftRadius: "8px" }
                  : { borderTopRightRadius: 0, borderTopLeftRadius: 0 }
              }
              key={index}
              className={`${styles.wrapper} ${props.wrapperClassName}`}
            >
              <div
                style={
                  props.collapsible === "icon"
                    ? { cursor: "default" }
                    : { cursor: "pointer" }
                }
                className={`${styles.headerWrapper} ${props.headerClassName}`}
                onClick={() => {
                  if (props.collapsible === "header") {
                    toggle(index);
                  }
                }}
              >
                <div
                  onClick={() => {
                    if (props.collapsible === "icon") {
                      toggle(index);
                    }
                  }}
                >
                  {showIcon ? (
                    props.icon ? (
                      React.isValidElement(props.icon) ? (
                        props.icon
                      ) : (
                        typeof props.icon === "string" && (
                          <img
                            className={props.arrowIconClassName}
                            src={props.icon}
                          />
                        )
                      )
                    ) : (
                      <div className={styles.iconWrapper}>
                        <img
                          className={
                            [key, ...keys].includes(index.toString())
                              ? styles.activeIcon
                              : styles.inactiveIcon
                          }
                          src={images.ic_chevron_down}
                        />
                      </div>
                    )
                  ) : null}
                </div>

                {React.isValidElement(child.props.title) ? (
                  child.props.title
                ) : (
                  <span className={styles.title}>{child.props.title}</span>
                )}
              </div>
              {[key, ...keys].includes(index.toString()) && (
                <div
                  className={`${
                    [key, ...keys].includes(index.toString())
                      ? styles.activeCard
                      : styles.inactiveCard
                  } ${props.bodyClassName}`}
                >
                  {child.props.children}
                </div>
              )}
            </div>
          );
        }
      )}
    </React.Fragment>
  );
};

export default React.memo(Collapse);
