import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "../../helpers/validator/loginValidator";
import styles from "./styles.module.sass";
import ErrorMessage from "../../components/shared/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "../../store/thunks";
import { useSelect } from "../../hooks";
import { ClipLoader } from "react-spinners";
import { logo } from "../../assets";
import { ILoginFormValus } from "../../store/types";
import { useAppDispatch } from "../../hooks/useAppDispatch";

export const Login: React.FC = () => {
  const disaptch = useAppDispatch();
  const navigate = useNavigate();
  const {
    authReducer,
    routingReducer: { from, to },
  } = useSelect((state) => state);
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = ({ email, password }: ILoginFormValus) => {
    disaptch(loginRequest({ password, email: email.toLowerCase() }));
  };
  useEffect(() => {
    if (authReducer.isLoggedIn)
      navigate(
        to.path === "/login" || to.path === ""
          ? from.path
            ? from.path
            : "/promocodes"
          : "/promocodes"
      );
  }, [authReducer.isLoggedIn]);
  return (
    <>
      <div className={styles.container}>
        <form
          data-testid="login-form"
          className={styles.formWrapper}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div data-testid="header" className={styles.header}>
            <p>Growth Tool</p>
          </div>
          <div className={styles.logoWrapper}>
            <img data-testid="logo" className={styles.logo} src={logo} />
          </div>
          <div className={styles.formControl}>
            <label className={styles.label} htmlFor="email">
              Email
            </label>
            <input
              data-testid="email-field"
              {...register("email")}
              type={"text"}
              placeholder="Enter your email..."
              className={styles.input}
            />
            {errors?.email && (
              <ErrorMessage
                message={errors?.email?.message as string}
                type={errors?.email?.type as string}
              />
            )}
          </div>
          <div className={styles.formControl}>
            <label className={styles.label} htmlFor="password">
              Password
            </label>

            <input
              data-testid="password-field"
              className={styles.input}
              {...register("password")}
              type={"password"}
              placeholder="Enter your password..."
            />
            {errors?.password && (
              <ErrorMessage
                message={errors?.password?.message as string}
                type={errors?.password?.type as string}
              />
            )}
          </div>
          <div className={`${styles.formControl} ${styles.center}`}>
            <button
              data-testid="login-btn"
              className={`${styles.btn} ${
                isSubmitting ? styles["btn-disapbed"] : styles["btn-primary"]
              }`}
              type="submit"
              disabled={isSubmitting}
            >
              {authReducer.status === "loading" ? (
                <ClipLoader color="#FFF" />
              ) : (
                "Log in"
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
