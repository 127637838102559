import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery, getCampaigns } from "../../services";

// Define a service using a base URL and expected endpoints
export const getCampaignsApi = createApi({
  reducerPath: "getCampaignsReducer",
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_GROWTH_TOOL_ENDPOINT}`,
  }),
  endpoints: (builder) => ({
    getCampaigns: builder.mutation<any, void>({
      query: () => {
        return {
          method: "GET",
          url: getCampaigns,
          headers: {
            "X-Api-Key": "staging",
          },
        };
      },
    }),
  }),
});
export const { useGetCampaignsMutation } = getCampaignsApi;
