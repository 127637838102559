import moment from "moment";

export const getWeeksInAMonth = ({
  months,
  year,
}: {
  year?: number;
  months: number[];
}) => {
  const weeks = new Set();
  let firstDayOfMonth: moment.Moment;
  let numOfDays: number;

  for (const m of months) {
    const currentYear = moment(moment.now()).year();
    firstDayOfMonth = moment(`${year ?? currentYear}-${m}`);
    numOfDays = firstDayOfMonth.daysInMonth();
    for (let i = 0; i < numOfDays; i++) {
      const currentDay = moment(firstDayOfMonth).add(i, "days");
      weeks.add(currentDay.isoWeek());
    }
  }

  return Array.from(weeks);
};
