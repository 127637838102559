import React, { ReactNode } from "react";
import {
  Control,
  Controller,
  FieldErrorsImpl,
  FieldPath,
  FieldValues,
} from "react-hook-form";
import { FormValues } from "../../pages/Promocode/types";
import ErrorMessage from "../shared/ErrorMessage";
import styles from "./styles.module.sass";

export const CloneGenericInputField = <
  T extends FieldValues,
  TName extends FieldPath<T> = FieldPath<T>
>({
  name,
  label,
  control,
  labelStyle,
  style,
  type,
  disabled,
  placeholder,
  badge,
  content,
  errors,
  handleOnChange,
  value,
  className,
  badgeContainer,
}: {
  name: TName;
  label?: string;
  control: Control<T, any> | undefined;
  style?: React.CSSProperties;
  labelStyle?: string;
  type: string;
  placeholder?: string;
  disabled?: boolean;
  badge?: string;
  content?: ReactNode;
  value?: string | number | undefined;
  errors: FieldErrorsImpl<FormValues>;
  handleOnChange?: (e: any) => void;
  className?: string;
  badgeContainer?: string;
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <div style={style} className={`${styles.formControl}  ${className}`}>
            {label && (
              <label
                className={`${styles.label} ${labelStyle}`}
                htmlFor="password"
              >
                {label}
              </label>
            )}
            <div className={`${styles.withBadge} ${badgeContainer}`}>
              <input
                className={`${styles.input} ${
                  errors[`${name}`]?.message ? styles.invalid : ""
                }`}
                type={type}
                disabled={disabled}
                placeholder={placeholder}
                value={field.value ?? value}
                onChange={(value) => {
                  field.onChange(value);
                  handleOnChange ? handleOnChange(value) : null;
                }}
              />
              {badge && (
                <div
                  className={`${styles.badge} ${
                    errors[`${name}`]?.message && styles.invalid
                  }`}
                >
                  {badge}
                </div>
              )}
            </div>
            {content ?? content}
            <ErrorMessage
              message={(errors[`${name}`]?.message as string) || ""}
              type={(errors[`${name}`]?.type as string) || ""}
            />
          </div>
        );
      }}
    />
  );
};

export default CloneGenericInputField;
