import { Switch } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import GenericInputField from "../../../components/Forms/GenericInputField";
import BrandItemFields from "../../../components/BrandItemFields";
import "./styles.less";
import styles from "./styles.module.sass";
import { IPromocodeSplitProps } from "./types";
import { useCurreny, usePromocodeSplit } from "../../../hooks";
import { PromotionType } from "../types";
const PromocodeSplit: React.FC<IPromocodeSplitProps> = ({
  errors,
  register,
  control,
  watchers,
  setValue,
  setFocus,
  trigger,
}: IPromocodeSplitProps) => {
  const {
    country_code,
    max_discount_values_flag,
    min_eligible_invoice_flag,
    split_flag,
    expires_after_values_flag,
    brands,
  } = watchers;

  //  min invoice checker action
  const {
    applyMaxDiscountAction,
    applyMinValueAction,
    applySplitAction,
    applyExpiresAfterAction,
    memoizedSplittedValues,
  } = usePromocodeSplit({ setValue, watchers });
  const currency = useCurreny(country_code);

  return (
    <>
      {brands.length > 1 && (
        <>
          <div className={styles.split_wrapper}>
            <div
              className={`${styles.split_division_switch} ${
                split_flag ? styles.active : ""
              }`}
            >
              <div className={styles.divider}>
                <div className={styles.formControl}>
                  <div
                    className={styles.toggle_header}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>Apply split for All</span>
                    <Controller
                      name="split_flag"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Switch
                            {...field}
                            checked={field.value}
                            onChange={(checked) => {
                              applySplitAction(checked, field);
                            }}
                            size="default"
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              {split_flag && (
                <div className={styles.divider}>
                  <GenericInputField
                    register={register("koinz_percentages")}
                    type={"number"}
                    placeholder={"e.g.  15%"}
                    label={"Koinz Percentage"}
                    error={errors?.koinz_percentages}
                    badge={"%"}
                    onChange={(e) => {
                      const actualValue =
                        Number(e.target.value ?? 0) >= 0 &&
                        Number(e.target.value ?? 0) <= 100
                          ? Number(e.target.value ?? 0)
                          : Number(e.target.value ?? 0) < 0
                          ? 0
                          : 100;
                      const brand_percentage_value = 100 - actualValue;

                      setValue("brand_percentages", brand_percentage_value);
                      setValue("koinz_percentages", actualValue);
                    }}
                  />
                  <GenericInputField
                    register={register("brand_percentages")}
                    type={"number"}
                    placeholder={"Ex.2"}
                    label={"Brand Percentage"}
                    error={errors?.brand_percentages}
                    badge={"%"}
                    onChange={(e) => {
                      const actualValue =
                        Number(e.target.value ?? 0) >= 0 &&
                        Number(e.target.value ?? 0) <= 100
                          ? Number(e.target.value ?? 0)
                          : Number(e.target.value ?? 0) < 0
                          ? 0
                          : 100;
                      const koinz_percentage_value = 100 - actualValue;
                      setValue("koinz_percentages", koinz_percentage_value);
                      setValue("brand_percentages", actualValue);
                    }}
                  />
                </div>
              )}
            </div>
            {(watchers.promotion_type === PromotionType.PERCENTAGE ||
              watchers.promotion_type === PromotionType.CASHBACK) && (
              <>
                <div
                  className={`${styles.split_division_switch} ${
                    max_discount_values_flag ? styles.active : ""
                  }`}
                >
                  <div className={styles.divider}>
                    <div className={styles.formControl}>
                      <div
                        className={styles.toggle_header}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          {watchers.promotion_type === PromotionType.CASHBACK
                            ? "Apply Max cashback value for All"
                            : "Apply capping for All"}
                        </span>
                        <Controller
                          name="max_discount_values_flag"
                          control={control}
                          render={({ field }) => {
                            return (
                              <Switch
                                checked={max_discount_values_flag}
                                {...field}
                                onChange={(checked) => {
                                  applyMaxDiscountAction(checked, field);
                                }}
                                size="default"
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {max_discount_values_flag && (
                    <GenericInputField
                      register={register("max_discount_values")}
                      type={"number"}
                      onChange={(e) => {
                        const actualValue =
                          Number(e.target.value ?? 0) >= 0
                            ? Number(e.target.value ?? 0)
                            : 0;
                        setValue("max_discount_values", actualValue);
                      }}
                      placeholder={"e.g.  15"}
                      label={"Capping"}
                      error={errors?.max_discount_values}
                      badge={currency}
                    />
                  )}
                </div>
                <div
                  className={`${styles.split_division_switch} ${
                    min_eligible_invoice_flag ? styles.active : ""
                  }`}
                >
                  <div className={styles.divider}>
                    <div className={styles.formControl}>
                      <div
                        className={styles.toggle_header}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Apply Min eligble invoice for All</span>
                        <Controller
                          name="min_eligible_invoice_flag"
                          control={control}
                          render={({ field }) => {
                            return (
                              <Switch
                                checked={min_eligible_invoice_flag}
                                {...field}
                                onChange={(checked) => {
                                  applyMinValueAction(checked, field);
                                }}
                                size="default"
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {min_eligible_invoice_flag && (
                    <GenericInputField
                      register={register("min_eligible_invoices")}
                      type={"number"}
                      placeholder={"e.g.  15"}
                      onChange={(e) => {
                        const actualValue =
                          Number(e.target.value ?? 0) >= 0
                            ? Number(e.target.value ?? 0)
                            : 0;
                        setValue("min_eligible_invoices", actualValue);
                      }}
                      label={"Min eligible invoice"}
                      error={errors?.min_eligible_invoices}
                      badge={currency}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          {watchers.promotion_type === PromotionType.CASHBACK && (
            <div
              className={`${styles.split_division_switch} ${
                expires_after_values_flag ? styles.active : ""
              }`}
            >
              <div className={styles.divider}>
                <div className={styles.formControl}>
                  <div
                    className={styles.toggle_header}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>Apply Expires After For All</span>
                    <Controller
                      name="expires_after_values_flag"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Switch
                            checked={expires_after_values_flag}
                            {...field}
                            onChange={(checked) => {
                              applyExpiresAfterAction(checked, field);
                            }}
                            size="default"
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              {expires_after_values_flag && (
                <GenericInputField
                  register={register("expires_after_values")}
                  type={"number"}
                  placeholder={"e.g.  15"}
                  onChange={(e) => {
                    const actualValue =
                      Number(e.target.value ?? 72) >= 0
                        ? Number(e.target.value ?? 72)
                        : 72;
                    setValue("expires_after_values", actualValue);
                  }}
                  label={"Expires After"}
                  error={errors?.expires_after_values}
                  badge={"Hours"}
                />
              )}
            </div>
          )}
        </>
      )}

      {!memoizedSplittedValues && (
        <div className={styles.split_wrapper}>
          {watchers.brands.map((item, index) => {
            return (
              <BrandItemFields
                brand={item}
                index={index}
                key={item.id}
                register={register}
                watchers={watchers}
                setValue={setValue}
                setFocus={setFocus}
                trigger={trigger}
                errors={
                  errors?.brands ? (errors.brands as any[])[index] : undefined
                }
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default React.memo(PromocodeSplit);
