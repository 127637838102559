import { createAsyncThunk } from "@reduxjs/toolkit";
import { Filters } from "../../pages/TargetingTool/CampaignsList/types";
import { getter } from "../../services";
import { getCampaignsCount } from "../../services/requests";
import {} from "../slices/auth-slice";
export const getCampaignListCountRequest = createAsyncThunk<
  any,
  {
    filters: Filters;
  }
>(
  "campaigns/campaigns_list/count",
  async ({ filters }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await getter(getCampaignsCount, {
        baseURL: `${process.env.REACT_APP_GROWTH_TOOL_ENDPOINT}`,
        headers: {
          "X-Api-Key": "staging",
        },
        params: {
          ...filters,
        },
      });

      return fulfillWithValue({ count: data.count });
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  }
);
