import React, { useMemo } from "react";
import { GenericDropdown } from "../../../components/GenericDropdown";
import { GenericSwitch } from "../../../components/GenericSwitch";
import {
  DeliveryAndPickup,
  PointMultiplierValue,
  PromocodeTypes,
  PromocodeTypesWithCashback,
  PromocodeTypesWithoutPointMultiplier,
  PromocodeVisibility,
} from "../../../constant/DropdownOptions";
import styles from "./styles.module.sass";
import {
  OrderingType,
  PointMultiplier,
  PromocodeSettingProps,
  PromotionType,
  VisibilityType,
} from "../types";
import "./styles.less";
import StartEndDate from "../../../components/Forms/StartEndDate";
import { CloneGenericInputField } from "../../../components/CloneGenericInputField";
import { useCurreny } from "../../../hooks";
import { reportStatusUnit } from "../../../constant";
import { setInfo } from "../../../store/slices/promoInfo-slice";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
const MAX_PERSENTAGE = 100;
const MAX_DISCOUNT = 200;
export const PromocodeSetting: React.FC<PromocodeSettingProps> = (props) => {
  const { control, watchers, setValue, errors } = props;
  const currency = useCurreny(watchers.country_code);

  const dispatch = useAppDispatch();

  const cashbackVisibility = useMemo(
    () =>
      watchers.visibility_type === "invisible"
        ? PromocodeTypesWithCashback
        : PromocodeTypes,
    [watchers.visibility_type, watchers.masked]
  );

  const handelSwitchOnChange = (value: boolean) => {
    dispatch(
      setInfo({
        masked: value,
      })
    );
    setValue("masked", value);
    if (value) {
      setValue("promotion_type", PromotionType.INITIAL_TYPE_VALUE);
    }
  };

  const handlePromoMerchantVisibilityChange = (value: VisibilityType) => {
    if (value !== watchers.visibility_type) {
      dispatch(
        setInfo({
          visibility_type: value as VisibilityType,
          masked: false,
          promotion_type: PromotionType.INITIAL_TYPE_VALUE,
          discount_percentage: 0,
        })
      );
      setValue("discount_percentage", 0);
      setValue("masked", false);
      setValue("promotion_type", PromotionType.INITIAL_TYPE_VALUE);
    }
  };

  const maxDiscountValueOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const persentage =
      watchers.promotion_type === PromotionType.PERCENTAGE ||
      watchers.promotion_type === PromotionType.CASHBACK
        ? MAX_PERSENTAGE
        : MAX_DISCOUNT;
    const updateDiscountNumber =
      Number(e.target.value) >= 0 && Number(e.target.value) <= persentage
        ? Number(e.target.value)
        : Number(e.target.value) < 0
        ? 0
        : persentage;

    setValue("discount_percentage", updateDiscountNumber);
    setValue("max_discount", updateDiscountNumber);

    dispatch(
      setInfo({
        discount_percentage: updateDiscountNumber,
        max_discount: updateDiscountNumber,
      })
    );
  };

  const handlePromoType = (value: PromotionType) => {
    if (watchers.promotion_type !== value) {
      setValue("discount_percentage", 0);
      setValue("max_discount", 0);
      setValue("min_eligible", 0);
      setValue("max_usage_times", 0);
      setValue("point_multiplier_value", PointMultiplier.INITIAL);
    }
    dispatch(
      setInfo({
        promotion_type: value as PromotionType,
      })
    );
  };

  const handleOrderingType = (value: OrderingType) => {
    dispatch(
      setInfo({
        ordering_type: value,
      })
    );
  };

  const handleMaxUsage = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setInfo({
        max_usage_times: Number(e.target.value),
      })
    );
  };
  const handleMaxDiscount = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setInfo({
        max_discount: Number(e.target.value),
      })
    );
  };
  const handleMinEligible = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setInfo({
        min_eligible: Number(e.target.value),
      })
    );
  };
  const handlePointMultiplier = (value: PointMultiplier) => {
    dispatch(
      setInfo({
        point_multiplier_value: value,
      })
    );
  };
  const handlePreselectPromoSwitcher = (value: boolean) => {
    dispatch(
      setInfo({
        preselectPromo: value,
      })
    );
  };

  return (
    <section>
      <div className={styles.promocodeSettingsCotnainer}>
        <div className={styles.promocodeDropdownWrapper}>
          <GenericDropdown
            label="Promocode visibility"
            name="visibility_type"
            control={control}
            options={PromocodeVisibility}
            size="large"
            className="generic-input-feild feild-arrow"
            labelStyle={styles.label}
            arrowColor="#1C1C1C"
            value={watchers.visibility_type}
            customOnChange={(value) =>
              handlePromoMerchantVisibilityChange(value)
            }
          />
        </div>

        <div className={styles.promocodeTypes}>
          <GenericDropdown
            name="promotion_type"
            control={control}
            label="Promocode type"
            size="large"
            options={
              watchers.masked
                ? PromocodeTypesWithoutPointMultiplier
                : cashbackVisibility
            }
            className="generic-input-feild feild-arrow"
            labelStyle={styles.label}
            arrowColor="#1C1C1C"
            value={watchers.promotion_type}
            customOnChange={(value) => {
              handlePromoType(value);
            }}
          />
        </div>

        <div className={styles.promocodeDropdownWrapper}>
          <GenericDropdown
            label="Promocode ordering type"
            name="ordering_type"
            control={control}
            size="large"
            options={DeliveryAndPickup}
            className="generic-input-feild feild-arrow"
            labelStyle={styles.labeldiscount_percentage}
            arrowColor="#1C1C1C"
            value={watchers.ordering_type}
            customOnChange={(value) => handleOrderingType(value)}
          />
        </div>
      </div>
      <div>
        {watchers.visibility_type === "invisible" && (
          <div className={styles.promocodeSettingsCotnainer}>
            <GenericSwitch
              control={control}
              name="masked"
              label="This is a masked promo"
              className={
                watchers.masked
                  ? styles.switchToggleContainer
                  : styles.activeSwitch
              }
              labelStyle={styles.label}
              checked={watchers.masked}
              handelSwitchOnChange={(value) => handelSwitchOnChange(value)}
            />
          </div>
        )}
      </div>

      <StartEndDate
        control={control}
        errors={errors}
        start_date={watchers.start_date}
        end_date={watchers.end_date}
        setValue={setValue}
      />
      <hr className={styles.line} />

      {watchers.promotion_type !== PromotionType.INITIAL_TYPE_VALUE && (
        <div>
          <div className={styles.divider}>
            {watchers.promotion_type !== PromotionType.POINTS_MULTIPLIER && (
              <CloneGenericInputField
                control={control}
                errors={errors}
                name="discount_percentage"
                type={"number"}
                placeholder={"Ex.2"}
                label={reportStatusUnit[watchers.promotion_type]}
                badge={
                  watchers.promotion_type === PromotionType.PERCENTAGE ||
                  watchers.promotion_type === PromotionType.CASHBACK
                    ? "%"
                    : currency
                }
                value={watchers.discount_percentage}
                handleOnChange={(e) => {
                  maxDiscountValueOnChange(e);
                }}
              />
            )}
            {watchers.promotion_type === PromotionType.POINTS_MULTIPLIER && (
              <div className={styles.pointMultiplierContainer}>
                <GenericDropdown
                  name="point_multiplier_value"
                  control={control}
                  label="Promocode ordering type"
                  size="large"
                  options={PointMultiplierValue}
                  className={"input-feild arrow"}
                  labelStyle={styles.label}
                  arrowColor="#1C1C1C"
                  errors={errors}
                  value={watchers.point_multiplier_value}
                  customOnChange={(e) => handlePointMultiplier(e)}
                />
              </div>
            )}
            {watchers.promotion_type === PromotionType.DISCOUNT_VALUE && (
              <>
                <CloneGenericInputField
                  name="max_discount"
                  control={control}
                  errors={errors}
                  type="number"
                  placeholder={"Ex.2"}
                  label={"Max Discount Value"}
                  badge={currency}
                  disabled={true}
                  value={watchers.max_discount}
                  handleOnChange={(e) => handleMaxDiscount(e)}
                />
                <CloneGenericInputField
                  name="min_eligible"
                  control={control}
                  errors={errors}
                  type="number"
                  badge={currency}
                  label={"Min Eligible Invoice"}
                  placeholder={"Ex.2"}
                  value={watchers.min_eligible}
                  handleOnChange={(e) => handleMinEligible(e)}
                />
              </>
            )}
            <CloneGenericInputField
              name="max_usage_times"
              control={control}
              type={"number"}
              placeholder={"Ex.2"}
              label={"Max usage Times"}
              errors={errors}
              value={watchers.max_usage_times}
              handleOnChange={(value) => handleMaxUsage(value)}
            />
          </div>
          {watchers.promotion_type === PromotionType.POINTS_MULTIPLIER && (
            <GenericSwitch
              control={control}
              name="preselectPromo"
              label={
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      color: "#1C1C1C",
                      fontSize: 16,
                      fontWeight: 700,
                    }}
                  >
                    This is a Preselected promocode
                  </span>
                  <span
                    style={{
                      color: "#65656C",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    This will appears to Koinz app users as a suggested
                    promocodes before checkout
                  </span>
                </div>
              }
              className={
                watchers.preselectPromo
                  ? styles.switchToggleContainer
                  : styles.activeSwitch
              }
              labelStyle={styles.genericSwitch}
              checked={watchers.preselectPromo}
              handelSwitchOnChange={(value) =>
                handlePreselectPromoSwitcher(value)
              }
            />
          )}
        </div>
      )}
    </section>
  );
};

export default PromocodeSetting;
