import React from "react";
import {
  CommunicationTemplate,
  CreateCommTemplate,
  Login,
  Promocode,
  TargetingTool,
} from "../pages";
import NotFound from "../pages/NotFound";
import { CampaignsList } from "../pages/TargetingTool/CampaignsList";

export interface IRoute {
  label: string;
  path: string;
  parent?: string;
}
export interface IRoutes {
  [key: string]: IRoute;
}

export const protectedRoutes: IRoutes = {
  promocodes: {
    path: "/promocodes",
    label: "promocodes",
  },
  targeting_tool: {
    path: "/targeting-tool",
    label: "targeting_tool",
  },
  tool: {
    path: "/targeting-tool/:id",
    label: "tool",
    parent: "targeting_tool",
  },
  target_campaigns: {
    path: "/targeting-tool/target-campaigns",
    label: "target_campaigns",
    parent: "targeting_tool",
  },
  communication_template: {
    path: "/targeting-tool/communication_template",
    label: "communication_template",
    parent: "targeting_tool",
  },
  create_template: {
    path: "/targeting-tool/communication_template/create",
    label: "create_template",
    parent: "communication_template",
  },
  campaigns: {
    path: "/targeting-tool/campaigns",
    label: "campaigns",
    parent: "targeting_tool",
  },
};

export const nonProtectedRoutes: IRoutes = {
  login: {
    path: "/login",
    label: "login",
  },
  notFound: {
    path: "*",
    label: "notFound",
  },
};

export const protectedComponents: { [key: string]: React.ReactElement } = {
  promocodes: <Promocode />,
  target_campaigns: <TargetingTool />,
  communication_template: <CommunicationTemplate />,
  create_template: <CreateCommTemplate />,
  campaigns: <CampaignsList />,
};
export const nonProtectedComponents: { [key: string]: React.ReactElement } = {
  login: <Login />,
  notFound: <NotFound />,
};

export const routes: IRoutes = {
  ...protectedRoutes,
  ...nonProtectedRoutes,
};

export const segmentFormatter = (segment: string) =>
  segment
    .replaceAll("-", " ")
    .replaceAll("_", " ")
    .trim()
    .split(" ")
    .map((segment) =>
      segment
        .split("")
        .map((letter, index) => (index == 0 ? letter.toUpperCase() : letter))
        .join("")
    )
    .join(" ");

export const normalizeRoute = (
  params: { [key: string]: string },
  routePath: string
) => {
  const pathSegments = routePath.replaceAll("/", " ").trim().split(" ");
  return `/${pathSegments
    .map((segment) => {
      if (segment.startsWith(":")) {
        return params[segment.replaceAll(":", "").trim()];
      }
      return segment;
    })
    .join("/")}`;
};

const getRouteOrigins: (
  origins: IRoute[],
  routeName: string,
  params: { [key: string]: string }
) => any = (origins: IRoute[], routeName: string, params) => {
  const origin = routes[routeName];
  return origin && origin.parent
    ? getRouteOrigins(
        [{ ...origin, path: normalizeRoute(params, origin.path) }, ...origins],
        origin.parent,
        params
      )
    : origin
    ? [origin, ...origins]
    : [...origins];
};

export const getOrigins = (path: string, params: { [key: string]: string }) => {
  const route = Object.values(routes).find((route) => route.path === path);
  const origins = getRouteOrigins([], route?.label as string, params);
  return origins;
};
