import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { campaignValidatorSchema } from "../helpers/validator/campaignValidator";
import { CampaignFormValues } from "../types";
import useFormPersist from "./useFormPersist";

export const useCampaign = () => {
  const methods = useForm<CampaignFormValues>({
    resolver: yupResolver(campaignValidatorSchema),
    reValidateMode: "onChange",
  });

  useFormPersist("persist:campaign", {
    watch: methods.watch,
    setValue: methods.setValue,
    storage: window.localStorage,
  });

  const handleSubmit = (values: CampaignFormValues) => {
    console.log({ values });
  };

  return {
    methods,
    handleSubmit: methods.handleSubmit(handleSubmit),
  };
};
