import { createSlice, PayloadAction } from "@reduxjs/toolkit/";
import moment from "moment";
import {
  OrderingType,
  PointMultiplier,
  PromotionType,
} from "../../layouts/Promocode/types";

import { IPromocodeInfoFormValus } from "../types";
export type PromoModeType = "create" | "modify";

interface IState {
  mode: PromoModeType;
  info: IPromocodeInfoFormValus;
  status: "idle" | "succeeded" | "failed" | "loading";
}

export const initialPromoInfo: IPromocodeInfoFormValus = {
  rank: "",
  promo_code: "",
  ar_desc: "",
  desc: "",
  title: "",
  ar_title: "",
  country_code: "",
  start_date: Number(moment().startOf("day").format("x")),
  end_date: Number(moment().endOf("day").format("x")),
  koinz_percentages: 0,
  brand_percentages: 100,
  min_eligible_invoices: 0,
  max_discount_values: 0,
  min_eligible_invoice_flag: false,
  max_discount_values_flag: false,
  split_flag: false,
  brands: [],
  visibility_type: "visible",
  promotion_type: PromotionType.INITIAL_TYPE_VALUE,
  ordering_type: OrderingType.DELIVERY_AND_PICKUP,
  max_usage_times: undefined,
  discount_percentage: undefined,
  masked: false,
  max_discount: 0,
  min_eligible: 0,
  point_multiplier_value: PointMultiplier.INITIAL,
  preselectPromo: false,
  targeted_customers_type: "universal",
  expires_after_values: 72,
  expires_after_values_flag: false,
  targeting_method: "all_customers",
};

const initialState: IState = {
  mode: "create",
  info: initialPromoInfo,
  status: "idle",
};

export const promoInfoSlice = createSlice({
  initialState,
  name: "promoInfoSlice",
  reducers: {
    setInfo: (
      state,
      { payload }: PayloadAction<Partial<IPromocodeInfoFormValus>>
    ) => {
      return {
        ...state,
        info: { ...state.info, ...payload },
      };
    },
    setMode: (
      state,
      {
        payload: { mode, info },
      }: PayloadAction<{ mode: PromoModeType; info: IPromocodeInfoFormValus }>
    ) => {
      return {
        ...state,
        mode,
        info,
      };
    },
  },
});
export const { setInfo, setMode } = promoInfoSlice.actions;
export default promoInfoSlice.reducer;
