import { createSlice } from "@reduxjs/toolkit/";
import { notify } from "../../helpers";
import { Campaign } from "../../pages/TargetingTool/CampaignsList/types";
import { getCampaignListRequest } from "../thunks/getCampaignList";
import { getCampaignListCountRequest } from "../thunks/getCampaignListCount";
interface IState {
  campaigns: Campaign[];
  isLoading: boolean;
  status: "idle" | "succeeded" | "failed" | "loading";
  count: number;
}

const initialState: IState = {
  campaigns: [],
  isLoading: false,
  status: "idle",
  count: 0,
};

export const CampaignListSlice = createSlice({
  initialState,
  name: "campaignsListSlice",
  reducers: {},
  extraReducers(builder) {
    // campaign list data
    builder.addCase(getCampaignListRequest.pending, (state) => ({
      ...state,
      status: "loading",
      isLoading: true,
    })),
      builder.addCase(
        getCampaignListRequest.fulfilled,
        (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            campaigns:
              payload.page === 0
                ? [...payload.data]
                : [...state.campaigns, ...payload.data],
            status: "succeeded",
          };
        }
      ),
      builder.addCase(getCampaignListRequest.rejected, (state, action) => {
        notify("Please check your credentials", "error");

        return {
          ...state,
          status: "failed",
          isLoading: false,
          error: action.error.message,
        };
      });
    // campaign list count
    builder.addCase(getCampaignListCountRequest.pending, (state) => ({
      ...state,
      status: "loading",
      isLoading: true,
    })),
      builder.addCase(
        getCampaignListCountRequest.fulfilled,
        (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            count: payload.count,
            status: "succeeded",
          };
        }
      ),
      builder.addCase(getCampaignListCountRequest.rejected, (state, action) => {
        notify("Please check your credentials", "error");

        return {
          ...state,
          status: "failed",
          isLoading: false,
          error: action.error.message,
        };
      });
  },
});

export default CampaignListSlice.reducer;
