import React from "react";
import { GenericInputField } from "../../../components";
import styles from "./styles.module.sass";
import { useFormContext } from "react-hook-form";
import { SectionWrapper } from "../../../components";
import GenericDateField from "../../../components/Forms/GenericDateField";
function CampaignSettings() {
  const {
    register,
    formState: { errors },
    control,
    watch,
  } = useFormContext();
  const watchers = watch();

  return (
    <SectionWrapper
      title="Campaign Settings"
      wrapperClassName={styles.sectionContainer}
    >
      <div className={styles.wrapper}>
        <div className={styles.campaign_title}>
          <GenericInputField
            register={register("title")}
            type={"text"}
            placeholder={
              "ex. “Ennabi - EXP - Reactivation - Cashback -  KN40 - Push - 11/08”"
            }
            label={"Campaign title"}
            error={errors?.title}
          />
        </div>
        <div className={styles.campaign_date}>
          <GenericDateField
            control={control}
            placeholder={"Select ending date..."}
            label={"End time"}
            value={watchers.end_time}
            name={"end_time"}
            errors={errors?.end_time}
          />
        </div>
      </div>
    </SectionWrapper>
  );
}

export default CampaignSettings;
