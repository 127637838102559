import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import PathWrapper from "./PathWrapper";
import ProtectedRoute from "./ProtectedRoute";
import {
  nonProtectedComponents,
  nonProtectedRoutes,
  protectedComponents,
  protectedRoutes,
} from "./utils";

export const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoute />}>
          {Object.values(protectedRoutes).map((route) => (
            <Route
              key={route.label}
              path={route.path}
              element={
                <PathWrapper
                  path={protectedComponents[route.label]}
                  route={route}
                />
              }
            />
          ))}
        </Route>
        {Object.values(nonProtectedRoutes).map((route) => (
          <Route
            key={route.label}
            path={route.path}
            element={
              <PathWrapper
                path={nonProtectedComponents[route.label]}
                route={route}
              />
            }
          />
        ))}
      </Routes>
    </Router>
  );
};

export default AppRoutes;
