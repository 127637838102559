import React, { useMemo, useState } from "react";
import styles from "./styles.module.sass";
import "./styles.less";
import images from "../../../assets/images/images";
import { ISelectOverlay } from "../types";

export const Overlay = <T extends string | number | undefined>({
  options,
  onSelect,
  selectedItem,
  placeholder,
}: ISelectOverlay<T>) => {
  const [searchTerm, setSearchTerm] = useState("");
  const filteredOptions = useMemo(() => {
    return options.filter((option) => {
      return option?.label?.toString()?.toLowerCase()?.includes(searchTerm);
    });
  }, [searchTerm]);
  return (
    <React.Fragment>
      <div className={styles.layout}>
        <div className={styles.search}>
          <div className={styles.icon}>
            <img src={images.search} />
          </div>
          <input
            placeholder={placeholder ? placeholder : "Search for promocode..."}
            type="text"
            className={styles.input}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </div>
        <hr />
        {filteredOptions.map((item, index) => (
          <div
            className={`${styles.content} ${
              selectedItem && selectedItem == item.value ? styles.active : ""
            }`}
            key={index}
            onClick={() => {
              onSelect({
                label: item.label,
                value: item.value,
                description: item?.description,
              });
            }}
          >
            <div className={styles.info}>
              <div className={styles.label}>{item.label}</div>
              <div className={styles.description}>
                {Number(item?.description?.length || 0) > 100
                  ? `${item?.description?.substring(0, 35)}...`
                  : item?.description}
              </div>
            </div>
            <div className={styles.value}>{item.value}</div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};
