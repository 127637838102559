import { createApi } from "@reduxjs/toolkit/query/react";
import { RootState } from "..";
import { generateFormDataObjects } from "../../helpers/formDataPayloadMapper";
import { pusher } from "../../pusher/pusher";
import {
  axiosBaseQuery,
  promocodeTragetedCustomerFileUpload,
} from "../../services";
import {
  customerFileVerificationEventPending,
  customerFileVerificationEventReset,
  customerFileVerificationEventSucceed,
} from "../slices/customer-file-verification-slice";

export const TargetedCustomersFileUploadApi = createApi({
  reducerPath: "promocodeTargetedCustomersFileUpload",
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_PROMOCODE_ENDPOINT}`,
  }),
  tagTypes: ["promocode_file_upload"],
  endpoints: (builder) => ({
    tragetedCustomerFile: builder.mutation<
      any,
      {
        data: {
          target_customers: File;
        };
      }
    >({
      query: ({ data }) => {
        const formDataInstance: FormData = new FormData();
        return {
          method: "POST",
          url: promocodeTragetedCustomerFileUpload,
          data: generateFormDataObjects(formDataInstance, data),
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const token = (getState() as RootState).authReducer.token;
          dispatch(customerFileVerificationEventPending());
          await queryFulfilled;
          const socket = pusher(token);
          socket
            .subscribe("presence-customer-file-verification")
            .bind(
              "customer-file-verification-event",
              (data: {
                code: string;
                file_path?: string;
                message?: string;
              }) => {
                dispatch(
                  customerFileVerificationEventSucceed({
                    code: data.code,
                    file_path:
                      data.code === "VALIDATION-SUCCESS"
                        ? data.file_path
                        : undefined,
                    status:
                      data.code === "VALIDATION-SUCCESS" ? "succeed" : "failed",
                    message:
                      data.code === "VALIDATION-SUCCESS"
                        ? undefined
                        : data.message,
                  })
                );
              }
            );
        } catch (error) {
          dispatch(customerFileVerificationEventReset());
        }
      },
    }),
  }),
});
export const { useTragetedCustomerFileMutation } =
  TargetedCustomersFileUploadApi;
