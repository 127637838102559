import React from "react";
import styles from "./styles.module.sass";
import { SectionWrapperProps } from "./types";

export const SectionWrapper: React.FC<SectionWrapperProps> = (props) => {
  const { children, title, wrapperClassName } = props;
  return (
    <section className={wrapperClassName}>
      <h1 className={styles.title}>{title}</h1>
      {children}
    </section>
  );
};

export default SectionWrapper;
