import { Modal } from "antd";
import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import images from "../../../../assets/images/images";
import styles from "./styles.module.sass";
export interface IConnectionSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  segmentIndex: number;
}
export const ConnectionTypes: IConnectionCard[] = [
  {
    title: "Push notifications",
    id: "notifications_id",
    image: images.smartphone,
    card_image: images.notification,
  },
  {
    title: "SMS",
    id: "sms_id",
    image: images.blackMessage,
    card_image: images.sms,
  },
  {
    title: "Whatsapp",
    id: "whatsapp_id",
    image: images.whatsapp,
    card_image: images.whatsapp,
  },
];
const ConnectionSelectionModal: React.FC<IConnectionSelectionModalProps> = ({
  onClose,
  isOpen,
  segmentIndex,
}: IConnectionSelectionModalProps) => {
  const { watch, setValue } = useFormContext();
  const { segment } = watch();
  const selectedConnections = useMemo(() => {
    return segment[segmentIndex].connections?.map(
      (selectedConnection: any) => selectedConnection.id
    );
  }, [segment[segmentIndex].connections]);
  return (
    <Modal
      open={isOpen}
      destroyOnClose={true}
      closable={false}
      onCancel={() => {
        onClose();
      }}
      title={""}
      footer={""}
      width={582}
      centered={true}
    >
      <div className={styles.header}>
        <div className={styles.title}>
          {/* <img className={styles.icon} src={image} alt="" /> */}
          <span className={styles.content}>Choose Communication Channel</span>
        </div>
        <button type="button" onClick={onClose}>
          &times;
        </button>
      </div>
      <div className={styles.connections_wrapper}>
        {ConnectionTypes.map((item) => {
          return (
            <ConnectionCard
              key={item.id}
              item={item}
              selected={selectedConnections.includes(item.id)}
              onSelect={() => {
                setValue(`segment.${segmentIndex}.connections`, [
                  ...segment[segmentIndex].connections,
                  {
                    name: item.title,
                    id: item.id,
                    attachActiveLink: false,
                    template_id: "",
                  },
                ]);
              }}
            />
          );
        })}
      </div>
    </Modal>
  );
};

export default ConnectionSelectionModal;

export interface IConnectionCard {
  title: string;
  image: string;
  id: string;
  card_image: string;
}
export interface IConnectionCardProps {
  item: IConnectionCard;
  onSelect: () => void;
  selected: boolean;
}
export const ConnectionCard: React.FC<IConnectionCardProps> = ({
  item: { title, image },
  selected,
  onSelect,
}: IConnectionCardProps) => {
  return (
    <div
      onClick={
        !selected
          ? onSelect
          : () => {
              console.log("it's already selected");
            }
      }
      className={`${styles.connection} ${selected && styles.active}`}
    >
      <div className={styles.image}>
        <img src={image} />
      </div>
      <div className={styles.title}>
        <span>{title}</span>
      </div>
      <div className={styles.status}>{selected && <span>Added!</span>}</div>
    </div>
  );
};
