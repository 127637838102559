import React from "react";
import { Control, Controller, FieldPath } from "react-hook-form";
import { Mention, MentionProps, MentionsInput } from "react-mentions";
import styles from "./styles.module.sass";
import classNames from "./styles.module.css";
import classNamesError from "./error.module.css";
interface TextareaMentionsProps<
  TFieldValues extends Record<string, any>,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends MentionProps {
  placeholder?: string;
  label?: JSX.Element | string;
  rootClassName?: string;
  name: TName;
  control: Control<TFieldValues>;
  classNames?: { [x: string]: string };
}
const GenericMentionsInput = <
  TFieldValues extends Record<string, any>,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  placeholder,
  label,
  rootClassName,
  onAdd: onAddProp,
  style: mentionStyle,
  control,
  name,
  trigger,
  classNames: classNamesProp,
  ...rest
}: TextareaMentionsProps<TFieldValues, TName>) => {
  const onAdd = (e: any) => {
    console.log("onAdd", e);
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <div className={`${styles.wrapper} ${rootClassName}`}>
            {React.isValidElement(label) ? (
              label
            ) : (
              <span className={styles.label}>{label}</span>
            )}
            <MentionsInput
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              classNames={
                classNamesProp ?? error?.message
                  ? { ...classNamesError }
                  : { ...classNames }
              }
            >
              <Mention
                {...rest}
                trigger={trigger}
                onAdd={onAddProp ?? onAdd}
                appendSpaceOnAdd={false}
                style={mentionStyle}
                className={rest.className ?? classNames.mentions__mention}
              />
            </MentionsInput>
          </div>
        );
      }}
    />
  );
};

export default GenericMentionsInput;
