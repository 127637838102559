export const weekOptions = [
  {
    label: "First Week",
    value: 1,
  },
  {
    label: "Second Week",
    value: 2,
  },
  {
    label: "Third Week",
    value: 3,
  },
  {
    label: "Fourth Week",
    value: 4,
  },
];

export const daysOptions = [
  {
    label: "Sunday",
    value: 1,
  },
  {
    label: "Monday",
    value: 2,
  },
  {
    label: "Tuesday",
    value: 3,
  },
  {
    label: "Wednesday",
    value: 4,
  },
  {
    label: "Thursday",
    value: 5,
  },
  {
    label: "Friday",
    value: 6,
  },
  {
    label: "Saturday",
    value: 7,
  },
];
