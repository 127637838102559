import { createAsyncThunk } from "@reduxjs/toolkit";
import { getter } from "../../services";
import { getPromoHighestRank as url } from "../../services/requests";
export const getPromoHighestRank = createAsyncThunk<any, { country?: string }>(
  "highest-ranks/get",
  async (params, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await getter(url, {
        baseURL: process.env.REACT_APP_PROMOCODE_ENDPOINT,
        params,
      });
      return fulfillWithValue({
        data: !data ? { sa: 0, eg: 0 } : data,
      });
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
