import { createSlice, PayloadAction } from "@reduxjs/toolkit/";
import { notify } from "../../helpers";
import { getCountrybrands, addBrandsCSV } from "../thunks";

export interface IBrandsOption {
  id: string;
  name: string;
  masked_promo_code?: any;
  masked_value?: any;
  min_threshold?: any;
  store_fund_percentage?: any;
  country_iso_code?: string;
  selected: boolean;
}

type LoadingStatus = "idle" | "succeeded" | "failed" | "loading";
interface IState {
  country_code: string;
  brands: IBrandsOption[];
  selectedBrands: IBrandsOption[];
  isloading: boolean;
  status: LoadingStatus;
  csvBrandsStatus: LoadingStatus;
  allSelected: boolean;
  hasChange: boolean;
}

const initialState: IState = {
  country_code: "",
  brands: [],
  selectedBrands: [],
  isloading: false,
  status: "idle",
  csvBrandsStatus: "idle",
  allSelected: false,
  hasChange: false,
};

function compare(arr1: any[], arr2: any[]) {
  const mappingarr1 = arr1.map((item) => item.id);
  const mappingarr2 = arr2.map((item) => item.id);
  const hasChange = !mappingarr1.every((item) => {
    return mappingarr2.includes(item);
  });
  if (mappingarr1.length !== mappingarr2.length) return true;
  return hasChange;
}
export const countryBrandsSlice = createSlice({
  initialState,
  name: "country-brands",
  reducers: {
    getSelectedBrands: (state) => {
      return {
        ...state,
        selectedBrands: state.brands.filter((item) => item.selected),
        hasChange: false,
      };
    },
    selectBrand: (
      state,
      { payload: { value } }: PayloadAction<{ value: string }>
    ) => {
      const prevSelected: IBrandsOption[] = [...state.selectedBrands];
      const mappedBrands: IBrandsOption[] = state.brands.map((element) => {
        if (element.id === value) {
          return { ...element, selected: !element.selected };
        }
        return element;
      });
      const mappedSelectedBrands = mappedBrands.filter((item) => item.selected);
      return {
        ...state,
        brands: mappedBrands,
        // selectedBrands: mappedSelectedBrands,
        hasChange: compare(prevSelected, mappedSelectedBrands),
      };
    },
    removeBrand: (
      state,
      { payload: { value } }: PayloadAction<{ value: string }>
    ) => {
      return {
        ...state,
        selectedBrands: state.selectedBrands.filter(
          (item) => item.id !== value
        ),
        brands: state.brands.map((item) => {
          return item.id === value
            ? { ...item, selected: !item.selected }
            : item;
        }),
      };
    },

    selectAll: (state, { payload: { isAllSelected } }) => {
      const prevSelected: IBrandsOption[] = [...state.selectedBrands];
      const mappedBrands: IBrandsOption[] = state.brands.map((element) => {
        return { ...element, selected: isAllSelected };
      });
      const mappedSelectedBrands = mappedBrands.filter((item) => item.selected);
      return {
        ...state,
        brands: mappedBrands,
        selectedBrands: mappedSelectedBrands,
        hasChange: compare(prevSelected, mappedSelectedBrands),
        allSelected: isAllSelected,
      };
    },

    clearSelectedBrands: (state) => {
      state.selectedBrands = [];
    },
    resetCountryBrandsState: () => ({
      ...initialState,
    }),
  },
  extraReducers(builder) {
    builder.addCase(getCountrybrands.pending, (state) => ({
      ...state,
      status: "loading",
      isloading: true,
      brands: [],
      selectedBrands: [],
      searchFilterBrands: [],
      allSelected: false,
    })),
      builder.addCase(
        getCountrybrands.fulfilled,
        (
          state,
          {
            payload: { data, country_code },
          }: PayloadAction<{
            data: Partial<IBrandsOption>[];
            country_code: string;
          }>
        ) => {
          return {
            ...state,
            country_code,
            isloading: false,
            brands: (data as IBrandsOption[]).map((item) => ({
              ...item,
              selected: false,
            })),
            searchFilterBrands: state.brands,
            selectedBrands: [],
            status: "succeeded",
          };
        }
      ),
      builder.addCase(getCountrybrands.rejected, (state, action) => {
        notify("something went wrong", "error");
        return {
          ...state,
          status: "failed",
          brands: [],
          selectedBrands: [],
          isloading: false,
          error: action.error.message,
        };
      });
    builder.addCase(addBrandsCSV.pending, (state) => {
      return {
        ...state,
        csvBrandsStatus: "loading",
      };
    }),
      builder.addCase(addBrandsCSV.fulfilled, (state, { payload }) => {
        const brandsCSV = (payload.data as IBrandsOption[]).map((item) => ({
          ...item,
          selected: true,
        }));
        return {
          ...state,
          csvBrandsStatus: "succeeded",
          brands: brandsCSV.length ? brandsCSV : [],
          selectedBrands: brandsCSV.length ? brandsCSV : [],
        };
      }),
      builder.addCase(addBrandsCSV.rejected, (state) => {
        return {
          ...state,
          csvBrandsStatus: "failed",
        };
      });
  },
});
export const {
  selectBrand,
  selectAll,
  getSelectedBrands,
  removeBrand,
  clearSelectedBrands,
  resetCountryBrandsState,
} = countryBrandsSlice.actions;
export default countryBrandsSlice.reducer;
