import { createAsyncThunk } from "@reduxjs/toolkit";
import { getter } from "../../services";
import { getBrands } from "../../services/requests";
import {} from "../slices/auth-slice";
import { IBrandsOption } from "../slices/country-brands-slice";
export const getCountrybrands = createAsyncThunk<any, { country_code: string }>(
  "country-brands/get",
  async ({ country_code }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await getter(getBrands, {
        baseURL: process.env.REACT_APP_ORDERING_ENDPOINT,
        params: {
          country_code,
        },
      });
      return fulfillWithValue({
        country_code,
        data: !data
          ? []
          : data.stores.map(
              ({ value, label }: { value: string; label: string }) =>
                ({
                  id: value,
                  name: label,
                } as IBrandsOption)
            ),
      });
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
