export const getHighlights = (s: string): string[] => {
  const openning = "{";
  const closing = "}";
  const opennings: number[] = [];
  const closings: number[] = [];

  s.split("").forEach((char: string, index) => {
    if (char === openning) opennings.push(index);
    if (char === closing) closings.push(index);
  });
  const segments = opennings.reduce((acc: string[], _, index) => {
    return [
      ...acc,
      s.slice(opennings[index], (closings[index] || opennings[index]) + 1),
    ];
  }, []);
  return segments;
};
