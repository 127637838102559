import React from "react";
import { NavLink } from "react-router-dom";
import images from "../../assets/images/images";
import CollapseTargetingTool from "./CollapseTargetingTool";
import styles from "./styles.module.sass";
const SideMenu: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <img src={images.logo} alt="logo" />
      </div>
      <div className={styles.link_list}>
        <NavLink
          to="/promocodes"
          className={({ isActive }) =>
            isActive ? `${styles.link} ${styles.active}` : `${styles.link}`
          }
        >
          <div className={styles.content}>
            <span
              className={styles.image}
              style={{
                backgroundImage: `url(${images.percentage})`,
              }}
            ></span>{" "}
            <span>Promocodes</span>
          </div>
        </NavLink>
        <CollapseTargetingTool />
      </div>
    </div>
  );
};

export default SideMenu;
