import * as yup from "yup";
import moment from "moment";
import { FormValues } from "../../pages/Promocode/types";
import { store } from "../../store";
import { PointMultiplier, PromotionType } from "../../layouts/Promocode/types";
import { useMemo } from "react";
const validateDiscount = (value: string, parent: FormValues) => {
  if (
    parent.promotion_type === PromotionType.PERCENTAGE ||
    parent.promotion_type === PromotionType.DISCOUNT_VALUE
  ) {
    const parsingToInt = parseInt(value);
    return parsingToInt >= 1 && parsingToInt <= 200;
  }
  return true;
};
export const promocodeInfoValidationSchema = () => {
  const {
    ranksReducer: { ranks },
    promoInfoReducer: {
      info: { country_code, promotion_type },
    },
  } = store.getState();
  return useMemo(() => {
    return yup.object().shape({
      ar_title: yup
        .string()
        .min(2, "promocode's arabic title must exceed 2 characters long.")
        .max(
          30,
          "promocode's arabic title should not exceed 30 characters long."
        )
        .required(),
      title: yup
        .string()
        .min(2, "promocode's english title must exceed 2 characters long.")
        .max(
          30,
          "promocode's english title should not exceed 30 characters long."
        )
        .required()
        .matches(
          /^[a-zA-Z0-9!@#$%^&*()_+\-={};':"\\|,.<>?\s]*$/,
          "promocode's english title should be in english characters."
        ),
      ar_desc: yup
        .string()
        .required("This field is required")
        .min(1, "Field must have at least 1 character long")
        .max(150, "Field must have at most 150 character long"),
      desc: yup
        .string()
        .required("This field is required")
        .min(1, "Field must have at least 1 character long")
        .max(150, "Field must have at most 150 character long")
        .test(
          "ar-chars",
          "English description should be in english characters.",
          (value) => {
            return !value
              ? true
              : /^[A-Za-z0-9!@#$%^&*()_+\-={};':"\\|,.<>?\s]*$/.test(value);
          }
        ),
      promo_code: yup
        .string()
        .required("* Promocode is required.")
        .matches(/^[A-Za-z0-9\s]*$/, "Name should be in english characters."),
      rank: yup
        .string()
        .required()
        .test(
          "highest-rank",
          `The rank must not be greater than ${
            country_code === "+966" ? ranks.sa + 1 : ranks.eg + 1
          }`,
          (value) => {
            if (country_code === "+2" && Number(value) > ranks.eg + 1) {
              return false;
            }
            if (country_code === "+966" && Number(value) > ranks.sa + 1) {
              return false;
            }
            return true;
          }
        )
        .test(
          "nan-value",
          "please type a number between 1 and 200",
          (value) => {
            const num = Number(value);
            return !isNaN(num);
          }
        )
        .test(
          "positive-num",
          "please type a positive number between 1 and 100.",
          (value) => {
            const num = Number(value);
            return !isNaN(num)
              ? /^[+]?\d+([.]\d+)?$/.test(num.toString())
                ? num >= 1 && num <= 100
                  ? true
                  : false
                : false
              : false;
          }
        ),
      start_date: yup
        .number()
        .required("start date is required.")
        .test(
          "is-after-current-date",
          "Start date must be after current date",
          (value) => {
            return moment(value).isAfter(new Date());
          }
        ),
      end_date: yup
        .number()
        .required("end date is required.")
        .test(
          "is-after-start-date",
          "End date must be after start date.",
          (value, parent) => {
            const start_date = parent.parent["start_date"];
            return moment(new Date(value || 0)).isAfter(start_date);
          }
        ),
      min_eligible_invoice_flag: yup.boolean(),
      max_discount_values_flag: yup.boolean(),
      split_flag: yup.boolean(),
      expires_after_values_flag: yup.boolean(),
      expires_after_values: yup
        .string()
        .test(
          "within_range",
          "expires after field should have value equal bigger 0.",
          (value, parent) => {
            const isChecked = parent.parent["expires_after_values_flag"];
            const isValid: boolean =
              typeof value !== "undefined" && Number(value) > 0;
            return isChecked ? isValid : true;
          }
        ),
      koinz_percentages: yup
        .string()
        .test(
          "within_range",
          "Koinz percentage field should have value between 0 and 100.",
          (value, parent) => {
            const isChecked = parent.parent["split_flag"];
            const isValid: boolean =
              typeof value !== "undefined" &&
              Number(value) <= 100 &&
              Number(value) >= 0;
            return isChecked ? isValid : true;
          }
        ),
      brand_percentages: yup
        .string()
        .test(
          "within_range",
          "Brand percentage field should have value between 0 and 100.",
          (value, parent) => {
            const isChecked: boolean | undefined = parent.parent["split_flag"];
            const isValid: boolean =
              typeof value !== "undefined" &&
              Number(value) <= 100 &&
              Number(value) >= 0;
            return isChecked ? isValid : true;
          }
        ),
      min_eligible_invoices: yup
        .string()
        .test(
          "within_range",
          "Min eligible invoice field should have value equal bigger 0.",
          (value, parent) => {
            const isChecked = parent.parent["min_eligible_invoice_flag"];
            const isValid: boolean =
              typeof value !== "undefined" && Number(value) >= 0;
            return isChecked ? isValid : true;
          }
        ),

      max_discount_values: yup
        .string()
        .test(
          "within_range",
          "Capping field should have value bigger than 0.",
          (value, parent) => {
            const isChecked: boolean =
              parent.parent["max_discount_values_flag"];
            const isValid: boolean =
              typeof value !== "undefined" && Number(value) >= 0;
            return isChecked ? isValid : true;
          }
        ),
      max_usage_times: yup
        .string()
        .required("Max usage times field is required")
        .test(
          "positive-num",
          "please type a number bigger than or equal to 0.",
          (value) => {
            const num = Number(value);
            return !isNaN(num)
              ? /^[+]?\d+([.]\d+)?$/.test(num.toString())
                ? num >= 0
                  ? true
                  : false
                : false
              : false;
          }
        ),
      discount_percentage: yup
        .string()
        .required("Discount percentage field is required")
        .test(
          "persentage-type-validation",
          "please type a number between 1 and 200",
          (value, parent: { parent: FormValues }) => {
            return validateDiscount(value as string, parent.parent);
          }
        ),
      point_multiplier_value: yup
        .string()
        .optional()
        .test("requires", "*Required", (value) => {
          if (promotion_type !== PromotionType.POINTS_MULTIPLIER) {
            return true;
          }
          return value === PointMultiplier.INITIAL ? false : true;
        }),
      min_eligible: yup.string().required("*Required"),

      brands: validateArray(),
      targeted_customers_type: yup.string().required("this field is required"),
      targeted_customers: yup
        .mixed()
        .nullable()
        .test("required", "this field is required", (value, context) => {
          const type: string = context.parent.targeted_customers_type;
          const isType = type === "targetted";
          return isType ? (value ? true : false) : true;
        })
        .test("fileSize", "The file is too large", (value, context) => {
          const type: string = context.parent.targeted_customers_type;
          const isType = type === "targetted";
          return isType ? (value ? value.size <= 5000000 : true) : true;
          // attachment is optional
        }),
      targeted_customers_file_path: yup
        .string()
        .test("path-validation", "file path is required", (value, context) => {
          const type: string = context.parent.targeted_customers_type;
          const isType = type === "targetted";
          return isType ? (value ? true : false) : true;
          // attachment is optional
        }),

      expiry_days: yup
        .string()
        .test("required", "Expiry days is required", (value, context) => {
          const type: string = context.parent.targeted_customers_type;
          const isType = type === "compensation";
          return isType ? (value ? true : false) : true;
          // attachment is optional
        })
        .test(
          "positive-value",
          "please type a number bigger than or equal to 1.",
          (value, context) => {
            const type: string = context.parent.targeted_customers_type;
            const isType = type === "compensation";
            const num = Number(value);
            return isType
              ? !isNaN(num)
                ? /^[+]?\d+([.]\d+)?$/.test(num.toString())
                  ? num > 0
                    ? true
                    : false
                  : false
                : false
              : true;
          }
        ),
    });
  }, [ranks, country_code, promotion_type]);
};

function validateArray() {
  const {
    split_flag,
    min_eligible_invoice_flag,
    max_discount_values_flag,
    expires_after_values_flag,
  } = store.getState().promoInfoReducer.info;
  return yup.array().of(
    yup.object().shape({
      koinz_percentage: yup
        .string()
        .test("required", "koinz percentage field is required", (value) => {
          const isChecked: boolean = split_flag;

          return !isChecked ? value !== "undefined" : true;
        })
        .test(
          "within_range",
          "Koinz percentage field should have value between 0 and 100.",
          (value) => {
            const num = Number(value);
            const isChecked = split_flag;
            return !isChecked
              ? !isNaN(num)
                ? /^[+]?\d+([.]\d+)?$/.test(num.toString())
                  ? num <= 100 && num >= 0
                    ? true
                    : false
                  : false
                : false
              : true;
          }
        ),
      brand_percentage: yup
        .string()
        .test("required", "brand percentage field is required", (value) => {
          const isChecked: boolean = split_flag;
          return !isChecked ? value !== "undefined" : true;
        })
        .test(
          "within_range",
          "brand percentage field should have value between 0 and 100.",
          (value) => {
            const num: any = Number(value);
            const isChecked: boolean = split_flag;
            return !isChecked
              ? !isNaN(num)
                ? /^[+]?\d+([.]\d+)?$/.test(num.toString())
                  ? num <= 100 && num >= 0
                    ? true
                    : false
                  : false
                : false
              : true;
          }
        ),
      min_eligible_invoice: yup
        .string()
        .test(
          "within_range",
          "Min eligible invoice field have a value equal to or bigger than 0.",
          (value) => {
            const num = Number(value);
            const isChecked: boolean = min_eligible_invoice_flag;
            return !isChecked
              ? !isNaN(num)
                ? /^[+]?\d+([.]\d+)?$/.test(num.toString())
                  ? num >= 0
                    ? true
                    : false
                  : false
                : false
              : true;
          }
        ),

      max_discount_value: yup
        .string()
        .test(
          "within_range",
          "Capping field should have a value equal to or bigger than 0.",
          (value) => {
            const num = Number(value);
            const isChecked = max_discount_values_flag;
            return !isChecked
              ? !isNaN(num)
                ? /^[+]?\d+([.]\d+)?$/.test(num.toString())
                  ? num >= 0
                    ? true
                    : false
                  : false
                : false
              : true;
          }
        ),
      expires_after_value: yup
        .string()
        .test(
          "within_range",
          "Expires after field should have a value equal to or bigger than 0.",
          (value) => {
            const num = Number(value);
            const isChecked = expires_after_values_flag;
            if (Number(value) === 0) {
              return false;
            }
            return !isChecked
              ? !isNaN(num)
                ? /^[+]?\d+([.]\d+)?$/.test(num.toString())
                  ? num >= 0
                    ? true
                    : false
                  : false
                : false
              : true;
          }
        ),
    })
  );
}
