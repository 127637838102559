import moment from "moment";
import React, { useState } from "react";
import images from "../../../assets/images/images";
import styles from "./styles.module.sass";
import { Campaign, CampaignStatus } from "./types";
const actions: {
  actionType: string;
  action: (item: Campaign) => void;
}[] = [
  {
    action: (item: Campaign) => console.log(item),
    actionType: "Edit Campaign",
  },
  {
    action: (item: Campaign) => console.log(item),
    actionType: "Duplicate Campaign",
  },
  {
    action: (item: Campaign) => console.log(item),
    actionType: "Delete Campaign",
  },
];

export const columns = [
  {
    title: "Campaign Title",
    sorter: false,
    render: (obj: Campaign) => (
      <div className={styles.campaign_cell}>
        <span className={styles.title}>{obj.title}</span>
      </div>
    ),
  },
  {
    title: "Next Time Trigger",

    sorter: false,
    render: (obj: Campaign) => (
      <span>
        {obj.next_time_to_run &&
          moment(obj.next_time_to_run).format("hh:mm A DD MMM yyyy")}
      </span>
    ),
  },
  {
    title: "Sent Actions",
    sorter: false,
    render: () => {
      const data: { [key: string]: number | null } = {
        succeeded_assigned_users: 299,
        succeeded_sent_push: 299,
        succeeded_sent_sms: 299,
      };
      const icons: { [key: string]: string } = {
        succeeded_assigned_users: images.percentageSm,
        succeeded_sent_sms: images.messageSm,
        succeeded_sent_push: images.bell,
      };
      return (
        <div>
          {data &&
            Object.keys(data)
              .filter((key: string) => {
                return data[key] !== null;
              })
              .map((key: string, index: number) => {
                return (
                  <div key={`${key}-${index}`} className={styles.action}>
                    <img src={icons[key]} alt="key" />
                    <span>{data[key]}</span>
                  </div>
                );
              })}
        </div>
      );
    },
  },
  {
    title: "Status",
    sorter: false,
    render: (obj: Campaign) => (
      <span
        className={styles.status}
        style={{
          ...CampaignStatus[obj.state],
        }}
      >
        {obj.state.toUpperCase()}
      </span>
    ),
  },
  {
    title: "End Date",

    sorter: false,
    render: (obj: Campaign) => (
      <span>{moment(obj.end_time).format("DD MMM yyyy")}</span>
    ),
  },
  {
    title: "Actions",
    sorter: false,
    render: (item: Campaign) => <Actions item={item} actions={actions} />,
  },
];

export const Actions: React.FC<{
  item: Campaign;
  actions: {
    actionType: string;
    action: (item: Campaign) => void;
  }[];
}> = ({ item, actions }) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      {actions.length ? (
        <div className={styles.actions_wrapper}>
          <div className={styles.image_wrapper}>
            <img
              src={images.dropdown}
              alt="dropdown"
              onClick={() => {
                setOpen(!open);
              }}
            />
          </div>
          <div className={`${styles.actions} ${open ? styles.open : ""}`}>
            {actions.map(
              (
                action: {
                  actionType: string;
                  action: (item: Campaign) => void;
                },
                index
              ) => (
                <button
                  key={`${action.actionType}_${index}`}
                  onClick={() => {
                    setOpen(false);
                    action.action(item);
                  }}
                  className={`${styles.action_cta} ${
                    action.actionType.toLowerCase().includes("delete")
                      ? styles.danger
                      : ""
                  }`}
                >
                  {action.actionType}
                </button>
              )
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
