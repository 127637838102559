import React from "react";
import images from "../../assets/images/images";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { removeBrand } from "../../store/slices/country-brands-slice";
import styles from "./styles.module.sass";
import { Brand } from "./types";

export const TargetBrandItem: React.FC<Brand> = (props) => {
  const dispatch = useAppDispatch();

  const handleRemoveBrand = (id: string) => {
    dispatch(removeBrand({ value: id }));
  };

  const { brand } = props;

  return (
    <div key={brand.id} className={styles.brandItemContainer}>
      <div className={styles.brandName}>
        <h3 className={styles.brandName}>{brand.name}</h3>
      </div>
      <div
        onClick={() => handleRemoveBrand(brand.id)}
        className={styles.clonseIconContainer}
      >
        <img src={images.closeBrandIcon} className={styles.closeIcon} />
      </div>
    </div>
  );
};

export default TargetBrandItem;
