import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelect } from "../hooks";
import { setRoutes } from "../store/slices/routing-slice";
import { IRoute } from "./utils";

export interface IPathWrapperProps {
  route: IRoute;
  path: React.ReactElement;
}

const PathWrapper: React.FC<IPathWrapperProps> = ({
  route,
  path,
}: IPathWrapperProps) => {
  const dispatch = useDispatch();
  const { to } = useSelect((state) => state.routingReducer);
  useEffect(() => {
    dispatch(
      setRoutes({
        from: to,
        to: route,
      })
    );
  }, [route]);

  return <>{path}</>;
};

export default PathWrapper;
