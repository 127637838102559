import React from "react";
import images from "../../../../assets/images/images";
import styles from "./styles.module.sass";
import { ModalHeaderProps } from "./types";

export const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
  const { onClose } = props;
  return (
    <div className={styles.modalHeaderContainer}>
      <div className={styles.closeIconContainer} onClick={onClose}>
        <img className={styles.closeIcon} src={images.closeModalIcon} />
      </div>
    </div>
  );
};
