import React, { useEffect, useMemo } from "react";
import { SectionWrapper } from "../../../components/SectionWrapper";
import { UploadCSV } from "./components/UploadCSV";
import styles from "./styles.module.sass";
import { setInfo } from "../../../store/slices/promoInfo-slice";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import GenericInputField from "../../../components/Forms/GenericInputField";
import TargettedTabs from "./components/TargettedTabs";
import { IPromocodeTargetedUsersProps } from "../types";

const PromocodeTargetedUsers: React.FC<IPromocodeTargetedUsersProps> = ({
  watchers,
  register,
  errors,
  setValue,
  unregister,
}: IPromocodeTargetedUsersProps) => {
  const dispatch = useAppDispatch();
  const { targeted_customers_type, targeting_method } = watchers;
  const isTargeted = useMemo(() => {
    return targeted_customers_type === "targetted";
  }, [targeted_customers_type]);
  const isUniversal = useMemo(() => {
    return targeted_customers_type === "universal";
  }, [targeted_customers_type]);
  const isCompensational = useMemo(() => {
    return targeted_customers_type === "compensation";
  }, [targeted_customers_type]);

  useEffect(() => {
    dispatch(
      setInfo({
        targeted_customers_type,
        targeting_method: targeting_method,
      })
    );
  }, [targeted_customers_type, targeting_method]);
  return (
    <SectionWrapper
      title="Promocode target users"
      wrapperClassName={styles.sectionContainer}
    >
      <TargettedTabs
        value={targeted_customers_type}
        onClick={(value: typeof targeted_customers_type) => {
          if (value === "universal") {
            setValue("targeting_method", "all_customers");
            unregister("targeted_customers_file_path");
            unregister("targeted_customers");
          } else {
            setValue("targeting_method", undefined);
            unregister("targeting_method");
          }
          setValue("targeted_customers_type", value);
        }}
        items={[
          {
            label: "Targeted",
            value: "targetted",
          },
          {
            label: "Universal",
            value: "universal",
          },
          {
            label: "Compensational",
            value: "compensation",
          },
        ]}
      />
      <div style={{ marginTop: "24px" }} />
      {isUniversal && (
        <TargettedTabs
          label={"Select users type"}
          value={targeting_method}
          onClick={(value: typeof targeting_method) => {
            setValue("targeting_method", value);
          }}
          items={[
            {
              label: "All users",
              value: "all_customers",
            },
            {
              label: "New users only",
              value: "new_customers",
            },
            {
              label: "Ordering users",
              value: "ordering_customers",
            },
          ]}
        />
      )}
      {isTargeted && (
        <UploadCSV
          onClose={() => {
            return;
          }}
          register={register("targeted_customers")}
          errors={errors}
          watchers={watchers}
          setValue={setValue}
        />
      )}
      {isCompensational && (
        <>
          <div
            className={styles.divider}
            style={{
              marginTop: "20px",
              width: "50%",
            }}
          >
            <GenericInputField
              register={register("expiry_days")}
              type={"number"}
              placeholder={"Ex.2"}
              label={"Promocode Duration"}
              error={errors?.expiry_days}
              badge={"Days"}
              className="any"
            />
          </div>
        </>
      )}
    </SectionWrapper>
  );
};

export default PromocodeTargetedUsers;
