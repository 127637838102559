import React, { useMemo } from "react";
import GenericInputField from "../Forms/GenericInputField";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { CountryCode } from "../../pages/Promocode/types";
import { IPromocodeBrandValues } from "../../store/types";
import { setInfo } from "../../store/slices/promoInfo-slice";
import styles from "./styles.module.sass";
import { IBrandItemFieldsProps } from "./types";
import { PromotionType } from "../../layouts/Promocode/types";

const BrandItemFields: React.FC<IBrandItemFieldsProps> = ({
  register,
  watchers,
  brand,
  index,
  setValue,
  errors,
  trigger,
}: IBrandItemFieldsProps) => {
  const {
    country_code,
    split_flag,
    max_discount_values_flag,
    min_eligible_invoice_flag,
    expires_after_values_flag,
    brands,
  } = watchers;
  const dispatch = useAppDispatch();
  const currency = useMemo(() => {
    return country_code == CountryCode.EGY ? "EGP" : "SAR";
  }, [country_code]);
  const showMinMaxInvoiceSection = useMemo(
    () => !max_discount_values_flag || !min_eligible_invoice_flag,
    [max_discount_values_flag, min_eligible_invoice_flag]
  );

  const setBrands = (values: Partial<IPromocodeBrandValues>, index: number) => {
    const newBrands = [...brands];
    newBrands[index] = { ...newBrands[index], ...values };
    dispatch(setInfo({ brands: newBrands }));
    setValue("brands", newBrands);
  };
  return (
    <div className={`${styles.split_division}`}>
      <div className={styles.divider}>
        <div className={styles.formControl}>
          <div className={styles.toggle_header}>
            <span>{brand.name}</span>
          </div>
        </div>
      </div>
      {!split_flag && (
        <div className={styles.divider}>
          <GenericInputField
            register={register(`brands.${index}.koinz_percentage`)}
            type={"number"}
            placeholder={"Ex.2%"}
            label={"Koinz Percentage"}
            error={errors?.[`koinz_percentage`]}
            badge={"%"}
            onChange={(e) => {
              const actualValue =
                Number(e.target.value ?? 0) >= 0 &&
                Number(e.target.value ?? 0) <= 100
                  ? Number(e.target.value ?? 0)
                  : Number(e.target.value ?? 0) < 0
                  ? 0
                  : 100;
              const brand_percentage_value = 100 - actualValue;
              setBrands(
                {
                  koinz_percentage: actualValue,
                  brand_percentage: brand_percentage_value,
                },
                index
              );
              trigger(`brands.${index}.koinz_percentage`);
            }}
          />
          <GenericInputField
            register={register(`brands.${index}.brand_percentage`)}
            type={"number"}
            placeholder={"e.g.  %15"}
            label={"Brand Percentage"}
            error={errors?.[`brand_percentage`]}
            badge={"%"}
            onChange={(e) => {
              const actualValue =
                Number(e.target.value ?? 0) >= 0 &&
                Number(e.target.value ?? 0) <= 100
                  ? Number(e.target.value ?? 0)
                  : Number(e.target.value ?? 0) < 0
                  ? 0
                  : 100;
              const koinz_percentage_value = 100 - actualValue;
              setBrands(
                {
                  koinz_percentage: koinz_percentage_value,
                  brand_percentage: actualValue,
                },
                index
              );
              trigger(`brands.${index}.brand_percentage`);
            }}
          />
        </div>
      )}
      {showMinMaxInvoiceSection &&
        (watchers.promotion_type === PromotionType.PERCENTAGE ||
          watchers.promotion_type === PromotionType.CASHBACK) && (
          <>
            <div className={styles.divider}>
              {!max_discount_values_flag && (
                <GenericInputField
                  register={register(`brands.${index}.max_discount_value`)}
                  type={"number"}
                  placeholder={"Ex.2"}
                  onChange={(e) => {
                    const actualValue =
                      Number(e.target.value ?? 0) >= 0
                        ? Number(e.target.value ?? 0)
                        : 0;
                    setBrands(
                      {
                        max_discount_value: actualValue,
                      },
                      index
                    );
                    trigger(`brands.${index}.max_discount_value`);
                  }}
                  label={
                    watchers.promotion_type === PromotionType.CASHBACK
                      ? "Max cashback value"
                      : "Capping"
                  }
                  error={errors?.[`max_discount_value`]}
                  badge={currency}
                />
              )}
              {!min_eligible_invoice_flag && (
                <GenericInputField
                  register={register(`brands.${index}.min_eligible_invoice`)}
                  type={"number"}
                  onChange={(e) => {
                    const actualValue =
                      Number(e.target.value ?? 0) >= 0
                        ? Number(e.target.value ?? 0)
                        : 0;
                    trigger(`brands.${index}.min_eligible_invoice`);
                    setBrands(
                      {
                        min_eligible_invoice: actualValue,
                      },
                      index
                    );
                    trigger(`brands.${index}.min_eligible_invoice`);
                  }}
                  placeholder={"Ex.2"}
                  label={"Min eligible invoice"}
                  error={errors?.[`min_eligible_invoice`]}
                  badge={currency}
                />
              )}
            </div>
          </>
        )}
      {watchers.promotion_type === PromotionType.CASHBACK && (
        <div className={styles.divider}>
          {!expires_after_values_flag && (
            <GenericInputField
              register={register(`brands.${index}.expires_after_value`)}
              type={"number"}
              placeholder={"Ex.2"}
              onChange={(e) => {
                const actualValue =
                  Number(e.target.value ?? 72) >= 0
                    ? Number(e.target.value ?? 72)
                    : 72;
                setBrands(
                  {
                    expires_after_value: actualValue,
                  },
                  index
                );
                trigger(`brands.${index}.expires_after_value`);
              }}
              label={"Expires at"}
              error={errors?.[`expires_after_value`]}
              badge={"Hours"}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default BrandItemFields;
