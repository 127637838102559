import React from "react";
import { Modal } from "antd";
import { BrandCSVModalProps } from "./types";
import { UploadSuccessCSV } from "./components/UploadSuccessCSV";
import { useSelect } from "../../hooks";
import { UploadCSV } from "./components/UploadCSV";
import { UploadFaildCSV } from "./components/UploadFaildCSV";

export const BrandCSVModal: React.FC<BrandCSVModalProps> = (props) => {
  const { openModal, onClose } = props;

  const {
    brandsReducer: { csvBrandsStatus },
  } = useSelect((state) => {
    return state;
  });
  return (
    <Modal
      onCancel={onClose}
      open={openModal}
      destroyOnClose={true}
      closable={false}
      title=""
      footer=""
    >
      {csvBrandsStatus !== "loading" && <UploadCSV onClose={onClose} />}
      {csvBrandsStatus === "loading" && <UploadSuccessCSV />}
      {csvBrandsStatus === "failed" && <UploadFaildCSV />}
    </Modal>
  );
};

export default BrandCSVModal;
