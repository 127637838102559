import axios, { AxiosError, AxiosResponse } from "axios";
import { store } from "../store";
import { logout } from "../store/slices/auth-slice";

const handleSuccess = (
  res: AxiosResponse
): AxiosResponse | Promise<AxiosResponse> => res;
const handleError = (error: AxiosError) => {
  if (error?.response?.status == 401) {
    store.dispatch(logout());
  }
  throw error;
};

export const createAxiosInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  instance.interceptors.request.use(
    (config) => {
      const token = store.getState().authReducer.token;
      if (!config.url?.includes("login") && token)
        config.headers = {
          ...config.headers,
          token,
        };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  instance.interceptors.response.use(handleSuccess, handleError);
  return instance;
};
export default createAxiosInstance("");
